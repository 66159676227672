export const spvGetEmailList = (data) => ({ type: 'SPV_EMAIL_LIST_GET', data });

export const spvSetEmailList = (list) => ({
  type: 'SPV_EMAIL_LIST_SET',
  list,
});

export const spvGetEmailListCurrent = (data) => ({ type: 'SPV_EMAIL_LIST_CURRENT_GET', data });

export const spvSetEmailListCurrent = (current) => ({
  type: 'SPV_EMAIL_LIST_CURRENT_SET',
  current,
});
