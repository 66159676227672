import React from "react";

export const AgentLog = React.lazy(() => import("./Report/AgentLog"));
export const Custome = React.lazy(() => import("./Report/Custome"));
export const CustomerRetention = React.lazy(() =>
 import("./Report/CustomerRetention")
);
export const DetailKip = React.lazy(() => import("./Report/DetailKip"));
export const Kip = React.lazy(() => import("./Report/Kip"));
export const Rating = React.lazy(() => import("./Report/Rating"));
export const SoundRecord = React.lazy(() => import("./Report/SoundRecord"));
export const Takeout = React.lazy(() => import("./Report/Takeout"));
export const Ticketing = React.lazy(() => import("./Report/Ticketing"));
export const Traffic = React.lazy(() => import("./Report/Traffic"));
export const VideoRecord = React.lazy(() => import("./Report/VideoRecord"));
export const WordCloud = React.lazy(() => import("./Report/WordCloud"));
export const ReportEmail = React.lazy(() => import("./Report/ReportEmail"));
export const EmailHandling = React.lazy(() => import("./Report/EmailHandling"));
export const EmailHistory = React.lazy(() => import("./Report/EmailHistory"));
export const EmailQueue = React.lazy(() => import("./Report/EmailQueue"));
export const Csat = React.lazy(() => import("./Report/Csat"));
export const DetailReport = React.lazy(() => import("./Report/DetailReport"));
