/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { FaUser } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { Tooltip } from "react-tippy";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import pingJs from "web-pingjs";
// import Draggable from "react-draggable";
import Router from "./router";
import {
  MarginRight,
  Flex,
  SnackbarMain,
  Absolute,
  Text,
  AvatarBorder,
  ButtonWithIcon,
  Loading,
  //  InputText,
  ButtonPrimary,
} from "components/Layout";
import helper from "utils/helper";
import ModalLogout from "components/Modal/ModalLogout";
// import ModalAddOrEdit from "components/Modal/ModalAddOrEdit";
import generalService from "services/generalService";
import BlankTenant from "components/Blank/BlankTenant";
import reportService from "services/reportService";
import SnackbarChrome from "components/SnackbarChrome";
import logic from "./logic";
import crypto from "utils/crypto";
//import Draggable from "react-draggable";

const CircularProgressMain = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: #fff !important;
`;

function Main(props) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [statusSnackbar, setStatusSnackbar] = useState("#f44336");
  const [message, setMessage] = useState("");
  //const [ping, setPing] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //  const [openTransfer, setOpenTransfer] = useState(false);
  //  const [ext, setExt] = useState("");
  useEffect(() => {
    /**
     *
     * Deklarasi Role & Version
     */

    if (props.auth.user) {
      getChannel();
    }

    if (props.auth.user && props.auth.user.userlevel === "Supervisor") {
      getCustomeReport();
      props.setAllEmail(true);
    }
    if (props.auth.version) {
      if (crypto.decryptedData().VERSION !== props.auth.version) {
        props.setValidVersion(false);
      }
    }
    /**
     *
     * Deklarasi Fungsi Ping
     */
    // setInterval(function () {
    //  pingJs(crypto.decryptedData().API_URL)
    //   .then(function (delta) {
    //    setPing(String(delta));
    //   })
    //   .catch(function (err) {
    //    setPing(0);
    //   });
    // }, 3000);
    /**
     *
     * Deklarasi Logic Axios
     */
    const args = {
      setOpenBackdrop: (res) => setOpenBackdrop(res),
      setOpenSnackbar: (res) => setOpenSnackbar(res),
      setStatusSnackbar: (res) => setStatusSnackbar(res),
      setMessage: (res) => setMessage(res),
      setLoading: (res) => props.setLoading(res),
    };
    logic.logicAxios(args);
    /**
     *
     * Deklarasi Logic Routing
     */
    props.setRoute(`/${window.TENANT_ID_URL}/${window.location.pathname}`);
    const storage = localStorage.getItem("tenant_id");
    if (storage) {
      if (window.TENANT_ID_URL !== storage.split("|")[1]) {
        localStorage.removeItem("tenant_id");
        generalService
          .getTenantValid({
            tenant_code: `'${window.TENANT_ID_URL}'`,
          })
          .then((e) => {
            if (e) {
              if (e.Record.length > 0) {
                localStorage.setItem(
                  "tenant_id",
                  `${e.Record[0].tenant_id}|${window.TENANT_ID_URL}|${e.Record[0].can_generate_link}|${e.Record[0].webphone}`
                );
                window.TENANT_ID = e.Record[0].tenant_id;
                window.location.href = `/${window.TENANT_ID_URL}/`;
              } else {
                window.location.href = "/";
              }
            } else {
              window.location.href = "/";
            }
          });
      }
    } else {
      generalService
        .getTenantValid({
          tenant_code: `'${window.TENANT_ID_URL}'`,
        })
        .then((e) => {
          if (e) {
            if (e.Record.length > 0) {
              localStorage.setItem(
                "tenant_id",
                `${e.Record[0].tenant_id}|${window.TENANT_ID_URL}|${e.Record[0].can_generate_link}|${e.Record[0].webphone}`
              );
              window.TENANT_ID = e.Record[0].tenant_id;
              window.location.href = `/${window.TENANT_ID_URL}/`;
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onUnload = (e) => {
      if (props.openIncoming) {
        // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = "Hello broo";
      }
      return false;
    };

    const validateChannel =
      props.channel?.filter(
        (v) => v.channel_name === "Video Call" && parseInt(v.is_active) === 1
      ).length > 0
        ? props.channelAgent?.filter(
            (v) => v.field === "vicall" && parseInt(v.value) === 1
          ).length > 0
          ? ["true"]
          : []
        : [];

    if (validateChannel.length > 0) {
      loadOpentok(() => {
        console.log("OPEN TOK LOADED");
      });
    }
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [props]);

  const loadOpentok = (callback) => {
    const existingScript = document.getElementById("opentok");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://static.opentok.com/v2/js/opentok.min.js`;
      script.id = "opentok";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  useEffect(() => {
    if (
      props.auth.user &&
      props.auth.user.userlevel === "Agent" &&
      parseInt(window.isMizu) === 1
    ) {
      loadData();
    }
    if (
      props.auth.user &&
      props.auth.user.userlevel === "Agent" &&
      parseInt(window.isMizu) === 4
    ) {
      window.$("#EditWebSocketServerAddr").val("103.90.251.48");
      window.$("#EditAccountAuthLogin").val(props.auth?.user?.login_pbx);
      window.$("#EditAccountAuthPassword").val(props.auth?.user?.pwd_pbx);
      window.$("#EditAccountServerAddr").val("192.168.70.106");
      window.$("#EditAccountServerPort").val("9506");
      let m_objVaxMain = new window.cVaxMain();
      window.m_objVaxMain = m_objVaxMain;
      window.m_objVaxMain.OnLoadPage();
      if (window.m_objVaxWebPhoneLib) {
        window.m_objVaxWebPhoneLib.VaxOnline(
          "",
          "",
          props.auth?.user?.login_pbx,
          props.auth?.user?.pwd_pbx,
          "",
          "192.168.70.106",
          9506,
          true,
          "103.90.251.48"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCustomeReport = () => {
    reportService
      .setReportMenu({
        tenant_id: window.TENANT_ID,
        fields: "*",
        type: "search",
        filter: {
          is_active: "1",
        },
        operator: "like",
      })
      .then((res) => {
        props.setCustomeReport(res);
      });
  };

  const loadData = async () => {
    await props.getChannel({
      tenant_id: window.TENANT_ID,
    });

    await props.getChannelAgent({
      tenant_id: window.TENANT_ID,
      skill_id: props.auth.loginStatus.skill_id,
    });
  };

  const getChannel = async () => {
    await props.getChannel({
      tenant_id: window.TENANT_ID,
    });
    await props.getChannelAgent({
      tenant_id: window.TENANT_ID,
      skill_id: props.auth.loginStatus.skill_id,
    });
    await props.getAddForm({
      tenant_id: window.TENANT_ID,
      type: "search",
    });
  };

  //const { validVersion } = props;
  let validLogoutStorage = false;
  if (localStorage.getItem(window.TENANT_ID + "-forcelogout")) {
    validLogoutStorage = localStorage.getItem(
      window.TENANT_ID + "-forcelogout"
    );
  }
  return window.TENANT_ID ? (
    <>
      <Router />
      <Loading open={openBackdrop}>
        <CircularProgressMain color="inherit" />
      </Loading>
      {/* <Absolute
    right="10px"
    bottom="0"
    bgcolor={validVersion ? window.palette.primary.main : "red"}
    style={{ padding: 5 }}
   >
    <Text color="#fff" fs="10">
     Version {window.VERSION}
     {validVersion ? "" : " ( not update )"}
    </Text>
   </Absolute> */}
      {/* <Absolute
    right={validVersion ? "80px" : "150px"}
    bottom="0"
    bgcolor={
     ping < 100 && ping > 0
      ? window.palette.primary.main
      : ping > 100 && ping < 200
      ? "gold"
      : "red"
    }
    style={{ padding: 5 }}
   >
    <Text color="#fff" fs="10">
     Ping time was {ping} ms
    </Text>
   </Absolute> */}
      <SnackbarMain
        bg={statusSnackbar}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={6000}
        message={
          <Flex>
            {props.loading ? (
              <>
                <CircularProgressMain />
                <MarginRight val={10} />
              </>
            ) : null}
            {message === "Error"
              ? "Something wrong with our system, please contact our support."
              : message}
          </Flex>
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
      <ModalLogout
        open={validLogoutStorage}
        message={"Your session has ended, please re-login..."}
      />

      <SnackbarChrome />
      {props.openIncoming &&
      props.statusCall === "RINGING" &&
      !props.outCall ? (
        <Absolute top="50px" left="64px" width="285px">
          <Flex
            bgcolor={"rgba(30, 23, 69, 1)"}
            padding="10px"
            align="center"
            nowrap="nowrap"
            margin="0 10px 0 0"
          >
            <AvatarBorder
              width="30px"
              height="30px"
              bgcolor={"#55ACEE"}
              fs="12px"
              padding="0"
            >
              <FaUser />
            </AvatarBorder>
            <Flex>
              <Flex nowrap="nowrap" direction="column" align="unset">
                <Flex align="center">
                  <Text
                    fs="12"
                    color="#fff"
                    textalign="left"
                    margin="0 10px 0 0"
                  >
                    {props.call.length > 0 ? props.call[3] : "Unknown Number"}
                  </Text>
                  <Tooltip
                    html={
                      <Text fs="12px" color="#fff">
                        Copy Number
                      </Text>
                    }
                    position="top"
                    arrow
                    size="small"
                  >
                    <CopyToClipboard
                      text={
                        props.call.length > 0 ? props.call[3] : "Unknown Number"
                      }
                    >
                      <ButtonWithIcon coloricon="#fff">
                        <MdContentCopy style={{ fontSize: "12px" }} />
                      </ButtonWithIcon>
                    </CopyToClipboard>
                  </Tooltip>
                </Flex>
                <Text fs="12" color="#fff" textalign="left">
                  Incoming call...
                </Text>
              </Flex>
            </Flex>
            {parseInt(window.isMizu) === 1 ? (
              <Flex nowrap="nowrap" direction="column" margin="0 0 0 auto">
                <ButtonPrimary
                  zindex="9999"
                  variant="contained"
                  size="small"
                  fs="10px"
                  style={{ marginBottom: 10 }}
                  onClick={() => window.webphone_api.accept()}
                >
                  Answer
                </ButtonPrimary>
                <ButtonPrimary
                  zindex="9999"
                  bgcolor="red"
                  variant="contained"
                  size="small"
                  fs="10px"
                  onClick={() => window.webphone_api.hangup()}
                >
                  Reject
                </ButtonPrimary>
              </Flex>
            ) : null}
          </Flex>
        </Absolute>
      ) : null}
    </>
  ) : (
    <BlankTenant />
  );
}

export default connect(helper.mapStateToProps, helper.mapDispatchToProps)(Main);
