import axios from "axios";
import * as GibberishAES from "gibberish-aes/src/gibberish-aes";
import history from "./history";
import authService from "../services/authService";

const http = axios.create();
const storage = localStorage.getItem(window.TENANT_ID);
let user_id = "";
if (storage) {
 const dirtyStorage = JSON.parse(GibberishAES.dec(storage, "infomedia123"));
 const { accessToken, user } = dirtyStorage;
 const { userid } = user;
 user_id = userid;
 http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

http.interceptors.response.use(
 (response) => {
  return response;
 },
 (error) => {
  if (error.response !== undefined) {
   if (error.response.status === 401) {
    alert("Sorry your session expired, please re-login...");
    delete http.defaults.headers.common.Authorization;
    authService
     .logout({
      tenant_id: window.TENANT_ID,
      userid: user_id,
     })
     .then((res) => {
      if (res) {
       localStorage.removeItem(window.TENANT_ID);
       localStorage.removeItem("tenant_id");
       localStorage.removeItem(`${window.TENANT_ID}-forcelogout`);
       history.push("/login");
       window.location.reload(true);
      }
     });
   }
  }
  return Promise.reject(error);
 }
);

export default http;
