export const voiceLogGet = (data) => ({ type: "VOICE_LOG_GET", data });

export const voiceLogSet = (voiceLog) => ({
 type: "VOICE_LOG_SET",
 voiceLog,
});

export const voiceLogCurrentGet = (data) => ({
 type: "VOICE_LOG_CURRENT_GET",
 data,
});

export const voiceLogCurrentSet = (current) => ({
 type: "VOICE_LOG_CURRENT_SET",
 current,
});

export const voiceLogLoadMoreGet = (data) => ({
 type: "VOICE_LOG_LOAD_MORE_GET",
 data,
});

export const voiceLogLoadMoreSet = (hide) => ({
 type: "VOICE_LOG_LOAD_MORE_SET",
 hide,
});
