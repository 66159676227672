/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import * as GibberishAES from "gibberish-aes/src/gibberish-aes";
import * as actions from "../store/actions";
import { menuSidebar } from "../components/Layout/MenuList";
import Ico from "../assets/images/ico.png";

export default {
 decrypt(variable) {
  return GibberishAES.dec(variable.toString(), "infomedia123");
 },
 encrypt(variable) {
  return GibberishAES.enc(variable.toString(), "infomedia123");
 },
 isAgent() {
  if (localStorage.getItem(window.TENANT_ID)) {
   const dirtyStorage = JSON.parse(
    this.decrypt(localStorage.getItem(window.TENANT_ID))
   );
   if (dirtyStorage.accessToken) {
    if (dirtyStorage.user.is_login !== 2) {
     if (dirtyStorage.user.userlevel === "Agent") {
      return true;
     }
    }
   }
  }
  return false;
 },

 isAux() {
  if (localStorage.getItem(window.TENANT_ID)) {
   const dirtyStorage = JSON.parse(
    this.decrypt(localStorage.getItem(window.TENANT_ID))
   );
   if (dirtyStorage.accessToken) {
    if (dirtyStorage.user.is_login === 2) {
     return true;
    }
   }
  }
  return false;
 },

 isSupervisor() {
  if (localStorage.getItem(window.TENANT_ID)) {
   const dirtyStorage = JSON.parse(
    this.decrypt(localStorage.getItem(window.TENANT_ID))
   );
   if (dirtyStorage.accessToken) {
    if (dirtyStorage.user.is_login !== 2) {
     if (dirtyStorage.user.userlevel === "Supervisor") {
      return true;
     }
    }
   }
  }
  return false;
 },

 isBackroom() {
  if (localStorage.getItem(window.TENANT_ID)) {
   const dirtyStorage = JSON.parse(
    this.decrypt(localStorage.getItem(window.TENANT_ID))
   );
   if (dirtyStorage.accessToken) {
    if (dirtyStorage.user.is_login !== 2) {
     if (dirtyStorage.user.userlevel === "Backroom") {
      return true;
     }
    }
   }
  }
  return false;
 },
 mapStateToProps(state) {
  return {
   auth: state.auth.auth,
   openIncoming: state.openIncoming,
   event: state.event,
   loading: state.loading.loading,
   channel: state.channel.channel || [],
   channelAgent: state.channelAgent.channel || [],
   notification: state.notification.notification,
   route: state.route.route,
   interaction: state.interaction.interaction,
   journey: state.journey.journey || [],
   profile: state.profile.profile || [],
   incoming: state.incoming.incoming,
   text: state.text.text,
   textCwc: state.textCwc.text || [],
   category: state.category.category || [],
   cwcId: state.cwcId ? state.cwcId.id : null,
   endDate: state.endDate ? state.endDate.date : null,
   disabled: state.disabled ? state.disabled.disabled : false,
   tabInteraction: state.tabInteraction.value,
   tabInfo: state.tabInfo.value,
   hideLoadMoreTicket: state.hideLoadMoreTicket
    ? state.hideLoadMoreTicket.hide
    : false,
   hideLoadMoreEmailSpv: state.hideLoadMoreEmailSpv
    ? state.hideLoadMoreEmailSpv.hide
    : false,
   hideLoadMoreLogCwc: state.hideLoadMoreLogCwc
    ? state.hideLoadMoreLogCwc.hide
    : false,
   customerLoadMore: state.customerLoadMore
    ? state.customerLoadMore.hide
    : false,
   voiceLogLoadMore: state.voiceLogLoadMore
    ? state.voiceLogLoadMore.hide
    : false,
   sumCwc: state.sumCwc.cwc || [],
   template: state.template.data || [],
   logCwc: state.logCwc.logCwc || [],
   logInteraction: state.logInteraction.interaction || [],
   call: state.call.call || [],
   statusCall: state.statusCall.status,
   statusRegister: state.statusRegister.status,
   durationCall: state.durationCall.duration,
   isCall: state.isCall ? state.isCall.call : false,
   addForm: state.addForm.form || [],
   addFormOpt: state.addFormOpt.form || [],
   manualKip: state.manualKip.manual || [],
   archiveId: state.archiveId ? state.archiveId.id : null,
   hideTabInteraction: state.hideTabInteraction
    ? state.hideTabInteraction.hide
    : false,
   activeManualKip: state.activeManualKip ? state.activeManualKip.active : null,
   outCall: state.outCall ? state.outCall.active : null,
   logCwcCurrent: state.logCwcCurrent ? state.logCwcCurrent.current : false,
   mergeCwc: state.mergeCwc.mergeCwc || [],
   validVersion: !!state.validVersion,
   validLogout: !!state.validLogout,
   statusAllEmail: state.statusAllEmail ? state.statusAllEmail.status : false,
   teleCurrent: state.teleCurrent ? state.teleCurrent.current : null,
   teleMessages: state.teleMessages.messages || [],
   whatsappCurrent: state.whatsappCurrent
    ? state.whatsappCurrent.current
    : null,
   whatsappMessages: state.whatsappMessages.messages || [],
   messengerCurrent: state.messengerCurrent
    ? state.messengerCurrent.current
    : null,
   messengerMessages: state.messengerMessages.messages || [],
   smsCurrent: state.smsCurrent ? state.smsCurrent.current : null,
   smsMessages: state.smsMessages.messages || [],
   tdmCurrent: state.tdmCurrent ? state.tdmCurrent.current : null,
   tdmMessages: state.tdmMessages.messages || [],
   webchatCurrent: state.webchatCurrent ? state.webchatCurrent.current : null,
   webchatMessages: state.webchatMessages.messages || [],
   emailCurrent: state.emailCurrent ? state.emailCurrent.current : null,
   emailMessages: state.emailMessages.messages || [],
   facebookCurrent: state.facebookCurrent
    ? state.facebookCurrent.current
    : null,
   facebookMessages: state.facebookMessages.messages || [],
   twitterCurrent: state.twitterCurrent ? state.twitterCurrent.current : null,
   twitterMessages: state.twitterMessages.messages || [],
   instagramCurrent: state.instagramCurrent
    ? state.instagramCurrent.current
    : null,
   instagramMessages: state.instagramMessages.messages || [],
   vidcallCurrent: state.vidcallCurrent ? state.vidcallCurrent.current : null,
   vidcallMessages: state.vidcallMessages.messages || [],
   voiceCurrent: state.voiceCurrent ? state.voiceCurrent.current : null,
   voiceMessages: state.voiceMessages.messages || [],
   lineCurrent: state.lineCurrent ? state.lineCurrent.current : null,
   lineMessages: state.lineMessages.messages || [],
   igCurrent: state.igCurrent ? state.igCurrent.current : null,
   igMessages: state.igMessages.messages || [],
   ticket: state.ticket.ticket || [],
   ticketStatus: state.ticketStatus.status || [],
   ticketUnit: state.ticketUnit.unit || [],
   ticketCurrent: state.ticketCurrent ? state.ticketCurrent.current : null,
   ticketHistory: state.ticketHistory.history || [],
   ticketStatusLevel: state.ticketStatusLevel.status || [],
   ticketStatusNext: state.ticketStatusNext.status || [],
   ticketCaseOrigin: state.ticketCaseOrigin.status || [],
   ticketServiceArea: state.ticketServiceArea.status || [],
   ticketStage: state.ticketStage.stage || [],
   ticketAdditional: state.ticketAdditional.additional || [],
   ticketAdditionalValue: state.ticketAdditionalValue.additional || [],
   customer: state.customer.customer || [],
   customerCurrent: state.customerCurrent
    ? state.customerCurrent.current
    : null,
   spvEmailList: state.spvEmailList.list || [],
   spvEmailListCurrent: state.spvEmailListCurrent
    ? state.spvEmailListCurrent.current
    : false,
   customeReport: state.customeReport || [],
   ticketCount: state.ticketCount ? state.ticketCount.count : null,
   voiceLog: state.voiceLog.voiceLog || [],
   voiceLogCurrent: state.voiceLogCurrent ? state.voiceLogCurrent : null,
  };
 },
 mapDispatchToProps(dispatch) {
  return {
   getAuth: (data) => dispatch(actions.generalGetAuth(data)),
   setLoading: (data) => dispatch(actions.generalSetLoading(data)),
   getChannel: (data) => dispatch(actions.generalGetChannel(data)),
   getChannelAgent: (data) => dispatch(actions.generalGetChannelAgent(data)),
   setNotification: (data) => dispatch(actions.generalSetNotification(data)),
   setRoute: (data) => dispatch(actions.generalSetRoute(data)),
   getJourney: (data) => dispatch(actions.generalGetJourney(data)),
   getProfile: (data) => dispatch(actions.generalGetProfile(data)),
   setProfile: (data) => dispatch(actions.generalSetProfile(data)),
   setInteraction: (data) => dispatch(actions.generalSetInteraction(data)),
   setTabInteraction: (data) =>
    dispatch(actions.generalSetTabInteraction(data)),
   setTabInfo: (data) => dispatch(actions.generalSetTabInfo(data)),
   getIncoming: (data) => dispatch(actions.generalGetIncoming(data)),
   setIncoming: (data) => dispatch(actions.generalSetIncoming(data)),
   setText: (data) => dispatch(actions.generalSetText(data)),
   getText: (data) => dispatch(actions.generalGetText(data)),
   setTextCwc: (data) => dispatch(actions.generalSetTextCwc(data)),
   getTextCwc: (data) => dispatch(actions.generalGetTextCwc(data)),
   getCategory: (data) => dispatch(actions.generalGetCategory(data)),
   setCwcId: (data) => dispatch(actions.generalSetCwcId(data)),
   setEndDate: (data) => dispatch(actions.generalSetEndDate(data)),
   setDisabled: (data) => dispatch(actions.generalSetDisabled(data)),
   setAllEmail: (data) => dispatch(actions.generalSetAllEmail(data)),
   getSumCwc: (data) => dispatch(actions.generalGetSumCwc(data)),
   getTemplate: (data) => dispatch(actions.generalGetTemplate(data)),
   setValidVersion: (data) => dispatch(actions.generalSetValidVersion(data)),
   getLogCwc: (data) => dispatch(actions.generalGetLogCwc(data)),
   getMergeCwc: (data) => dispatch(actions.generalGetMergeCwc(data)),
   setMergeCwc: (data) => dispatch(actions.generalSetMergeCwc(data)),
   getLogInteraction: (data) =>
    dispatch(actions.generalGetLogInteraction(data)),
   setLogCwcCurrent: (data) => dispatch(actions.generalSetCurrentLogCwc(data)),
   setCall: (data) => dispatch(actions.generalSetCall(data)),
   setStatusCall: (data) => dispatch(actions.generalSetStatusCall(data)),
   setStatusCallRegister: (data) =>
    dispatch(actions.generalSetStatusCallRegister(data)),
   setOpenIncoming: (data) => dispatch(actions.generalSetOpenIncoming(data)),
   setDurationCall: (data) => dispatch(actions.generalSetDurationCall(data)),
   setIsCall: (data) => dispatch(actions.generalSetIsCall(data)),
   getAddForm: (data) => dispatch(actions.generalGetAddForm(data)),
   getManualKip: (data) => dispatch(actions.generalGetManualKip(data)),
   getAddFormOpt: (data) => dispatch(actions.generalGetAddFormOpt(data)),
   setArchiveId: (data) => dispatch(actions.generalSetArchiveId(data)),
   setHideTabInteraction: (data) =>
    dispatch(actions.generalSetHideTabInteraction(data)),
   setActiveManualKip: (data) =>
    dispatch(actions.generalSetActiveManualKip(data)),
   setOutCall: (data) => dispatch(actions.generalSetOutcall(data)),
   setTeleCurrent: (data) => dispatch(actions.teleSetCurrent(data)),
   getTeleMessages: (data) => dispatch(actions.teleGetMessages(data)),
   setTeleMessages: (data) => dispatch(actions.teleSetMessages(data)),
   setWhatsappCurrent: (data) => dispatch(actions.whatsappSetCurrent(data)),
   getWhatsappMessages: (data) => dispatch(actions.whatsappGetMessages(data)),
   setWhatsappMessages: (data) => dispatch(actions.whatsappSetMessages(data)),
   setMessengerCurrent: (data) => dispatch(actions.messengerSetCurrent(data)),
   getMessengerMessages: (data) => dispatch(actions.messengerGetMessages(data)),
   setMessengerMessages: (data) => dispatch(actions.messengerSetMessages(data)),
   setSmsCurrent: (data) => dispatch(actions.smsSetCurrent(data)),
   getSmsMessages: (data) => dispatch(actions.smsGetMessages(data)),
   setSmsMessages: (data) => dispatch(actions.smsSetMessages(data)),
   setTdmCurrent: (data) => dispatch(actions.tdmSetCurrent(data)),
   getTdmMessages: (data) => dispatch(actions.tdmGetMessages(data)),
   setTdmMessages: (data) => dispatch(actions.tdmSetMessages(data)),
   setWebchatCurrent: (data) => dispatch(actions.webchatSetCurrent(data)),
   getWebchatMessages: (data) => dispatch(actions.webchatGetMessages(data)),
   setWebchatMessages: (data) => dispatch(actions.webchatSetMessages(data)),
   setEmailCurrent: (data) => dispatch(actions.emailSetCurrent(data)),
   getEmailMessages: (data) => dispatch(actions.emailGetMessages(data)),
   setEmailMessages: (data) => dispatch(actions.emailSetMessages(data)),
   setFacebookCurrent: (data) => dispatch(actions.facebookSetCurrent(data)),
   getFacebookMessages: (data) => dispatch(actions.facebookGetMessages(data)),
   setFacebookMessages: (data) => dispatch(actions.facebookSetMessages(data)),
   setTwitterCurrent: (data) => dispatch(actions.twitterSetCurrent(data)),
   getTwitterMessages: (data) => dispatch(actions.twitterGetMessages(data)),
   setTwitterMessages: (data) => dispatch(actions.twitterSetMessages(data)),
   setInstagramCurrent: (data) => dispatch(actions.instagramSetCurrent(data)),
   getInstagramMessages: (data) => dispatch(actions.instagramGetMessages(data)),
   setInstagramMessages: (data) => dispatch(actions.instagramSetMessages(data)),
   setVidcallCurrent: (data) => dispatch(actions.vidcallSetCurrent(data)),
   getVidcallMessages: (data) => dispatch(actions.vidcallGetMessages(data)),
   setVidcallMessages: (data) => dispatch(actions.vidcallSetMessages(data)),
   setVoiceCurrent: (data) => dispatch(actions.voiceSetCurrent(data)),
   getVoiceMessages: (data) => dispatch(actions.voiceGetMessages(data)),
   setVoiceMessages: (data) => dispatch(actions.voiceSetMessages(data)),
   setLineCurrent: (data) => dispatch(actions.lineSetCurrent(data)),
   getLineMessages: (data) => dispatch(actions.lineGetMessages(data)),
   setLineMessages: (data) => dispatch(actions.lineSetMessages(data)),
   setIgCurrent: (data) => dispatch(actions.igSetCurrent(data)),
   getIgMessages: (data) => dispatch(actions.igGetMessages(data)),
   setIgMessages: (data) => dispatch(actions.igSetMessages(data)),
   getTicket: (data) => dispatch(actions.ticketGet(data)),
   getTicketStatus: (data) => dispatch(actions.ticketGetStatus(data)),
   getTicketUnit: (data) => dispatch(actions.ticketGetUnit(data)),
   setTicketCurrent: (data) => dispatch(actions.ticketSetCurrent(data)),
   getTicketHistory: (data) => dispatch(actions.ticketGetHistory(data)),
   getTicketStatusLevel: (data) => dispatch(actions.ticketGetStatusLevel(data)),
   getTicketStatusNext: (data) => dispatch(actions.ticketGetStatusNext(data)),
   getTicketCaseOrigin: (data) => dispatch(actions.ticketGetCaseOrigin(data)),
   getTicketServiceArea: (data) => dispatch(actions.ticketGetServiceArea(data)),
   getTicketStage: (data) => dispatch(actions.ticketGetStageTicket(data)),
   getTicketAdditional: (data) =>
    dispatch(actions.ticketGetAdditionalTicket(data)),
   getTicketAdditionalValue: (data) =>
    dispatch(actions.ticketGetAdditionalValue(data)),
   getCustomer: (data) => dispatch(actions.customerGet(data)),
   setCustomerCurrent: (data) => dispatch(actions.customerCurrentSet(data)),
   getSpvEmailList: (data) => dispatch(actions.spvGetEmailList(data)),
   setSpvEmailListCurrent: (data) =>
    dispatch(actions.spvSetEmailListCurrent(data)),
   getCustomeReport: (data) => dispatch(actions.reportGetCurrent(data)),
   setCustomeReport: (data) => dispatch(actions.reportSetCurrent(data)),
   setEvent: (data) => dispatch(actions.generalSetEvent(data)),
   getVoiceLog: (data) => dispatch(actions.voiceLogGet(data)),
   setVoiceLogCurrent: (data) => dispatch(actions.voiceLogCurrentSet(data)),
  };
 },
 uuidv4() {
  /* eslint-disable eqeqeq */
  /* eslint-disable no-mixed-operators */
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
   const r = (Math.random() * 16) | 0;
   const v = c == "x" ? r : (r & 0x3) | 0x8;
   return v.toString(16);
  });
 },
 kFormatter(num) {
  return Math.abs(num) > 999
   ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
   : Math.sign(num) * Math.abs(num);
 },
 truncString(str, max, add) {
  add = add || "...";
  return typeof str === "string" && str.length > max
   ? str.substring(0, max) + add
   : str;
 },
 getCurrentChannel(props) {
  let currentChannel = [];
  if (props.route) {
   currentChannel = menuSidebar(false, props).filter(
    (v) => props.route.indexOf(v.link) > -1
   );
  }
  return currentChannel;
 },
 getCurrentChannelByPath(props, path) {
  const currentChannel = menuSidebar(false, props).filter(
   (v) => path.indexOf(v.link) > -1
  );
  return currentChannel.length > 0 ? currentChannel[0] : null;
 },
 getCurrentChannelByKey(props, key) {
  const currentChannel = menuSidebar(false, props).filter(
   (v) => key === v.key.toLowerCase().replace(/ /g, "")
  );
  return currentChannel.length > 0 ? currentChannel[0] : null;
 },
 getCurrent(props) {
  let current = null;
  const currentChannel = this.getCurrentChannel(props);
  if (currentChannel.length > 0) {
   current = currentChannel[0].current;
  }
  return current;
 },
 getChannelId(props) {
  const currentChannel = this.getCurrentChannel(props);
  let channelid = null;
  if (currentChannel.length > 0) {
   if (props.channel) {
    const dirtyChannel = props.channel.filter(
     (v) => v.channel_name === currentChannel[0].key
    );
    if (dirtyChannel.length > 0) {
     channelid = dirtyChannel[0].channel_id;
    }
   }
  }
  return channelid;
 },
 getChannelIdNoProps(name, props) {
  let channelid = 0;
  if (name) {
   if (props.channel) {
    const dirtyChannel = props.channel.filter((v) => v.channel_name === name);
    if (dirtyChannel.length > 0) {
     channelid = dirtyChannel[0].channel_id;
    }
   }
  }
  return channelid;
 },
 getChannelName(props, channel_id) {
  let channelname = "";

  const dirtyChannel = props.channel.filter((v) => v.channel_id === channel_id);
  if (dirtyChannel.length > 0) {
   channelname = dirtyChannel[0].channel_name;
  }
  return channelname;
 },
 getChannelPath(props, name) {
  let path = "";
  const currentChannel = menuSidebar(false, props).filter(
   (v) => name === v.key
  );
  if (currentChannel.length > 0) {
   path = currentChannel[0].link;
  }
  return path;
 },
 getChannelKey(props, channel_id) {
  let path = "";

  const dirtyChannel = props.channel.filter((v) => v.channel_id === channel_id);
  if (dirtyChannel.length > 0) {
   path = dirtyChannel[0].key;
  }
  return path;
 },
 getInteraction(props, channelid) {
  return props.interaction
   ? props.interaction?.filter(
      (item) =>
       item.agent_id === props.auth.user.userid &&
       item.channel_id === channelid &&
       item.group_id === props.auth.loginStatus.group_id
     )
   : props?.interaction?.interaction
   ? props?.interaction?.interaction?.filter(
      (item) =>
       item.agent_id === props.auth.user.userid &&
       item.channel_id === channelid &&
       item.group_id === props.auth.loginStatus.group_id
     )
   : [];
 },
 getInteractionVoice(props, channelid) {
  return props.interaction
   ? props.interaction.filter(
      (item) =>
       item.channel_id === channelid &&
       item.group_id === props.auth.loginStatus.group_id
     )
   : [];
 },
 getQueue(props) {
  return this.getChannelId(props)
   ? props.interaction
     ? props.interaction.filter(
        (item) =>
         item.agent_id === 0 &&
         item.channel_id === this.getChannelId(props) &&
         item.group_id === props.auth.loginStatus.group_id
       )
     : []
   : [];
 },
 getText(props) {
  return this.getCurrent(props)
   ? props.text
     ? props.text.filter(
        (v) => v.session_id === this.getCurrent(props).session_id
       )
     : []
   : [];
 },
 getTextNotEqual(props) {
  return this.getCurrent(props)
   ? props.text
     ? props.text.filter(
        (v) => v.session_id !== this.getCurrent(props).session_id
       )
     : []
   : [];
 },
 getTextCwc(props, session_id) {
  return props.textCwc
   ? props.textCwc.filter((v) => v.session_id === session_id)
   : [];
 },
 getTextCwcNotEqual(props, session_id) {
  return this.getCurrent(props)
   ? props.textCwc
     ? props.textCwc.filter((v) => v.session_id !== session_id)
     : []
   : [];
 },
 getIncomingNotEqual(props) {
  const messages = this.getMessages(props);
  return props.incoming
   ? props.incoming.filter((v) => v.session_id !== messages[0].session_id)
   : [];
 },
 getMessages(props) {
  const current = this.getCurrent(props);
  const data = this.getCurrentChannel(props);
  return data.length > 0
   ? current
     ? data[0].messages.filter((v) => v.session_id === current.session_id)
     : []
   : [];
 },
 setMessages(props) {
  const data = this.getCurrentChannel(props);
  return data.length > 0 ? data.setMessages(props) : [];
 },
 pushNotification(data, message, route) {
  Notification.requestPermission();
  if (!Notification) {
   alert("Desktop notifications not available in your browser. Try Chromium.");
   return;
  }

  if (Notification.permission !== "granted") {
   Notification.requestPermission();
  } else {
   const notification = new Notification(
    data.from_name ? data.from_name : data.from,
    {
     Ico,
     body: message,
    }
   );
   notification.onclick = function () {
    // history.push(`/${route}`);
    notification.close();
   };
  }
 },
 isUrl(text) {
  const pattern = new RegExp(
   "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
   "i"
  ); // fragment locator
  return !!pattern.test(text);
 },
};
