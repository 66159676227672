/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import openSocket from "socket.io-client";
import { default as openSocketPrevious } from "socket.io-client-previous";
import helper from "./helper";
import crypto from "utils/crypto";

let userId = "";
let groupId = "";
let type = "";
let name = "";
let storage = null;
let unitId = "";
let loginPbx = "";
const tenantId = window.TENANT_ID;
if (localStorage.getItem(window.TENANT_ID)) {
  storage = JSON.parse(helper.decrypt(localStorage.getItem(window.TENANT_ID)));
  userId = storage.user.userid;
  groupId = storage.loginStatus.group_id;
  type = storage.user.userlevel;
  name = storage.user.name;
  unitId = storage.user.unit_id;
  loginPbx = storage.user.login_pbx;
}
const socket = storage
  ? openSocketPrevious(crypto.decryptedData().SOCKET_SERVER, {
      query: `userId=${userId}&tenant_id=${tenantId}&group_id=${groupId}&type=${type}&name=${name}&extension=${loginPbx}&unit_id=${unitId}`,
      transports: ["websocket"],
    })
  : null;
const socketAmi =
  storage && parseInt(window.isMizu) === 0
    ? openSocket(window.SOCKET_SERVER_AMI, {
        query: `userId=${userId}&tenant_id=${tenantId}&extension=${loginPbx}`,
        transports: ["websocket"],
      })
    : null;

export default {
  /* GENERAL */
  ctiVoice(cb) {
    return socket ? socket.on("cti-voice", (data) => cb(data)) : null;
  },
  listenAudioChannel(cb, channelId) {
    return socket
      ? socket.on(
          `newInteraction_${tenantId}_${groupId}_${channelId}`,
          (data) => cb(data)
        )
      : null;
  },
  getCountNotificationAllChannel(cb) {
    return socket
      ? socket.on(`countQueue_${tenantId}_${groupId}`, (data) => cb(data))
      : null;
  },

  getNotificationAllChannel(cb) {
    return socket
      ? socket.on(`listQueue_${tenantId}_${groupId}_0`, (data) => cb(data))
      : null;
  },
  getNotificationByChannel(cb, channelId) {
    return socket
      ? socket.on(`listQueue_${tenantId}_${groupId}_${channelId}`, (data) =>
          cb(data, channelId)
        )
      : null;
  },
  getCountTicket(cb) {
    return socket
      ? socket.on(`countTicket_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* TELEGRAM */
  storeInsertInteractionTelegram(cb) {
    return socket
      ? socket.on(`insert_interaction_telegram_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* WHATSAPP */
  storeInsertInteractionWhatsapp(cb) {
    return socket
      ? socket.on(`insert_interaction_whatsapp_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* MESSENGER */
  storeInsertInteractionMessenger(cb) {
    return socket
      ? socket.on(`insert_interaction_messenger_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },

  updateInsertInteractionMessenger(cb) {
    return socket
      ? socket.on(`update_interaction_messenger_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },

  /* SMS */
  storeInsertInteractionSms(cb) {
    return socket
      ? socket.on(`insert_interaction_sms_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* TWITTER DM */
  storeInsertInteractionTdm(cb) {
    return socket
      ? socket.on(`insert_interaction_twitter_message_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },

  /* INSTAGRAM */
  storeInsertInteractionInstagram(cb) {
    return socket
      ? socket.on(`insert_interaction_instagram_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },

  /* TWITTER */
  storeInsertInteractionTwitter(cb) {
    return socket
      ? socket.on(`insert_interaction_twitter_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* EMAIL */
  storeInsertInteractionEmail(cb) {
    return socket
      ? socket.on(`insert_interaction_email_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* CHAT */
  storeInsertInteractionChat(cb) {
    return socket
      ? socket.on(`insert_interaction_chatbot_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* LINE */
  storeInsertInteractionLine(cb) {
    return socket
      ? socket.on(`insert_interaction_line_${tenantId}`, (data) => cb(data))
      : null;
  },

  /* VOICE */
  storeInsertInteractionVoice(cb) {
    return socket
      ? socket.on(`insert_interaction_voice_${tenantId}`, (data) => cb(data))
      : null;
  },
  storeInsertInteractionVoiceHistory(cb) {
    return socket
      ? socket.on(`insert_interaction_voice_history_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },
  /* WEBCHAT */
  storeInsertInteractionWebChat(cb) {
    return socket
      ? socket.on(`insert_interaction_chat_${tenantId}`, (data) => cb(data))
      : null;
  },
  /* IG DM */
  storeInsertInteractionIgDm(cb) {
    return socket
      ? socket.on(`insert_interaction_instagram_message_${tenantId}`, (data) =>
          cb(data)
        )
      : null;
  },
  /* TICKETING */
  storeInsertTransTicket(cb) {
    return socket
      ? type === "Backroom"
        ? socket.on(`insert_trans_ticket_${tenantId}_${unitId}`, (data) =>
            cb(data)
          )
        : socket.on(`insert_trans_ticket_${tenantId}`, (data) => cb(data))
      : null;
  },
  storeUpdateTransTicket(cb) {
    return socket
      ? type === "Backroom"
        ? socket.on(`update_trans_ticket_${tenantId}_${unitId}`, (data) =>
            cb(data)
          )
        : socket.on(`update_trans_ticket_${tenantId}`, (data) => cb(data))
      : null;
  },

  socketEventAction(cb) {
    return crypto
      .decryptedData()
      .SOCKET_EVENT_ACTION.map((v) =>
        socket ? socket.on(v, (data) => cb(data)) : null
      );
  },
  amiSocket(cb) {
    return socketAmi
      ? socketAmi.on(`ami-socket-${tenantId}:${loginPbx}`, (data, cbb) => {
          console.log(data);
          cbb("sukses");
          cb(data);
        })
      : null;
  },
};
