import React from "react";
import { connect } from "react-redux";
import helper from "utils/helper";
import { Flex, Image, Text, GridContent } from "components/Layout";
import Snap from "assets/images/SNAP.jpg";

class BlankTenant extends React.Component {
 render() {
  return (
   <GridContent
    container
    justify="center"
    alignContent="center"
    style={{ height: "100%" }}
   >
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Image
      img={Snap}
      margin="auto"
      width="300px"
      minheight="150px"
      position="center"
      size="contain"
     />
    </GridContent>
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Flex direction="column" wrap="nowrap">
      <Text fs="17" color="#294354" bold textalign="left">
       No tenant code detected...
      </Text>
      <Text fs="12" color="gray" textalign="left">
       Please rewrite url with tenant code, ex : https://omni.radion.co.id/
       <b>demo</b>
      </Text>
      <Text fs="12" color="gray" textalign="left">
       Change <b>demo</b> with your tenant code
      </Text>
     </Flex>
    </GridContent>
   </GridContent>
  );
 }
}
export default connect(
 helper.mapStateToProps,
 helper.mapDispatchToProps
)(BlankTenant);
