import React from "react";
import { BrowserRouter, Router } from "react-router-dom";
import ClearCache from "react-clear-cache";
import BlankSnap from "./components/Blank/BlankSnap";
import BlankCache from "./components/Blank/BlankCache";
import history from "./utils/history";
import Main from "./apps/main";
import "./styles/font.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-tippy/dist/tippy.css";
import "react-chat-elements/dist/main.css";
import "react-datepicker/dist/react-datepicker.css";
import "webdatarocks/webdatarocks.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "video-react/dist/video-react.css";

class App extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
   error_name: "",
   error_description: "",
   error_line: "",
   hasError: false,
  };
 }

 static getDerivedStateFromError() {
  return { hasError: true };
 }

 componentDidCatch(error, errorInfo) {
  this.setState({
   error_name: error.message,
   error_description: errorInfo.componentStack,
   error_line: errorInfo.componentStack.split(":")[0],
  });
 }

 render() {
  return (
   <ClearCache>
    {({ isLatestVersion, emptyCacheStorage }) =>
     !isLatestVersion ? (
      <BlankCache emptyCacheStorage={emptyCacheStorage} />
     ) : this.state.hasError ? (
      <BlankSnap data={this.state} />
     ) : (
      <BrowserRouter basename={`/${window.TENANT_ID_URL}`}>
       <Router history={history}>
        <Main />
       </Router>
      </BrowserRouter>
     )
    }
   </ClearCache>
  );
 }
}

export default App;
