const { default: http } = require("utils/http");

// eslint-disable-next-line import/no-anonymous-default-export
export default {
 logicMicrosip(args = {}) {
  const { data, voiceByAgentRinging, voiceByAgentHangup, setOpenIncoming } =
   args;
  const gantengBanget = data ? JSON.parse(data) : null;
  console.log(gantengBanget);
  if (gantengBanget.data.event === "answer") {
   voiceByAgentRinging({
    src: gantengBanget.data.src,
    uid: gantengBanget.data.uid,
    ruid: gantengBanget.data.ruid,
   });
  }
  if (gantengBanget.data.event === "hangup") {
   voiceByAgentHangup();
  }

  if (gantengBanget.data.event === "ringing") {
   setOpenIncoming(true, gantengBanget);
  }
 },
 logicAxios(args = {}) {
  const {
   setOpenBackdrop,
   setOpenSnackbar,
   setStatusSnackbar,
   setMessage,
   setLoading,
  } = args;
  http.interceptors.request.use(
   (config) => {
    setLoading(true);
    setOpenBackdrop(true);
    return config;
   },
   (error) => {
    if (error) {
     setLoading(false);
     setOpenBackdrop(false);
     setOpenSnackbar(true);
     setStatusSnackbar("#f44336");
     setMessage(
      error.response
       ? `${error.response.data.message}`
       : "Something wrong with our server, please try again..."
     );
    }
    return Promise.reject(error);
   }
  );
  http.interceptors.response.use(
   (response) => {
    if (response) {
     //  console.log(response);
     if (response.status === 503) {
      setLoading(false);
      setOpenBackdrop(false);
      setOpenSnackbar(true);
      setStatusSnackbar("orange");
      setMessage(
       response.response
        ? `${response.response.data.message}`
        : "Something wrong with our server, please try again..."
      );
     } else {
      setLoading(false);
      setOpenBackdrop(false);
      setOpenSnackbar(false);
     }
    }
    return response;
   },
   (error) => {
    if (error) {
     console.log(error);
     setLoading(false);
     setOpenBackdrop(false);
     setOpenSnackbar(true);
     setStatusSnackbar("#f44336");
     setMessage(
      error.response
       ? `${error.response.data.message}`
       : "Something wrong with our server, please try again..."
     );
    }
   }
  );
 },
};
