import { put, call, all } from "redux-saga/effects";
import channelServices from "../../services/channelService";
import * as actions from "../actions";
import store from "../index";

export function* telegramMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.teleMessages.length === 0 || !state.teleMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "5",
    session_id: state.teleCurrent.current.session_id,
    from: state.teleCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.teleCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
  } else {
   const dataOffsetComparison = state.teleMessages.messages.filter(
    (v) => v.session_id === state.teleCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "5",
     session_id: state.teleCurrent.current.session_id,
     from: state.teleCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.teleMessages.messages,
      {
       message: data.Record,
       session_id: state.teleCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.teleMessages.messages.filter(
     (v) => v.session_id !== state.teleCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "5",
      session_id: state.teleCurrent.current.session_id,
      from: state.teleCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.teleCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.teleMessages.messages;
    }
   }
  }

  yield all([
   put(actions.teleSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* lineMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.lineMessages.length === 0 || !state.lineMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "10",
    session_id: state.lineCurrent.current.session_id,
    from: state.lineCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.lineCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
  } else {
   const dataOffsetComparison = state.lineMessages.messages.filter(
    (v) => v.session_id === state.lineCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "10",
     session_id: state.lineCurrent.current.session_id,
     from: state.lineCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.lineMessages.messages,
      {
       message: data.Record,
       session_id: state.lineCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.lineMessages.messages.filter(
     (v) => v.session_id !== state.lineCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "10",
      session_id: state.lineCurrent.current.session_id,
      from: state.lineCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.lineCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.lineMessages.messages;
    }
   }
  }

  yield all([
   put(actions.lineSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* whatsappMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.whatsappMessages.length === 0 || !state.whatsappMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "12",
    session_id: state.whatsappCurrent.current.session_id,
    from: state.whatsappCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.whatsappCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.whatsappMessages.messages.filter(
    (v) => v.session_id === state.whatsappCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "12",
     session_id: state.whatsappCurrent.current.session_id,
     from: state.whatsappCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.whatsappMessages.messages,
      {
       message: data.Record,
       session_id: state.whatsappCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.whatsappMessages.messages.filter(
     (v) => v.session_id !== state.whatsappCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "12",
      session_id: state.whatsappCurrent.current.session_id,
      from: state.whatsappCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.whatsappCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.whatsappMessages.messages;
    }
   }
  }

  yield all([
   put(actions.whatsappSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* messengerMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.messengerMessages.length === 0 || !state.messengerMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "7",
    session_id: state.messengerCurrent.current.session_id,
    from: state.messengerCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.messengerCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.messengerMessages.messages.filter(
    (v) => v.session_id === state.messengerCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "7",
     session_id: state.messengerCurrent.current.session_id,
     from: state.messengerCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.messengerMessages.messages,
      {
       message: data.Record,
       session_id: state.messengerCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual =
     state.messengerMessages.messages.filter(
      (v) => v.session_id !== state.messengerCurrent.current.session_id
     );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "7",
      session_id: state.messengerCurrent.current.session_id,
      from: state.messengerCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.messengerCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.messengerMessages.messages;
    }
   }
  }

  yield all([
   put(actions.messengerSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* smsMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.smsMessages.length === 0 || !state.smsMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "4",
    session_id: state.smsCurrent.current.session_id,
    from: state.smsCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.smsCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.smsMessages.messages.filter(
    (v) => v.session_id === state.smsCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "4",
     session_id: state.smsCurrent.current.session_id,
     from: state.smsCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.smsMessages.messages,
      {
       message: data.Record,
       session_id: state.smsCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.smsMessages.messages.filter(
     (v) => v.session_id !== state.smsCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "4",
      session_id: state.smsCurrent.current.session_id,
      from: state.smsCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.smsCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.smsMessages.messages;
    }
   }
  }

  yield all([
   put(actions.smsSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* tdmMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.tdmMessages.length === 0 || !state.tdmMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "13",
    session_id: state.tdmCurrent.current.session_id,
    from: state.tdmCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.tdmCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.tdmMessages.messages.filter(
    (v) => v.session_id === state.tdmCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "13",
     session_id: state.tdmCurrent.current.session_id,
     from: state.tdmCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.tdmMessages.messages,
      {
       message: data.Record,
       session_id: state.tdmCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.tdmMessages.messages.filter(
     (v) => v.session_id !== state.tdmCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "13",
      session_id: state.tdmCurrent.current.session_id,
      from: state.tdmCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.tdmCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.tdmMessages.messages;
    }
   }
  }

  yield all([
   put(actions.tdmSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* webchatMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.webchatMessages.length === 0 || !state.webchatMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: "3",
    session_id: state.webchatCurrent.current.session_id,
    from: state.webchatCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.webchatCurrent.current.session_id,
      type: "redux",
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.webchatMessages.messages.filter(
    (v) => v.session_id === state.webchatCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: "3",
     session_id: state.webchatCurrent.current.session_id,
     from: state.webchatCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.webchatMessages.messages,
      {
       message: data.Record,
       session_id: state.webchatCurrent.current.session_id,
       type: "redux",
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.webchatMessages.messages.filter(
     (v) => v.session_id !== state.webchatCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: "3",
      session_id: state.webchatCurrent.current.session_id,
      from: state.webchatCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.webchatCurrent.current.session_id,
        type: "redux",
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.webchatMessages.messages;
    }
   }
  }

  yield all([
   put(actions.webchatSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {
  console.log(error);
 }
}
export function* emailMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.emailMessages.length === 0 || !state.emailMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 2,
    session_id: state.emailCurrent.current.session_id,
    from: state.emailCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.emailCurrent.current.session_id,
      type: "redux",
      attachement: data.attachement,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.emailMessages.messages.filter(
    (v) => v.session_id === state.emailCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 2,
     session_id: state.emailCurrent.current.session_id,
     from: state.emailCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.emailMessages.messages,
      {
       message: data.Record,
       session_id: state.emailCurrent.current.session_id,
       type: "redux",
       attachement: data.attachement,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.emailMessages.messages.filter(
     (v) => v.session_id !== state.emailCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 2,
      session_id: state.emailCurrent.current.session_id,
      from: state.emailCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.emailCurrent.current.session_id,
        type: "redux",
        attachement: data.attachement,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.emailMessages.messages;
    }
   }
  }

  yield all([
   put(actions.emailSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}

export function* facebookMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.facebookMessages.length === 0 || !state.facebookMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 6,
    session_id: state.facebookCurrent.current.session_id,
    from: state.facebookCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.facebookCurrent.current.session_id,
      type: "redux",
      interaction: data.interaction,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.facebookMessages.messages.filter(
    (v) => v.session_id === state.facebookCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 6,
     session_id: state.facebookCurrent.current.session_id,
     from: state.facebookCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.facebookMessages.messages,
      {
       message: data.Record,
       session_id: state.facebookCurrent.current.session_id,
       type: "redux",
       interaction: data.interaction,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.facebookMessages.messages.filter(
     (v) => v.session_id !== state.facebookCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 6,
      session_id: state.facebookCurrent.current.session_id,
      from: state.facebookCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.facebookCurrent.current.session_id,
        type: "redux",
        interaction: data.interaction,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.facebookMessages.messages;
    }
   }
  }

  yield all([
   put(actions.facebookSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}
export function* twitterMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.twitterMessages.length === 0 || !state.twitterMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 8,
    session_id: state.twitterCurrent.current.session_id,
    from: state.twitterCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.twitterCurrent.current.session_id,
      type: "redux",
      interaction: data.interaction,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.twitterMessages.messages.filter(
    (v) => v.session_id === state.twitterCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 8,
     session_id: state.twitterCurrent.current.session_id,
     from: state.twitterCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.twitterMessages.messages,
      {
       message: data.Record,
       session_id: state.twitterCurrent.current.session_id,
       type: "redux",
       interaction: data.interaction,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.twitterMessages.messages.filter(
     (v) => v.session_id !== state.twitterCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 8,
      session_id: state.twitterCurrent.current.session_id,
      from: state.twitterCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.twitterCurrent.current.session_id,
        type: "redux",
        interaction: data.interaction,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.twitterMessages.messages;
    }
   }
  }

  yield all([
   put(actions.twitterSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}
export function* instagramMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.instagramMessages.length === 0 || !state.instagramMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 11,
    session_id: state.instagramCurrent.current.session_id,
    from: state.instagramCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.instagramCurrent.current.session_id,
      type: "redux",
      interaction: data.interaction,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.instagramMessages.messages.filter(
    (v) => v.session_id === state.instagramCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 11,
     session_id: state.instagramCurrent.current.session_id,
     from: state.instagramCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.instagramMessages.messages,
      {
       message: data.Record,
       session_id: state.instagramCurrent.current.session_id,
       type: "redux",
       interaction: data.interaction,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual =
     state.instagramMessages.messages.filter(
      (v) => v.session_id !== state.instagramCurrent.current.session_id
     );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 11,
      session_id: state.instagramCurrent.current.session_id,
      from: state.instagramCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.instagramCurrent.current.session_id,
        type: "redux",
        interaction: data.interaction,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.instagramMessages.messages;
    }
   }
  }

  yield all([
   put(actions.instagramSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}
export function* vidcallMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.vidcallMessages.length === 0 || !state.vidcallMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 9,
    session_id: state.vidcallCurrent.current.session_id,
    from: state.vidcallCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.vidcallCurrent.current.session_id,
      type: "redux",
      interaction: data.interaction,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.vidcallMessages.messages.filter(
    (v) => v.session_id === state.vidcallCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 9,
     session_id: state.vidcallCurrent.current.session_id,
     from: state.vidcallCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.vidcallMessages.messages,
      {
       message: data.Record,
       session_id: state.vidcallCurrent.current.session_id,
       type: "redux",
       interaction: data.interaction,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.vidcallMessages.messages.filter(
     (v) => v.session_id !== state.vidcallCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 9,
      session_id: state.vidcallCurrent.current.session_id,
      from: state.vidcallCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.vidcallCurrent.current.session_id,
        type: "redux",
        interaction: data.interaction,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.vidcallMessages.messages;
    }
   }
  }

  yield all([
   put(actions.vidcallSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}

export function* voiceMessagesWatcher() {
 try {
  const state = store.getState();
  const { auth } = state.auth;
  let returnable = [];
  let hideTabInteraction = false;
  if (state.voiceMessages.length === 0 || !state.voiceMessages) {
   const data = yield call(channelServices.getInteraction, {
    channel_id: 1,
    session_id: state.voiceCurrent.current.session_id,
    from: state.voiceCurrent.current.from,
    tenant_id: window.TENANT_ID,
    agent_id: auth.user.userid,
    type: "realtime",
   });
   if (data.status === 200) {
    returnable = [
     {
      message: data.Record,
      session_id: state.voiceCurrent.current.session_id,
      type: "redux",
      interaction: data.interaction,
     },
    ];
   }
   if (data) {
    if (data.Record.length === 0) {
     hideTabInteraction = false;
    }
   }
  } else {
   const dataOffsetComparison = state.voiceMessages.messages.filter(
    (v) => v.session_id === state.voiceCurrent.current.session_id
   );
   if (dataOffsetComparison.length === 0) {
    const data = yield call(channelServices.getInteraction, {
     channel_id: 1,
     session_id: state.voiceCurrent.current.session_id,
     from: state.voiceCurrent.current.from,
     tenant_id: window.TENANT_ID,
     agent_id: auth.user.userid,
     type: "realtime",
    });
    if (data.status === 200) {
     returnable = [
      ...state.voiceMessages.messages,
      {
       message: data.Record,
       session_id: state.voiceCurrent.current.session_id,
       type: "redux",
       interaction: data.interaction,
      },
     ];
    }
    if (data) {
     if (data.Record.length === 0) {
      hideTabInteraction = false;
     }
    }
   } else {
    const dataOffsetComparisonNotEqual = state.voiceMessages.messages.filter(
     (v) => v.session_id !== state.voiceCurrent.current.session_id
    );
    if (dataOffsetComparison[0].type !== "redux") {
     const data = yield call(channelServices.getInteraction, {
      channel_id: 1,
      session_id: state.voiceCurrent.current.session_id,
      from: state.voiceCurrent.current.from,
      tenant_id: window.TENANT_ID,
      agent_id: auth.user.userid,
      type: "realtime",
     });
     if (data.status === 200) {
      returnable = [
       ...dataOffsetComparisonNotEqual,
       {
        message: data.Record,
        session_id: state.voiceCurrent.current.session_id,
        type: "redux",
        interaction: data.interaction,
       },
      ];
     }
     if (data) {
      if (data.Record.length === 0) {
       hideTabInteraction = false;
      }
     }
    } else {
     returnable = state.voiceMessages.messages;
    }
   }
  }

  yield all([
   put(actions.voiceSetMessages(returnable)),
   put(actions.generalSetHideTabInteraction(hideTabInteraction)),
  ]);
 } catch (error) {}
}

export function* igMessagesWatcher() {
    try {
     const state = store.getState();
     const { auth } = state.auth;
     let returnable = [];
     let hideTabInteraction = false;
     if (state.igMessages.length === 0 || !state.igMessages) {
      const data = yield call(channelServices.getInteraction, {
       channel_id: "16",
       session_id: state.igCurrent.current.session_id,
       from: state.igCurrent.current.from,
       tenant_id: window.TENANT_ID,
       agent_id: auth.user.userid,
       type: "realtime",
      });
      if (data) {
       if (data.Record.length === 0) {
        hideTabInteraction = false;
       }
      }
      if (data.status === 200) {
       returnable = [
        {
         message: data.Record,
         session_id: state.igCurrent.current.session_id,
         type: "redux",
        },
       ];
      }
     } else {
      const dataOffsetComparison = state.igMessages.messages.filter(
       (v) => v.session_id === state.igCurrent.current.session_id
      );
      if (dataOffsetComparison.length === 0) {
       const data = yield call(channelServices.getInteraction, {
        channel_id: "16",
        session_id: state.igCurrent.current.session_id,
        from: state.igCurrent.current.from,
        tenant_id: window.TENANT_ID,
        agent_id: auth.user.userid,
        type: "realtime",
       });
       if (data.status === 200) {
        returnable = [
         ...state.igMessages.messages,
         {
          message: data.Record,
          session_id: state.igCurrent.current.session_id,
          type: "redux",
         },
        ];
       }
       if (data) {
        if (data.Record.length === 0) {
         hideTabInteraction = false;
        }
       }
      } else {
       const dataOffsetComparisonNotEqual = state.igMessages.messages.filter(
        (v) => v.session_id !== state.igCurrent.current.session_id
       );
       if (dataOffsetComparison[0].type !== "redux") {
        const data = yield call(channelServices.getInteraction, {
         channel_id: "16",
         session_id: state.igCurrent.current.session_id,
         from: state.igCurrent.current.from,
         tenant_id: window.TENANT_ID,
         agent_id: auth.user.userid,
         type: "realtime",
        });
        if (data.status === 200) {
         returnable = [
          ...dataOffsetComparisonNotEqual,
          {
           message: data.Record,
           session_id: state.igCurrent.current.session_id,
           type: "redux",
          },
         ];
        }
        if (data) {
         if (data.Record.length === 0) {
          hideTabInteraction = false;
         }
        }
       } else {
        returnable = state.igMessages.messages;
       }
      }
     }
   
     yield all([
      put(actions.igSetMessages(returnable)),
      put(actions.generalSetHideTabInteraction(hideTabInteraction)),
     ]);
    } catch (error) {
     console.log(error);
    }
   }
   