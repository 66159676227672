export const generalGetLoading = (data) => ({ type: "LOADING_GET", data });

export const generalSetLoading = (loading) => ({
 type: "LOADING_SET",
 loading,
});

export const generalGetAuth = (data) => ({ type: "AUTH_GET", data });

export const generalSetAuth = (auth) => ({
 type: "AUTH_SET",
 auth,
});

export const generalGetAuthRequest = (data) => ({
 type: "AUTH_REQUEST_GET",
 data,
});

export const generalSetARequest = (auth) => ({
 type: "AUTH_REQUEST_SET",
 auth,
});

export const generalGetChannel = (data) => ({ type: "CHANNEL_GET", data });

export const generalSetChannel = (channel) => ({
 type: "CHANNEL_SET",
 channel,
});

export const generalGetChannelAgent = (data) => ({
 type: "CHANNEL_AGENT_GET",
 data,
});

export const generalSetChannelAgent = (channel) => ({
 type: "CHANNEL_AGENT_SET",
 channel,
});

export const generalGetNotification = (data) => ({
 type: "NOTIFICATION_GET",
 data,
});

export const generalSetNotification = (notification) => ({
 type: "NOTIFICATION_SET",
 notification,
});

export const generalGetRoute = (data) => ({ type: "ROUTE_GET", data });

export const generalSetRoute = (route) => ({
 type: "ROUTE_SET",
 route,
});

export const generalGetJourney = (data) => ({ type: "JOURNEY_GET", data });

export const generalSetJourney = (journey) => ({
 type: "JOURNEY_SET",
 journey,
});

export const generalGetProfile = (data) => ({ type: "PROFILE_GET", data });

export const generalSetProfile = (profile) => ({
 type: "PROFILE_SET",
 profile,
});

export const generalGetInteraction = (data) => ({
 type: "INTERACTION_GET",
 data,
});

export const generalSetInteraction = (interaction) => ({
 type: "INTERACTION_SET",
 interaction,
});

export const generalGetTabInteraction = (data) => ({
 type: "TAB_INTERACTION_GET",
 data,
});

export const generalSetTabInteraction = (value) => ({
 type: "TAB_INTERACTION_SET",
 value,
});

export const generalGetTabInfo = (data) => ({
 type: "TAB_INFO_GET",
 data,
});

export const generalSetTabInfo = (value) => ({
 type: "TAB_INFO_SET",
 value,
});

export const generalGetIncoming = (data) => ({
 type: "INCOMING_GET",
 data,
});

export const generalSetIncoming = (incoming) => ({
 type: "INCOMING_SET",
 incoming,
});

export const generalGetText = (data) => ({
 type: "TEXT_GET",
 data,
});

export const generalSetText = (text) => ({
 type: "TEXT_SET",
 text,
});

export const generalGetTextCwc = (data) => ({
 type: "TEXT_CWC_GET",
 data,
});

export const generalSetTextCwc = (text) => ({
 type: "TEXT_CWC_SET",
 text,
});

export const generalGetCategory = (data) => ({
 type: "CATEGORY_GET",
 data,
});

export const generalSetCategory = (category) => ({
 type: "CATEGORY_SET",
 category,
});

export const generalGetCwcId = (data) => ({
 type: "CWC_ID_GET",
 data,
});

export const generalSetCwcId = (id) => ({
 type: "CWC_ID_SET",
 id,
});

export const generalGetEndDate = (data) => ({
 type: "END_DATE_GET",
 data,
});

export const generalSetEndDate = (date) => ({
 type: "END_DATE_SET",
 date,
});

export const generalGetDisabled = (data) => ({
 type: "DISABLED_GET",
 data,
});

export const generalSetDisabled = (disabled) => ({
 type: "DISABLED_SET",
 disabled,
});

export const generalGetHideLoadMoreTicket = (data) => ({
 type: "HIDE_LOAD_MORE_TICKET_GET",
 data,
});

export const generalSetHideLoadMoreTicket = (hide) => ({
 type: "HIDE_LOAD_MORE_TICKET_SET",
 hide,
});

export const generalGetHideLoadMoreEmailSpv = (data) => ({
 type: "HIDE_LOAD_MORE_EMAIL_SPV_GET",
 data,
});

export const generalSetHideLoadMoreEmailSpv = (hide) => ({
 type: "HIDE_LOAD_MORE_EMAIL_SPV_SET",
 hide,
});

export const generalGetSumCwc = (data) => ({
 type: "SUM_CWC_GET",
 data,
});

export const generalSetSumCwc = (cwc) => ({
 type: "SUM_CWC_SET",
 cwc,
});

export const generalGetAllEmail = (data) => ({
 type: "STATUS_ALL_EMAIL_GET",
 data,
});

export const generalSetAllEmail = (status) => ({
 type: "STATUS_ALL_EMAIL_SET",
 status,
});

export const generalGetTemplate = (data) => ({
 type: "TEMPLATE_GET",
 data,
});

export const generalSetTemplate = (data) => ({
 type: "TEMPLATE_SET",
 data,
});

export const generalGetValidVersion = (valid) => ({
 type: "VALID_VERSION_GET",
 valid,
});

export const generalSetValidVersion = (valid) => ({
 type: "VALID_VERSION_SET",
 valid,
});

export const generalGetValidLogout = (valid) => ({
 type: "VALID_LOGOUT_GET",
 valid,
});

export const generalSetValidLogout = (valid) => ({
 type: "VALID_LOGOUT_SET",
 valid,
});

export const generalGetLogCwc = (logCwc) => ({
 type: "LOG_CWC_GET",
 logCwc,
});

export const generalSetLogCwc = (logCwc) => ({
 type: "LOG_CWC_SET",
 logCwc,
});

export const generalGetCurrentLogCwc = (current) => ({
 type: "LOG_CWC_CURRENT_GET",
 current,
});

export const generalSetCurrentLogCwc = (current) => ({
 type: "LOG_CWC_CURRENT_SET",
 current,
});

export const generalGetHideLoadMoreLogCwc = (data) => ({
 type: "HIDE_LOAD_MORE_LOG_CWC_GET",
 data,
});

export const generalSetHideLoadMoreLogCwc = (hide) => ({
 type: "HIDE_LOAD_MORE_LOG_CWC_SET",
 hide,
});

export const generalGetLogInteraction = (interaction) => ({
 type: "LOG_INTERACTION_GET",
 interaction,
});

export const generalSetLogInteraction = (interaction) => ({
 type: "LOG_INTERACTION_SET",
 interaction,
});

export const generalGetCall = (call) => ({
 type: "CALL_GET",
 call,
});

export const generalSetCall = (call) => ({
 type: "CALL_SET",
 call,
});

export const generalGetStatusCall = (status) => ({
 type: "CALL_STATUS_GET",
 status,
});

export const generalSetStatusCall = (status) => ({
 type: "CALL_STATUS_SET",
 status,
});

export const generalGetStatusCallRegister = (status) => ({
 type: "CALL_STATUS_REGISTER_GET",
 status,
});

export const generalSetStatusCallRegister = (status) => ({
 type: "CALL_STATUS_REGISTER_SET",
 status,
});

export const generalGetDurationCall = (duration) => ({
 type: "DURATION_CALL_GET",
 duration,
});

export const generalSetDurationCall = (duration) => ({
 type: "DURATION_CALL_SET",
 duration,
});

export const generalGetIsCall = (call) => ({
 type: "IS_CALL_GET",
 call,
});

export const generalSetIsCall = (call) => ({
 type: "IS_CALL_SET",
 call,
});

export const generalGetAddForm = (form) => ({
 type: "ADD_FORM_GET",
 form,
});

export const generalSetAddForm = (form) => ({
 type: "ADD_FORM_SET",
 form,
});

export const generalGetAddFormOpt = (form) => ({
 type: "ADD_FORM_OPT_GET",
 form,
});

export const generalSetAddFormOpt = (form) => ({
 type: "ADD_FORM_OPT_SET",
 form,
});

export const generalGetArchiveId = (id) => ({
 type: "ARCHIVE_ID_GET",
 id,
});

export const generalSetArchiveId = (id) => ({
 type: "ARCHIVE_ID_SET",
 id,
});

export const generalGetHideTabInteraction = (hide) => ({
 type: "HIDE_TAB_INTERACTION_GET",
 hide,
});

export const generalSetHideTabInteraction = (hide) => ({
 type: "HIDE_TAB_INTERACTION_SET",
 hide,
});

export const generalGetActiveManualKip = (active) => ({
 type: "ACTIVE_MANUAL_KIP_GET",
 active,
});

export const generalSetActiveManualKip = (active) => ({
 type: "ACTIVE_MANUAL_KIP_SET",
 active,
});

export const generalGetOutcall = (active) => ({
 type: "OUT_CALL_GET",
 active,
});

export const generalSetOutcall = (active) => ({
 type: "OUT_CALL_SET",
 active,
});

export const generalGetManualKip = (manual) => ({
 type: "MANUAL_KIP_GET",
 manual,
});

export const generalSetManualKip = (manual) => ({
 type: "MANUAL_KIP_SET",
 manual,
});

export const generalGetMergeCwc = (mergeCwc) => ({
 type: "MERGE_CWC_GET",
 mergeCwc,
});

export const generalSetMergeCwc = (mergeCwc) => ({
 type: "MERGE_CWC_SET",
 mergeCwc,
});

export const generalSetOpenIncoming = (open) => ({
 type: "OPEN_INCOMING_SET",
 open,
});

export const generalSetFirst = (first) => ({
 type: "FIRST_SET",
 first,
});

export const generalSetSecond = (second) => ({
 type: "SECOND_SET",
 second,
});

export const generalSetEvent = (event) => ({
 type: "EVENT_SET",
 event,
});
