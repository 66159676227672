import React from "react";
import {
 Flex,
 Image,
 Text,
 ButtonPrimary,
 GridContent,
} from "components/Layout";
import Snap from "assets/images/RESET.png";

class BlankCache extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
   openModal: false,
  };
 }

 render() {
  const { emptyCacheStorage } = this.props;
  return (
   <GridContent
    container
    justify="center"
    alignContent="center"
    style={{ height: "100%" }}
   >
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Image
      img={Snap}
      margin="auto"
      width="300px"
      minheight="150px"
      position="center"
      size="contain"
     />
    </GridContent>
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Flex direction="column" wrap="nowrap">
      <Text fs="17" color="#294354" bold textalign="left">
       Version apps is outdated!!!
      </Text>
      <Text fs="12" color="gray" textalign="left">
       Your version is outdated, please update version!
      </Text>
      <Flex wrap="nowrap">
       <Flex nowrap="nowrap" margin="10px 0 0 0">
        <ButtonPrimary
         bgcolor="transparent"
         onClick={() => emptyCacheStorage()}
         size="small"
         fs="12px"
         color={window.palette.primary.main}
         bold="bold"
        >
         Update version
        </ButtonPrimary>
       </Flex>
      </Flex>
     </Flex>
    </GridContent>
   </GridContent>
  );
 }
}
export default BlankCache;
