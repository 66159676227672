export const customerGet = (data) => ({ type: 'CUSTOMER_GET', data });

export const customerSet = (customer) => ({
  type: 'CUSTOMER_SET',
  customer,
});

export const customerCurrentGet = (data) => ({ type: 'CUSTOMER_CURRENT_GET', data });

export const customerCurrentSet = (current) => ({
  type: 'CUSTOMER_CURRENT_SET',
  current,
});

export const customerLoadMoreGet = (data) => ({ type: 'CUSTOMER_LOAD_MORE_GET', data });

export const customerLoadMoreSet = (hide) => ({
  type: 'CUSTOMER_LOAD_MORE_SET',
  hide,
});