import { put, call, all } from "redux-saga/effects";
import * as actions from "../actions";
import store from "../index";
import ticketService from "../../services/ticketService";
import { profileWatcher } from "./general";

export function* ticketWatcher(action) {
 console.log(action.data);
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   limit: 10,
   offset: action.data.offset,
   order: `create_date ${
    action.data.order ? action.data.order : "desc"
   },ticket_priority desc`,
   filter: action.data.filter,
   operator: action.data.operator,
   from: action.data.from,
  };
  const data = yield call(ticketService.getTicket, postData);
  let returnable = [];
  let returnableLoadMore = false;
  if (data.TotalPages <= 1) {
   returnableLoadMore = true;
  }
  const state = store.getState();
  if (action.data.type === "load_more") {
   const dataComparison = state.ticket.ticket;
   let dataRealClose = [];
   let dataRealNotClose = [];
   let dataRealCompare = [];
   if (data.Record.length > 0) {
    dataRealClose = data.Record.filter((v) => v.ticket_status === 8);
    dataRealNotClose = data.Record.filter((v) => v.ticket_status !== 8);
    dataRealCompare = dataRealNotClose.concat(dataRealClose);
   }
   returnable = dataComparison.concat(dataRealCompare);
   if (action.data.offset >= data.TotalPages - 1) {
    returnableLoadMore = true;
   }
  } else {
   let dataRealClose = [];
   let dataRealNotClose = [];
   let dataRealCompare = [];
   if (data.Record.length > 0) {
    dataRealClose = data.Record.filter((v) => v.ticket_status === 8);
    dataRealNotClose = data.Record.filter((v) => v.ticket_status !== 8);
    dataRealCompare = dataRealNotClose.concat(dataRealClose);
   }
   returnable = dataRealCompare;
  }
  yield all([
   put(actions.ticketSet(returnable)),
   put(actions.generalSetHideLoadMoreTicket(returnableLoadMore)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* ticketStatusWatcher() {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   limit: 20,
   offset: 0,
   order: "status_id desc",
  };
  const data = yield call(ticketService.getTicketStatus, postData);
  yield put(actions.ticketSetStatus(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* ticketUnitWatcher() {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   limit: 20,
   offset: 0,
   order: "unit_id desc",
  };
  const data = yield call(ticketService.getTicketUnit, postData);
  yield put(actions.ticketSetUnit(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* statusLevelWatcher() {
 try {
  const state = store.getState();
  const postData = {
   tenant_id: window.TENANT_ID,
   filter: {
    level: `'${state.auth.auth.user.userlevel}'`,
   },
  };
  const data = yield call(ticketService.getTicketStatusLevel, postData);
  yield put(actions.ticketSetStatusLevel(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* caseOriginWatcher() {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
  };
  const data = yield call(ticketService.getCaseOrigin, postData);
  yield put(actions.ticketSetCaseOrigin(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* serviceAreaWatcher() {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
  };
  const data = yield call(ticketService.getServiceArea, postData);
  yield put(actions.ticketSetServiceArea(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* statusNextWatcher(action) {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   filter: {
    status_id: action.data.status_id,
   },
  };
  const data = yield call(ticketService.getStatusNext, postData);
  yield put(actions.ticketSetStatusNext(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* stageTicketWatcher(action) {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   ticket_id: action.data.ticket_id,
  };
  const data = yield call(ticketService.getStageTicket, postData);
  yield put(actions.ticketSetStageTicket(data.Record));
 } catch (error) {
  console.log(error);
 }
}

export function* ticketHistoryWatcher(action) {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   filter: {
    ticket_id: action.data.ticket_id,
   },
  };
  const data = yield call(ticketService.getTicketHistory, postData);

  yield all([
   call(profileWatcher, {
    data: {
     tenant_id: window.TENANT_ID,
     cust_id: action.data.cust_id,
    },
   }),
   put(actions.ticketSetHistory(data.Record)),
  ]);
 } catch (error) {
  console.log(error);
 }
}

export function* additionalTicketWatcher(action) {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   filter: {
    ticket_id: action.data.ticket_id,
    table_master: "ticketing",
   },
  };
  const data = yield call(ticketService.getAdditionalField, postData);
  yield put(actions.ticketSetAdditionalTicket(data));
 } catch (error) {
  console.log(error);
 }
}

export function* additionalValueWatcher(action) {
 try {
  const postData = {
   tenant_id: window.TENANT_ID,
   form_group: "cwc",
   key: action.data.cwc_id,
  };
  const data = yield call(ticketService.getAdditionalValue, postData);
  yield put(actions.ticketSetAdditionalValue(data));
 } catch (error) {
  console.log(error);
 }
}
