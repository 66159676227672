import React from "react";
import {
 MdDashboard,
 MdPerson,
 MdPhoneInTalk,
 MdQuestionAnswer,
 MdChatBubble,
 MdPeople,
 MdViewList,
 MdInfo,
 MdFlag,
 MdAccessibility,
 MdKeyboardArrowRight,
 MdRecentActors,
 MdFlashOn,
 MdPanTool,
 MdHistory,
 //  MdStars,
 MdAssignment,
 MdTimeline,
 MdCloud,
 MdVideocam,
 MdRecordVoiceOver,
 MdTune,
 MdInput,

 //  MdEmail,
} from "react-icons/md";
import {
 FaTicketAlt,
 FaHistory,
 FaWhatsapp,
 FaTelegramPlane,
 FaFacebookMessenger,
 FaFacebookSquare,
 FaTwitter,
 FaTwitterSquare,
 FaInstagram,
 FaCommentAlt,
 FaMap,
 FaLine,
 FaInstagramSquare,
} from "react-icons/fa";
import { FiAtSign } from "react-icons/fi";
import { AiOutlineInteraction } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import channelService from "../../services/channelService";
import {
 POST_OUTGOING_TELEGRAM,
 POST_OUTGOING_WHATSAPP,
 POST_OUTGOING_MESSENGER,
 POST_OUTGOING_TDM,
 POST_OUTGOING_SMS,
 POST_OUTGOING_WEBCHAT,
 POST_OUTGOING_FACEBOOK,
 POST_OUTGOING_TWITTER,
 POST_OUTGOING_INSTAGRAM,
 POST_OUTGOING_LINE,
 POST_OUTGOING_IG
} from "../../utils/api";
import blockIcon from "../../assets/images/BLOCK-24px 2.png";
import groupIcon from "../../assets/images/GROUP DETAIL-24px 2.png";
import skillIcon from "../../assets/images/SKILL SETTING-24px 2.png";
import templateIcon from "../../assets/images/TEMPLATE MANAGE-24px 2.png";
import custFieldIcon from "../../assets/images/CustField.png";
import whiteListIcon from "../../assets/images/Whitelist.png";
import parkingIcon from "../../assets/images/PARKING.png";
import custRetentionIcon from "../../assets/images/CUSTOMER.png";
import videoCallIcon from "../../assets/images/video_call_icon.png";
import threshold from "../../assets/images/THRESHOLD.PNG";

export const menuSidebar = (mobile, props, colorVoice) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;

 return [
  {
   icon: <MdPhoneInTalk size={size} color={colorVoice || color} />,
   name: "Voice",
   notif: 0,
   link: "/voice",
   key: "Voice",
   is_active: 0,
   current: props ? (props.voiceCurrent ? props.voiceCurrent : null) : null,
   setCurrent: props ? props.setVoiceCurrent : null,
   setMessages: props ? props.setVoiceMessages : null,
   getMessages: props ? props.getVoiceMessages : null,
   messages: props ? (props.voiceMessages ? props.voiceMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_SMS,
   field: "voice",
  },
  {
   icon: <FaCommentAlt size={size} color={color} />,
   name: "SMS",
   notif: 0,
   link: "/sms",
   key: "SMS",
   is_active: 0,
   current: props ? (props.smsCurrent ? props.smsCurrent : null) : null,
   setCurrent: props ? props.setSmsCurrent : null,
   setMessages: props ? props.setSmsMessages : null,
   getMessages: props ? props.getSmsMessages : null,
   messages: props ? (props.smsMessages ? props.smsMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_SMS,
   field: "sms",
  },
  {
   icon: <MdVideocam size={size} color={color} />,
   name: "Vidcall",
   notif: 0,
   link: "/video-call",
   key: "Video Call",
   is_active: 0,
   current: props ? (props.vidcallCurrent ? props.vidcallCurrent : null) : null,
   setCurrent: props ? props.setVidcallCurrent : null,
   setMessages: props ? props.setVidcallMessages : null,
   getMessages: props ? props.getVidcallMessages : null,
   messages: props ? (props.vidcallMessages ? props.vidcallMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_SMS,
   field: "vicall",
  },
  {
   icon: <FiAtSign size={size} color={color} />,
   name: "Email",
   notif: 0,
   link: "/email",
   key: "Email",
   is_active: 0,
   current: props ? (props.emailCurrent ? props.emailCurrent : null) : null,
   setCurrent: props ? props.setEmailCurrent : null,
   setMessages: props ? props.setEmailMessages : null,
   getMessages: props ? props.getEmailMessages : null,
   messages: props ? (props.emailMessages ? props.emailMessages : []) : [],
   sendMessage: channelService.sendMessageEmail,
   field: "email",
  },
  {
   icon: <MdQuestionAnswer size={size} color={color} />,
   name: "Chat",
   notif: 0,
   link: "/chat",
   key: "ChatBot",
   is_active: 0,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "LiveCh",
   notif: 0,
   link: "/webchat",
   key: "Live Chat",
   is_active: 0,
   current: props ? (props.webchatCurrent ? props.webchatCurrent : null) : null,
   setCurrent: props ? props.setWebchatCurrent : null,
   setMessages: props ? props.setWebchatMessages : null,
   getMessages: props ? props.getWebchatMessages : null,
   messages: props ? (props.webchatMessages ? props.webchatMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_WEBCHAT,
   field: "webchat",
  },
  {
   icon: <FaLine size={size} color={color} />,
   name: "Line",
   notif: 0,
   link: "/line",
   key: "Line",
   is_active: 0,
   current: props ? (props.lineCurrent ? props.lineCurrent : null) : null,
   setCurrent: props ? props.setLineCurrent : null,
   setMessages: props ? props.setLineMessages : null,
   getMessages: props ? props.getLineMessages : null,
   messages: props ? (props.lineMessages ? props.lineMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_LINE,
   field: "line",
  },
  {
   icon: <FaTelegramPlane size={size} color={color} />,
   name: "Tele",
   notif: 0,
   link: "/telegram",
   key: "Telegram",
   is_active: 0,
   current: props ? (props.teleCurrent ? props.teleCurrent : null) : null,
   setCurrent: props ? props.setTeleCurrent : null,
   setMessages: props ? props.setTeleMessages : null,
   getMessages: props ? props.getTeleMessages : null,
   messages: props ? (props.teleMessages ? props.teleMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_TELEGRAM,
   field: "telegram",
  },
  {
   icon: <FaWhatsapp size={size} color={color} />,
   name: "WA",
   notif: 0,
   link: "/whatsapp",
   key: "Whatsapp",
   is_active: 0,
   current: props
    ? props.whatsappCurrent
      ? props.whatsappCurrent
      : null
    : null,
   setCurrent: props ? props.setWhatsappCurrent : null,
   setMessages: props ? props.setWhatsappMessages : null,
   getMessages: props ? props.getWhatsappMessages : null,
   messages: props
    ? props.whatsappMessages
      ? props.whatsappMessages
      : []
    : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_WHATSAPP,
   field: "whatsapp",
  },
  {
   icon: <FaFacebookMessenger size={size} color={color} />,
   name: "MSG",
   notif: 0,
   link: "/messenger",
   key: "Facebook Messenger",
   is_active: 0,
   current: props
    ? props.messengerCurrent
      ? props.messengerCurrent
      : null
    : null,
   setCurrent: props ? props.setMessengerCurrent : null,
   setMessages: props ? props.setMessengerMessages : null,
   getMessages: props ? props.getMessengerMessages : null,
   messages: props
    ? props.messengerMessages
      ? props.messengerMessages
      : []
    : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_MESSENGER,
   field: "facebookMsg",
  },
  {
   icon: <FaFacebookSquare size={size} color={color} />,
   name: "FB",
   notif: 0,
   link: "/facebook",
   key: "Facebook",
   is_active: 0,
   current: props
    ? props.facebookCurrent
      ? props.facebookCurrent
      : null
    : null,
   setCurrent: props ? props.setFacebookCurrent : null,
   setMessages: props ? props.setFacebookMessages : null,
   getMessages: props ? props.getFacebookMessages : null,
   messages: props
    ? props.facebookMessages
      ? props.facebookMessages
      : []
    : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_FACEBOOK,
   field: "facebook",
  },
  {
   icon: <FaTwitter size={size} color={color} />,
   name: "TW",
   notif: 0,
   link: "/twitter",
   key: "Twitter",
   is_active: 0,
   current: props ? (props.twitterCurrent ? props.twitterCurrent : null) : null,
   setCurrent: props ? props.setTwitterCurrent : null,
   setMessages: props ? props.setTwitterMessages : null,
   getMessages: props ? props.getTwitterMessages : null,
   messages: props ? (props.twitterMessages ? props.twitterMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_TWITTER,
   field: "twitter",
  },
  {
   icon: <FaTwitterSquare size={size} color={color} />,
   name: "TDM",
   notif: 0,
   link: "/dm",
   key: "Twitter DM",
   is_active: 0,
   current: props ? (props.tdmCurrent ? props.tdmCurrent : null) : null,
   setCurrent: props ? props.setTdmCurrent : null,
   setMessages: props ? props.setTdmMessages : null,
   getMessages: props ? props.getTdmMessages : null,
   messages: props ? (props.tdmMessages ? props.tdmMessages : []) : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_TDM,
   field: "twitterMsg",
  },
  {
   icon: <FaInstagram size={size} color={color} />,
   name: "IG",
   notif: 0,
   link: "/instagram",
   key: "Instagram",
   is_active: 0,
   current: props
    ? props.instagramCurrent
      ? props.instagramCurrent
      : null
    : null,
   setCurrent: props ? props.setInstagramCurrent : null,
   setMessages: props ? props.setInstagramMessages : null,
   getMessages: props ? props.getInstagramMessages : null,
   messages: props
    ? props.instagramMessages
      ? props.instagramMessages
      : []
    : [],
   sendMessage: channelService.sendMessageRtc,
   outgoing: POST_OUTGOING_INSTAGRAM,
   field: "instagram",
  },
  {
    icon: <FaInstagramSquare size={size} color={color} />,
    name: "IGDM",
    notif: 0,
    link: "/igdm",
    key: "Instagram DM",
    is_active: 0,
    current: props ? (props.igCurrent ? props.igCurrent : null) : null,
    setCurrent: props ? props.setIgCurrent : null,
    setMessages: props ? props.setIgMessages : null,
    getMessages: props ? props.getIgMessages : null,
    messages: props ? (props.igMessages ? props.igMessages : []) : [],
    sendMessage: channelService.sendMessageRtc,
    outgoing: POST_OUTGOING_IG,
    field: "instagramdm",
   }, 
 ];
};

export const menuNavbar = (mobile) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 return [
  {
   icon: <MdDashboard size={size} color={color} />,
   name: "Home",
   notif: 0,
   link: "/dashboard",
   show: true,
  },
  {
   icon: <FaTicketAlt size={size} color={color} />,
   name: "Case Management",
   notif: 0,
   link: "/ticketing",
   show: true,
  },
  {
   icon: <FaHistory size={size} color={color} />,
   name: "Log Session",
   notif: 0,
   link: "/log-kip",
   show: true,
  },

  {
   icon: <MdRecordVoiceOver size={size} color={color} />,
   name: "Log Follow Up",
   notif: 0,
   link: "/log-follow-up",
   show: true,
  },
  {
   icon: <MdPerson size={size} color={color} />,
   name: "Customer",
   notif: 0,
   link: "/customer",
   show: true,
  },
 ];
};

export const menuSidebarMasterData = (mobile) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 const mainRoute = "/spv-";
 return [
  {
   icon: <MdViewList size={size} color={color} />,
   name: "Category",
   link: `${mainRoute}category`,
  },
  {
   icon: <MdViewList size={size} color={color} />,
   name: "Sub Category",
   link: `${mainRoute}sub-category`,
  },
  {
   icon: <MdInfo size={size} color={color} />,
   name: "Information",
   link: `${mainRoute}information`,
  },
  {
   icon: <img src={templateIcon} width="20px" alt="icon" />,
   name: "Template Management",
   link: `${mainRoute}template`,
  },
  {
   icon: <img src={blockIcon} width="20px" alt="icon" />,
   name: "Filter Rule",
   link: `${mainRoute}filter-rule`,
  },
  {
   icon: <img src={parkingIcon} width="20px" alt="icon" />,
   name: "Parking Interaction",
   link: `${mainRoute}parking`,
  },
  {
   icon: <MdPeople size={size} color={color} />,
   name: "User",
   link: `${mainRoute}user`,
  },
  {
   icon: <MdFlashOn size={size} color={color} />,
   name: "Group skill",
   link: `${mainRoute}skill-group`,
  },
  {
   icon: <img src={skillIcon} width="20px" alt="icon" />,
   name: "Skill Setting",
   link: `${mainRoute}skill-setting`,
  },
  {
   icon: <MdPeople size={size} color={color} />,
   name: "Group",
   link: `${mainRoute}group`,
  },
  {
   icon: <img src={groupIcon} width="20px" alt="icon" />,
   name: "Group Detail",
   link: `${mainRoute}detail-group`,
  },
  {
   icon: <MdRecentActors size={size} color={color} />,
   name: "Unit",
   link: `${mainRoute}unit`,
  },
  {
   icon: <MdPanTool size={size} color={color} />,
   name: "Aux",
   link: `${mainRoute}aux`,
  },
  {
   icon: <MdFlag size={size} color={color} />,
   name: "Status",
   link: `${mainRoute}status`,
  },
  {
   icon: <MdAccessibility size={size} color={color} />,
   name: "Status Level",
   link: `${mainRoute}level-status`,
  },
  {
   icon: <MdKeyboardArrowRight size={size} color={color} />,
   name: "Status Next",
   link: `${mainRoute}next-status`,
  },
  {
   icon: <img src={whiteListIcon} width="20px" alt="icon" />,
   name: "White List",
   link: `${mainRoute}white-list`,
  },
  {
   icon: <img src={threshold} width="17px" alt="icon" />,
   name: "Threshold",
   link: `${mainRoute}threshold`,
  },
  {
   icon: <img src={custFieldIcon} width="20px" alt="icon" />,
   name: "Custome Fields",
   link: `${mainRoute}custome-fields`,
  },
  {
   icon: <MdTune size={size} color={color} />,
   name: "Custome Report",
   link: `${mainRoute}custome-report`,
  },
 ];
};

export const menuSidebarReportData = (mobile) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 const mainRoute = "/spv-reporting-";
 return [
  {
   icon: <MdHistory size={size} color={color} />,
   name: "Agent Log",
   link: `${mainRoute}agent-log`,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Detail KIP",
   link: `${mainRoute}detail-kip`,
  },
  // {
  //  icon: <MdEmail size={size} color={color} />,
  //  name: "Report Email",
  //  link: `${mainRoute}email`,
  // },
  {
   icon: <MdAssignment size={size} color={color} />,
   name: "Report Csat",
   link: `${mainRoute}csat`,
  },
  {
   icon: <MdAssignment size={size} color={color} />,
   name: "Report Detail",
   link: `${mainRoute}detail-2`,
  },
  // {
  //  icon: <MdEmail size={size} color={color} />,
  //  name: "Email in Queue",
  //  link: `${mainRoute}queue-email`,
  // },
  // {
  //  icon: <MdEmail size={size} color={color} />,
  //  name: "Email in Handling",
  //  link: `${mainRoute}handling-email`,
  // },
  // {
  //  icon: <MdEmail size={size} color={color} />,
  //  name: "Email History",
  //  link: `${mainRoute}history-email`,
  // },
  // {
  //  icon: <MdStars size={size} color={color} />,
  //  name: "Rating Report",
  //  link: `${mainRoute}rating`,
  // },
  {
   icon: <MdAssignment size={size} color={color} />,
   name: "KIP Report",
   link: `${mainRoute}kip`,
  },
  {
   icon: <MdTimeline size={size} color={color} />,
   name: "Traffic Report",
   link: `${mainRoute}traffic`,
  },
  {
   icon: <FaTicketAlt size={size} color={color} />,
   name: "Ticketing Report",
   link: `${mainRoute}ticketing`,
  },
  {
   icon: <img src={custRetentionIcon} width="20px" alt="icon" />,
   name: "Customer Retention",
   link: `${mainRoute}customer-retention`,
  },
  {
   icon: <MdCloud size={size} color={color} />,
   name: "Word Cloud",
   link: `${mainRoute}word-cloud`,
  },
  {
   icon: <img src={videoCallIcon} width="20px" alt="icon" />,
   name: "Video Record",
   link: `${mainRoute}video-record`,
  },
  {
   icon: <MdRecordVoiceOver size={size} color={color} />,
   name: "Sound Record",
   link: `${mainRoute}sound-record`,
  },
  {
   icon: <MdInput size={size} color={color} />,
   name: "Takeout",
   link: `${mainRoute}takeout`,
  },
 ];
};

export const menuSidebarChannelData = (mobile, props) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 return [
  {
   icon: <FiAtSign size={size} color={color} />,
   name: "Email",
   notif: 0,
   link: "/email",
   show: true,
   current: props
    ? props.spvEmailListCurrent
      ? props.spvEmailListCurrent
      : null
    : null,
   setCurrent: props ? props.setSpvEmailListCurrent : null,
   setMessages: props ? props.setEmailMessages : null,
   getMessages: props ? props.getEmailMessages : null,
   messages: props ? (props.emailMessages ? props.emailMessages : []) : [],
   sendMessage: channelService.sendMessageEmail,
  },
 ];
};

export const menuSidebarLogs = (mobile, props) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 return [
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Block Message",
   notif: 0,
   link: "/block-message",
   show: true,
  },
 ];
};

export const menuSidebarOldReport = (mobile, props) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 return [
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Agent Log",
   notif: 0,
   link: "/old-report-agent-log",
   show: true,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Agent Performance",
   notif: 0,
   link: "/old-report-agent-performance",
   show: true,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Operation",
   notif: 0,
   link: "/old-report-operation",
   show: true,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Summary Traffic",
   notif: 0,
   link: "/old-report-summmary-traffic",
   show: true,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Summary Interval",
   notif: 0,
   link: "/old-report-summary-interval",
   show: true,
  },
  {
   icon: <MdChatBubble size={size} color={color} />,
   name: "Summary KIP",
   notif: 0,
   link: "/old-report-summary-kip",
   show: true,
  },
 ];
};

export const menuSidebarMonitoringData = (mobile, props) => {
 const size = 18;
 const color = !mobile ? "#fff" : window.palette.primary.main;
 const mainRoute = "/spv-monitoring-";
 return [
  {
   icon: <IoIosPeople size={size} color={color} />,
   name: "Monitoring Agent Status",
   notif: 0,
   link: `${mainRoute}agent-status`,
   show: true,
  },
  {
   icon: <AiOutlineInteraction size={size} color={color} />,
   name: " Monitoring Agent Activity",
   notif: 0,
   link: `${mainRoute}agent-activity`,
   show: true,
  },
  {
   icon: <FaMap size={size} color={color} />,
   name: "Agent Map",
   notif: 0,
   link: `${mainRoute}agent-map`,
   show: true,
  },
 ];
};
