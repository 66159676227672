export const voiceLogReducer = (state = [], action) => {
 switch (action.type) {
  case "VOICE_LOG_GET":
   return state;
  case "VOICE_LOG_SET":
   return { ...state, voiceLog: action.voiceLog };
  default:
   return state;
 }
};

export const voiceLogCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "VOICE_LOG_CURRENT_GET":
   return state;
  case "VOICE_LOG_CURRENT_SET":
   return { ...state, voiceLog: action.current };
  default:
   return state;
 }
};

export const voiceLogLoadMoreReducer = (state = false, action) => {
 switch (action.type) {
  case "VOICE_LOG_LOAD_MORE_GET":
   return state;
  case "VOICE_LOG_LOAD_MORE_SET":
   return { ...state, voiceLog: action.hide };
  default:
   return state;
 }
};
