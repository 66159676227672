import React from "react";
import { connect } from "react-redux";
import helper from "utils/helper";
import {
 Flex,
 Image,
 Text,
 ButtonPrimary,
 GridContent,
} from "components/Layout";
import ModalAddOrEdit from "components/Modal/ModalAddOrEdit";
import ErrorLogs from "components/Form/ErrorLogs";
import Snap from "assets/images/SNAP.jpg";

class BlankSnap extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
   openModal: false,
  };
 }

 render() {
  return (
   <GridContent
    container
    justify="center"
    alignContent="center"
    style={{ height: "100%" }}
   >
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Image
      img={Snap}
      margin="auto"
      width="300px"
      minheight="150px"
      position="center"
      size="contain"
     />
    </GridContent>
    <GridContent item xs={12} sm={12} md={12} lg={12} xl={12}>
     <Flex direction="column" wrap="nowrap">
      <Text fs="17" color="#294354" bold textalign="left">
       Aw Snap!!!
      </Text>
      <Text fs="12" color="gray" textalign="left">
       Something wrong with our server, please try again
      </Text>
      <Flex wrap="nowrap">
       <Flex nowrap="nowrap" margin="10px 0 0 0">
        <ButtonPrimary
         variant="contained"
         onClick={() => {
          this.setState({
           openModal: true,
          });
         }}
         size="small"
         fs="12px"
        >
         Report problem
        </ButtonPrimary>
       </Flex>
       <Flex nowrap="nowrap" margin="10px 0 0 0">
        <ButtonPrimary
         bgcolor="transparent"
         onClick={() => window.location.reload(true)}
         size="small"
         fs="12px"
         color={window.palette.primary.main}
         bold="bold"
        >
         Refresh
        </ButtonPrimary>
       </Flex>
      </Flex>
     </Flex>
    </GridContent>
    <ModalAddOrEdit
     handleClose={() => {
      this.setState({ openModal: false });
     }}
     open={this.state.openModal}
     title="Report Problem"
    >
     <ErrorLogs
      handleClose={() => {
       this.setState({ openModal: false });
      }}
      data={this.props.data}
     />
    </ModalAddOrEdit>
   </GridContent>
  );
 }
}
export default connect(
 helper.mapStateToProps,
 helper.mapDispatchToProps
)(BlankSnap);
