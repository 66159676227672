import CryptoJS from "crypto-js";

var key = "$2y$10$cEaLwjcpQWsoQ45Kkl4qNOyHfChpuuNjl3ltYaL8Y5lR6G/T49ZfC";

const main = {
  ciphertext: () =>
    CryptoJS.AES.encrypt(JSON.stringify(window.TEST), key).toString(),
  decryptedData: () => {
    var bytes = CryptoJS.AES.decrypt(window.ENCRYPT, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  },
  encryptData() {
    const json = {
      SOCKET_VAXVOIP: "wss://103_90_251_48.web.vaxphone.com:8080",
      KEY_VAXVOIP: "60c9c121b14a1e7c2313a1b0c291c979",
      SOCKET_SERVER: "https://ws.cybros.id",
      API_URL: "https://api.cybros.id",
      MIDDLEWARE_URL: "https://middleware.cybros.id/",
      TICK_GREEN: 3,
      TICK_YELLOW: 3,
      TICKET: false,
      BUCKET: 5,
      IS_ATTACH: "[2,3,5,12]",
      FILE_SIZE: 25000000,
      FILE_SIZE_TEXT: "25 MB",
      AUTO_IN: 0,
      VERSION: "1.2.5",
      SOCKET_SERVER_ACTION: "https://ws.cybros.id/socket-emit",
      SOCKET_EVENT_ACTION: ["forcelogout"],
      INTEGRATION: false,
      FLEXMONSTER_KEY: "Z7YG-XD5I61-1Q5D2K-4Q0J2Q",
      VCALL_API: "https://vca.infomedia.co.id:3050",
      WEBPHONE_VER: "665",
      SPV_CHANNEL: 1,
      TOKEN_AVAYA_V2: "f7be754e24dfac3f17d080b69eeaa8936a214051",
      TOKEN_AVAYA_V3: "f7be754e24dfac3f17d080b69eeaa8936a214051",
      HOST_AVAYA_V2: "https://cti-dc01.infomedia.co.id:20443",
      HOST_AVAYA_V3: "https://cti-dc03.infomedia.co.id",
    };
    const enc = CryptoJS.AES.encrypt(JSON.stringify(json), key);
    return enc.toString();
  },
};

export default main;
