export const ticketStatusReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_STATUS_GET":
   return state;
  case "TICKET_STATUS_SET":
   return { ...state, status: action.status };
  default:
   return state;
 }
};

export const ticketHistoryReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_HISTORY_GET":
   return state;
  case "TICKET_HISTORY_SET":
   return { ...state, history: action.history };
  default:
   return state;
 }
};

export const ticketReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_GET":
   return state;
  case "TICKET_SET":
   return { ...state, ticket: action.ticket };
  default:
   return state;
 }
};

export const ticketUnitReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_UNIT_GET":
   return state;
  case "TICKET_UNIT_SET":
   return { ...state, unit: action.unit };
  default:
   return state;
 }
};

export const ticketCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "TICKET_CURRENT_GET":
   return state;
  case "TICKET_CURRENT_SET":
   return { ...state, current: action.current };
  default:
   return state;
 }
};

export const ticketStatusLevelReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_STATUS_LEVEL_GET":
   return state;
  case "TICKET_STATUS_LEVEL_SET":
   return { ...state, status: action.status };
  default:
   return state;
 }
};

export const ticketStatusNextReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_STATUS_NEXT_GET":
   return state;
  case "TICKET_STATUS_NEXT_SET":
   return { ...state, status: action.status };
  default:
   return state;
 }
};

export const ticketCaseOriginReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_CASE_ORIGIN_GET":
   return state;
  case "TICKET_CASE_ORIGIN_SET":
   return { ...state, status: action.status };
  default:
   return state;
 }
};

export const ticketServiceAreaReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_SERVICE_AREA_GET":
   return state;
  case "TICKET_SERVICE_AREA_SET":
   return { ...state, status: action.status };
  default:
   return state;
 }
};

export const ticketStageReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_STAGE_GET":
   return state;
  case "TICKET_STAGE_SET":
   return { ...state, stage: action.stage };
  default:
   return state;
 }
};

export const ticketAdditionalReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_ADDITIONAL_GET":
   return state;
  case "TICKET_ADDITIONAL_SET":
   return { ...state, additional: action.additional };
  default:
   return state;
 }
};

export const ticketAdditionalValueReducer = (state = [], action) => {
 switch (action.type) {
  case "TICKET_ADDITIONAL_VALUE_GET":
   return state;
  case "TICKET_ADDITIONAL_VALUE_SET":
   return { ...state, additional: action.additional };
  default:
   return state;
 }
};

export const ticketCountReducer = (state = null, action) => {
 switch (action.type) {
  case "TICKET_GET_COUNT":
   return state;
  case "TICKET_SET_COUNT":
   return { ...state, count: action.count };
  default:
   return state;
 }
};
