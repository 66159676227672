import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { MdInfo } from "react-icons/md";

function Alert(props) {
 return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
 root: {
  width: "100%",
  "& > * + *": {
   marginTop: theme.spacing(2),
  },
 },
 title: {
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: "0",
 },
}));

export default function CustomizedSnackbars() {
 const classes = useStyles();
 const [open, setOpen] = React.useState(localStorage.getItem("chromeAlert"));

 const handleClose = (event, reason) => {
  if (reason === "clickaway") {
   return;
  }

  setOpen(false);
  localStorage.removeItem("chromeAlert");
 };

 return (
  <div className={classes.root}>
   <Snackbar
    open={open}
    autoHideDuration={10000}
    onClose={handleClose}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
   >
    <Alert
     onClose={handleClose}
     severity="warning"
     icon={<MdInfo fontSize="1.7em" />}
    >
     <AlertTitle
      classes={{
       root: classes.title,
      }}
     >
      Unsupported browser
     </AlertTitle>
     <p style={{ fontWeight: 300, margin: 0 }}>
      please use chrome for best experience.{" "}
      <a href="https://www.google.com/chrome" style={{ color: "#fff" }}>
       <strong>download here</strong>
      </a>
     </p>
    </Alert>
   </Snackbar>
  </div>
 );
}
