/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import {
 REPORT_,
 GET_GROUP,
 GET_RECORDING,
 GET_SOUND_RECORDING,
 SET_REPORT,
 TAKE_OUT,
 SET_BLAST,
 RADION,
} from "../utils/api";
import http from "../utils/http";

export default {
 getAgentLog(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}agentLogs`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTakeOut(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: TAKE_OUT,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAgentLogStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}agentLogsStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAgentLogSummary(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}agentLogsSum`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getRating(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}rating`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getRatingStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}ratingStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getKip(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}summaryKIP`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getKipStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}summaryKIPStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractionHistory(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}interaction_history`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractionHistoryStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}interaction_history_stream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTraffic(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}summaryTraffic`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTrafficStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}summaryTrafficStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketing(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}ticketing`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketingstream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}ticketingStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractionHeader(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}interaction_header`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractionHeaderStrean(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}interaction_header_stream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getRetention(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}customerRetention`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getRetentionStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${REPORT_}customerRetentionStream`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getRecodingStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${GET_RECORDING}`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getSoundRecodingStream(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${GET_SOUND_RECORDING}`,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getGroupData(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${GET_GROUP}getData `,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateGroupData(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: `${GET_GROUP}updateGroupId`,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 setReportMenu(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: SET_REPORT,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getReportCustome({ body, endPoint }) {
  return new Promise((resolve, reject) => {
   const postData = body;
   http({
    ...{
     method: "POST",
     type: "search",
     url: endPoint,
     data: postData,
    },
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 setBlast(data) {
  return new Promise((resolve, reject) => {
   http({
    ...{
     method: "POST",
     data,
     url: SET_BLAST,
    },
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getDetailReport(data) {
  return new Promise((resolve, reject) => {
   http({
    ...{
     method: "POST",
     data,
     url: `${RADION}/getDetailReport`,
    },
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
};
