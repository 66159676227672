import crypto from "./crypto";

const API = crypto.decryptedData().API_URL;

/* eslint-disable no-underscore-dangle */
export const LOGIN_ = `${API}/v1/auth/login`;
export const LOGOUT_ = `${API}/v1/auth/logout`;
export const UPDATE_PASSWORD_ = `${API}/v1/auth/changePass`;
export const RESET_PASSWORD_ = `${API}/v1/auth/reset`;
export const PAUSE_AUTO_IN_ = `${API}/v1/auth/pause`;
export const AUX_ = `${API}/v1/auth/aux`;
export const RELEASE_AUX_ = `${API}/v1/auth/aux_release`;
export const VALIDATE_TENANT = `${API}/v1/auth/tenantValid`;
export const GET_CHANNEL_ = `${API}/v1/general/getChannel`;
export const HISTORY_INTERACTION_ = `${API}/v1/interaction/getInteraction`;
export const REGISTER_QUEUE_ = `${API}/v1/interaction/queue`;
export const GET_JOURNEY_ = `${API}/v1/interaction/getJourney`;
export const GET_PROFILE_ = `${API}/v1/general/getCustomer`;
export const GET_LOG_CWC = `${API}/v1/interaction/getcwc`;
export const GET_LOG_CWC_ALL = `${API}/v1/interaction/getCwcAll`;
export const GET_TICKET_HISTORY_ = `${API}/v1/ticketing/getTicketHistory`;
export const GET_TICKET_ = `${API}/v1/ticketing/getTicket`;
export const GET_TICKET_STATUS_ = `${API}/v1/general/getStatus`;
export const GET_TICKET_UNIT_ = `${API}/v1/general/getUnit`;
export const GET_CATEGORY_ = `${API}/v1/general/getCategory`;
export const GET_CASE_ORIGIN_ = `${API}/v1/general/getCaseOrigin`;
export const GET_SERVICE_AREA_ = `${API}/v1/general/getServiceArea`;
export const GET_TICKETING_STATUS_LEVEL_ = `${API}/v1/general/getStatusLevel`;
export const GET_TICKETING_STATUS_NEXT_ = `${API}/v1/general/getStatusNext`;
export const GET_TICKETING_HISTORY_ = `${API}/v1/ticketing/getTicketHistory`;
export const UPDATE_PROFILE_ = `${API}/v1/general/updateCustomer`;
export const POST_OUTGOING_TELEGRAM = `${API}/v1/outgoing/telegram`;
export const POST_OUTGOING_WHATSAPP = `${API}/v1/outgoing/whatsapp`;
export const POST_OUTGOING_MESSENGER = `${API}/v1/outgoing/messenger`;
export const POST_OUTGOING_INSTAGRAM = `${API}/v1/outgoing/instagram`;
export const POST_OUTGOING_TDM = `${API}/v1/outgoing/twitter_dm`;
export const POST_OUTGOING_FACEBOOK = `${API}/v1/outgoing/facebook`;
export const POST_OUTGOING_SMS = `${API}/v1/outgoing/sms`;
export const POST_OUTGOING_TWITTER = `${API}/v1/outgoing/twitter_mention`;
export const POST_OUTGOING_EMAIL = `${API}/v1/outgoing/email`;
export const POST_OUTGOING_CHAT = `${API}/v1/outgoing/chatbot`;
export const POST_OUTGOING_WEBCHAT = `${API}/v1/outgoing/webchat`;
export const POST_OUTGOING_LINE = `${API}/v1/outgoing/line`;
export const POST_OUTGOING_IG = `${API}/v1/outgoing/instagram_dm`;
export const END_INTERACTION_ = `${API}/v1/interaction/end`;
export const CWC_UPDATE_ = `${API}/v1/interaction/cwc`;
export const GET_CUSTOMER_ = `${API}/v1/general/getAllCustomer`;
export const MERGE_CUSTOMER_ = `${API}/v1/customer/merger`;
export const UPDATE_TICKET_ = `${API}/v1/ticketing/updateTicket`;
export const IFRAME_ = `${API}/v1/interaction/emailMessage`;
export const GET_STAGE_ = `${API}/v1/ticketing/getStageTicket`;
export const SUMMARY_CHANNEL_ = `${API}/v1/general/summaryChannelAgent`;
export const SUM_CWC_ = `${API}/v1/general/summaryCwc`;
export const GET_AUX_ = `${API}/v1/general/getAux`;
export const GET_SIGNATURE_ = `${API}/v1/interaction/getSignature`;
export const ERROR_REPORT_ = `${API}/v1/general/errorLogs`;
export const SPV_ = `${API}/v1/setting/`;
export const REPORT_ = `${API}/v1/report/`;
export const LOAD_IMAGE = `${
 crypto.decryptedData().MIDDLEWARE_URL
}/minio/download`;
export const DOWNLOAD_RECORDING = `${window.API_URL}/v1/interaction/downloadRecording`;
export const ESKALASI_GROUP = `${API}/v1/interaction/escalationInteraction`;
export const TEMPLATE_ = `${API}/v1/interaction/getTemplate`;
export const CREATE_CUSTOMER_ = `${API}/v1/customer/createCustomer`;
export const SEARCH_CUSTOMER_ = `${API}/v1/customer/SearchCustomer`;
export const GET_ACCOUNT_ = `${API}/v1/general/getAccount`;
export const BLOCKED_MSG = `${API}/v1/logsController/blockedMessage`;
export const IN_QUEUE = `${API}/v1/interaction/inQueue`;
export const WORD_CLOUD = `${API}/v1/dashboard/wordCloud`;
export const GET_WORD_CLOUD_ = `${API}/v1/dashboard/getWordCloud`;
export const ADD_WORD_CLOUD_ = `${API}/v1/dashboard/addWordCloud`;
export const DEL_WORD_CLOUD_ = `${API}/v1/dashboard/delWordCloud`;
export const GET_WORD_CLOUD_KIP = `${API}/v1/dashboard/wordCloudKIP`;
export const LOGIN_STATUS = `${API}/v1/auth/loginStatus`;
export const LOG_INTERACTION = `${API}/v1/interaction/log_interaction`;
export const DETAIL_INTERACTION = `${API}/v1/interaction/getInteraction`;
export const VOICE_AGENT = `${API}/v1/incoming/voiceByAgent`;
export const GET_CHANNEL_AGENT = `${API}/v1/general/getChannelAgent`;
export const GET_PRODUCT = `${API}/v1/general/getProduct`;
export const ADD_FIELD = `${API}/v1/setting/masterAdditionalField`;
export const ADD_OPT = `${API}/v1/setting/masterAdditionalOption`;
export const GET_GROUP = `${API}/v1/group/`;
export const GET_RECORDING = `${API}/v1/recording/videoStream`;
export const GET_SOUND_RECORDING = `${API}/v1/rd/recordingAmrt`;
export const SET_REPORT = `${API}/v1/setting/masterMenu`;
export const TAKE_OUT = `${API}/v1/report/takeOut`;
export const WORD_CLOUD_CHANNEL = `${API}/v1/analytics/text/wordcount/channel`;
export const WORD_CLOUD_INTERACTIONS = `${API}/v1/interaction/getInteractionMessage`;
export const SET_BLAST = `${API}/v1/group/blast`;
export const ADD_MANUAL_KIP = `${API}/v1/incoming/addManual`;
export const FORGOT_PASSWORD = `${API}/v1/auth/forgotPass`;
export const BPJS = `${API}/v1/bpjskes`;
export const GET_ADDITIONAL_ = `${API}/v1/interaction/getAdditionalField`;
export const GET_ADDITIONAL_VALUE_ = `${API}/v1/interaction/getAdditionalValue`;
export const WORD_CLOUD_CATEGORY = `${API}/v1/analytics/text/wordcount/category`;
export const GET_LOGIN_STATUS = `${API}/v1/setting/loginStatus`;
export const GET_MANUAL_KIP = `${API}/v1/interaction/getManualInteraction`;
export const GET_ALL_EMAIL_ = `${API}/v1/interaction/getInteractionAll`;
export const GET_USER_DETAIL_ = `${API}/v1/general/getUserDetail`;
export const DASHBOARD_MONITORING = `${API}/v1/rd/dashboard`;
export const TOKEN_VALID = `${API}/v1/auth/tokenValid`;
export const DASHBOARD_MONITORING_HISTORY = `${API}/v1/rd/incoming_history`;
export const DASHBOARD_MONITORING_HISTORY_TODAY = `${API}/v1/rd/incoming_history_today`;
export const RADION = `${API}/v1/rd`;
