/* eslint-disable react/prefer-stateless-function */
import React, { Suspense, lazy } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import helper from "utils/helper";
import SpvRoutes from "./spv";
import history from "utils/history";

const Login = lazy(() => import("../components/Form/Login"));
const Reset = lazy(() => import("../components/Form/Reset"));
const DashboardMonitoring = lazy(() =>
 import("../layouts/DashboardMonitoring")
);
// const DashboardAdminLayout = lazy(() =>
//  import("../layouts/DashboardAdminLayout")
// );
const FullLayout = lazy(() => import("../layouts/FullLayout"));
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const ChannelLayout = lazy(() => import("../layouts/ChannelLayout"));
const TicketingLayout = lazy(() => import("../layouts/TicketingLayout"));
const CustomerLayout = lazy(() => import("../layouts/CustomerLayout"));
const Aux = lazy(() => import("../components/Form/AuxForm"));
const SpvEmailLayout = lazy(() => import("../layouts/SpvEmailLayout"));
const LogCwcLayout = lazy(() => import("../layouts/LogCwcLayout"));
const VoiceLayout = lazy(() => import("../layouts/VoiceLayout"));
const BlankAccess = lazy(() => import("../components/Blank/BlankAccess"));
const BlankLanding = lazy(() => import("../components/Blank/BlankLanding"));
const ManualCwcLayout = lazy(() => import("../layouts/ManualCwcLayout"));
const LogFollowUp = lazy(() => import("../layouts/LogFollowUp"));
// const ClearBlank = lazy(() => import("../components/ClearBlank"));

const DashboardRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    helper.isAgent() ? (
     <MainLayout>
      <DashboardMonitoring />
     </MainLayout>
    ) : (
     <Redirect
      to={
       helper.isBackroom()
        ? {
           pathname: "/ticketing",
           state: { from: location },
          }
        : helper.isAux()
        ? {
           pathname: "/aux",
           state: { from: location },
          }
        : helper.isSupervisor()
        ? {
           pathname: "/supervisor",
           state: { from: location },
          }
        : {
           pathname: "/login",
           state: { from: location },
          }
      }
     />
    )
   }
  />
 );
};

const PublicRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    !helper.isAgent() &&
    !helper.isBackroom() &&
    !helper.isSupervisor() &&
    !helper.isAux() ? (
     <FullLayout type={rest.path}>{children}</FullLayout>
    ) : (
     <Redirect
      to={{
       pathname: "/dashboard",
       state: { from: location },
      }}
     />
    )
   }
  />
 );
};

const PrivateRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    helper.isAgent() || helper.isBackroom() || helper.isSupervisor() ? (
     <MainLayout>{children}</MainLayout>
    ) : (
     <Redirect
      to={{
       pathname: "/login",
       state: { from: location },
      }}
     />
    )
   }
  />
 );
};

const AuxRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    helper.isAux() ? (
     <FullLayout type={rest.path}>{children}</FullLayout>
    ) : (
     <Redirect
      to={{
       pathname: "/login",
       state: { from: location },
      }}
     />
    )
   }
  />
 );
};

const AgentRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    helper.isAgent() ? (
     <MainLayout>{children}</MainLayout>
    ) : (
     <Redirect
      to={{
       pathname: "/login",
       state: { from: location },
      }}
     />
    )
   }
  />
 );
};

const SupervisorRoute = ({ children, ...rest }) => {
 return (
  <Route
   {...rest}
   render={({ location }) =>
    helper.isSupervisor() ? (
     <MainLayout>{children}</MainLayout>
    ) : (
     <Redirect
      to={{
       pathname: "/login",
       state: { from: location },
      }}
     />
    )
   }
  />
 );
};

class Router extends React.Component {
 render() {
  const { statusAllEmail, channel, channelAgent } = this.props;
  const coreRoute = [
   "/sms",
   "/webchat",
   "/telegram",
   "/whatsapp",
   "/voice",
   "/messenger",
   "/dm",
   "/email",
   "/facebook",
   "/twitter",
   "/instagram",
   "/video-call",
   "/line",
   "/igdm",
  ];
  const current =
   helper.getCurrentChannel(this.props).length > 0
    ? helper.getCurrentChannel(this.props)[0]
    : null;
  const validateRoute = current
   ? channel
     ? channel.filter((v) => v.channel_name === current.key).length > 0
       ? channel.filter((v) => v.channel_name === current.key)[0].is_active ===
         "1"
         ? channelAgent
           ? channelAgent.filter((v) => v.field === current.field).length > 0
             ? channelAgent.filter((v) => v.field === current.field)[0]
                .value === "1"
               ? ["true"]
               : []
             : []
           : []
         : []
       : []
     : []
   : [];

  return (
   <Suspense fallback={<PageLoader />}>
    <Switch>
     <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
     <DashboardRoute path="/dashboard" />
     <Route
      exact
      path="/check-token/:id?"
      render={(props) =>
       props.match.params.id &&
       !helper.isAgent() &&
       !helper.isBackroom() &&
       !helper.isSupervisor() &&
       !helper.isAux() ? (
        <BlankLanding token={props.match.params.id} />
       ) : (
        <Redirect
         to={{
          pathname: "/dashboard",
          state: { from: props.location },
         }}
        />
       )
      }
     />
     <PublicRoute path="/login">
      <Login />
     </PublicRoute>
     <PublicRoute path="/reset-password">
      <Reset />
     </PublicRoute>
     <PrivateRoute path="/log-kip">
      <LogCwcLayout />
     </PrivateRoute>
     <PrivateRoute path="/log-follow-up">
      <LogFollowUp />
     </PrivateRoute>
     <PrivateRoute path="/customer">
      <CustomerLayout />
     </PrivateRoute>
     <PrivateRoute path="/ticketing">
      <TicketingLayout />
     </PrivateRoute>
     <AuxRoute path="/aux">
      <Aux />
     </AuxRoute>
     {coreRoute.map((v, i) =>
      helper.isSupervisor() && v === "/email" ? (
       <SupervisorRoute path="/email" key={i.toString()}>
        <SpvEmailLayout />
       </SupervisorRoute>
      ) : statusAllEmail && v === "/email" && helper.isAgent() ? (
       <AgentRoute path="/email" key={i.toString()}>
        <SpvEmailLayout />
       </AgentRoute>
      ) : helper.isAgent() ? (
       <AgentRoute path={v} key={i.toString()}>
        {validateRoute.length > 0 ? (
         v === "/voice" ? (
          <VoiceLayout phone={history.location.state} />
         ) : (
          <ChannelLayout />
         )
        ) : (
         <BlankAccess key={i.toString()} data={validateRoute} />
        )}
       </AgentRoute>
      ) : null
     )}
     <AgentRoute path="/manual-kip">
      <ManualCwcLayout />
     </AgentRoute>
     <SupervisorRoute path="/supervisor">
      <DashboardMonitoring />
     </SupervisorRoute>
     {SpvRoutes.map((val, key) => (
      <SupervisorRoute key={key} path={val.url}>
       {val.components()}
      </SupervisorRoute>
     ))}
    </Switch>
   </Suspense>
  );
 }
}

const PageLoader = () => (
 <div id="loader">
  <div className="loadingio-spinner-rolling-3kjtqyynjid">
   <div className="ldio-ucwibazh2i9">
    <div></div>
   </div>
  </div>
 </div>
);

export default connect(
 helper.mapStateToProps,
 helper.mapDispatchToProps
)(withRouter(Router));
