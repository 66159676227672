import React from "react";
import { Paper, Backdrop, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import helper from "../../utils/helper";
import {
 Flex,
 Image,
 ModalBody,
 ModalElement,
 Text,
 ButtonPrimary,
} from "../Layout";
import Logout from "../../assets/images/logout.png";

class ModalLogout extends React.Component {
 render() {
  const { open, handleClose, message } = this.props;
  return (
   <ModalElement
    disableBackdropClick
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
    disablePortal
    disableEnforceFocus
    disableAutoFocus
   >
    <Fade in={open}>
     <Paper style={{ width: 250 }}>
      <ModalBody>
       <Flex nowrap="nowrap" justify="center" direction="column">
        <Image img={Logout} minheight="170px" width="100%" size="contain" />
        <Text fs="12" color={window.palette.primary.dark} bold>
         {message || "Are you sure want to logout?"}
        </Text>
        <Flex nowrap="nowrap" margin="10px 0 0 0">
         {!message ? (
          <ButtonPrimary
           bgcolor="red"
           size="small"
           margin="0 10px 0 0"
           onClick={handleClose}
          >
           No!
          </ButtonPrimary>
         ) : null}
         <ButtonPrimary
          bgcolor={window.palette.primary.button}
          size="small"
          margin="0 10px 0 0"
          onClick={() => {
           this.props.getAuth({
            tenant_id: window.TENANT_ID,
            userid: this.props.auth.user.userid,
            type: "LOGOUT",
           });
          }}
         >
          {message ? "Logout" : "Yes"}
         </ButtonPrimary>
        </Flex>
       </Flex>
      </ModalBody>
     </Paper>
    </Fade>
   </ModalElement>
  );
 }
}
ModalLogout.propTypes = {
 open: PropTypes.any,
 handleClose: PropTypes.any,
};
export default connect(
 helper.mapStateToProps,
 helper.mapDispatchToProps
)(ModalLogout);
