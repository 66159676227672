export const teleGetCurrent = (data) => ({
  type: "TELE_GET_CURRENT",
  data,
});

export const teleSetCurrent = (current) => ({
  type: "TELE_SET_CURRENT",
  current,
});

export const teleGetMessages = (data) => ({
  type: "TELE_GET_MESSAGES",
  data,
});

export const teleSetMessages = (messages) => ({
  type: "TELE_SET_MESSAGES",
  messages,
});

export const whatsappGetCurrent = (data) => ({
  type: "WHATSAPP_GET_CURRENT",
  data,
});

export const whatsappSetCurrent = (current) => ({
  type: "WHATSAPP_SET_CURRENT",
  current,
});

export const whatsappGetMessages = (data) => ({
  type: "WHATSAPP_GET_MESSAGES",
  data,
});

export const whatsappSetMessages = (messages) => ({
  type: "WHATSAPP_SET_MESSAGES",
  messages,
});

export const messengerGetCurrent = (data) => ({
  type: "MESSENGER_GET_CURRENT",
  data,
});

export const messengerSetCurrent = (current) => ({
  type: "MESSENGER_SET_CURRENT",
  current,
});

export const messengerGetMessages = (data) => ({
  type: "MESSENGER_GET_MESSAGES",
  data,
});

export const messengerSetMessages = (messages) => ({
  type: "MESSENGER_SET_MESSAGES",
  messages,
});

export const smsGetCurrent = (data) => ({
  type: "SMS_GET_CURRENT",
  data,
});

export const smsSetCurrent = (current) => ({
  type: "SMS_SET_CURRENT",
  current,
});

export const smsGetMessages = (data) => ({
  type: "SMS_GET_MESSAGES",
  data,
});

export const smsSetMessages = (messages) => ({
  type: "SMS_SET_MESSAGES",
  messages,
});

export const tdmGetCurrent = (data) => ({
  type: "TDM_GET_CURRENT",
  data,
});

export const tdmSetCurrent = (current) => ({
  type: "TDM_SET_CURRENT",
  current,
});

export const tdmGetMessages = (data) => ({
  type: "TDM_GET_MESSAGES",
  data,
});

export const tdmSetMessages = (messages) => ({
  type: "TDM_SET_MESSAGES",
  messages,
});

export const webchatGetCurrent = (data) => ({
  type: "WEBCHAT_GET_CURRENT",
  data,
});

export const webchatSetCurrent = (current) => ({
  type: "WEBCHAT_SET_CURRENT",
  current,
});

export const webchatGetMessages = (data) => ({
  type: "WEBCHAT_GET_MESSAGES",
  data,
});

export const webchatSetMessages = (messages) => ({
  type: "WEBCHAT_SET_MESSAGES",
  messages,
});

export const emailGetMessages = (data) => ({
  type: "EMAIL_GET_MESSAGES",
  data,
});

export const emailSetMessages = (messages) => ({
  type: "EMAIL_SET_MESSAGES",
  messages,
});

export const emailGetCurrent = (data) => ({
  type: "EMAIL_GET_CURRENT",
  data,
});

export const emailSetCurrent = (current) => ({
  type: "EMAIL_SET_CURRENT",
  current,
});

export const facebookGetMessages = (data) => ({
  type: "FACEBOOK_GET_MESSAGES",
  data,
});

export const facebookSetMessages = (messages) => ({
  type: "FACEBOOK_SET_MESSAGES",
  messages,
});

export const facebookGetCurrent = (data) => ({
  type: "FACEBOOK_GET_CURRENT",
  data,
});

export const facebookSetCurrent = (current) => ({
  type: "FACEBOOK_SET_CURRENT",
  current,
});

export const twitterGetMessages = (data) => ({
  type: "TWITTER_GET_MESSAGES",
  data,
});

export const twitterSetMessages = (messages) => ({
  type: "TWITTER_SET_MESSAGES",
  messages,
});

export const twitterGetCurrent = (data) => ({
  type: "TWITTER_GET_CURRENT",
  data,
});

export const twitterSetCurrent = (current) => ({
  type: "TWITTER_SET_CURRENT",
  current,
});

export const instagramGetMessages = (data) => ({
  type: "INSTAGRAM_GET_MESSAGES",
  data,
});

export const instagramSetMessages = (messages) => ({
  type: "INSTAGRAM_SET_MESSAGES",
  messages,
});

export const instagramGetCurrent = (data) => ({
  type: "INSTAGRAM_GET_CURRENT",
  data,
});

export const instagramSetCurrent = (current) => ({
  type: "INSTAGRAM_SET_CURRENT",
  current,
});

export const vidcallGetMessages = (data) => ({
  type: "VIDCALL_GET_MESSAGES",
  data,
});

export const vidcallSetMessages = (messages) => ({
  type: "VIDCALL_SET_MESSAGES",
  messages,
});

export const vidcallGetCurrent = (data) => ({
  type: "VIDCALL_GET_CURRENT",
  data,
});

export const vidcallSetCurrent = (current) => ({
  type: "VIDCALL_SET_CURRENT",
  current,
});

export const voiceGetMessages = (data) => ({
  type: "VOICE_GET_MESSAGES",
  data,
});

export const voiceSetMessages = (messages) => ({
  type: "VOICE_SET_MESSAGES",
  messages,
});

export const voiceGetCurrent = (data) => ({
  type: "VOICE_GET_CURRENT",
  data,
});

export const voiceSetCurrent = (current) => ({
  type: "VOICE_SET_CURRENT",
  current,
});

export const lineGetMessages = (data) => ({
  type: "LINE_GET_MESSAGES",
  data,
});

export const lineSetMessages = (messages) => ({
  type: "LINE_SET_MESSAGES",
  messages,
});

export const lineGetCurrent = (data) => ({
  type: "LINE_GET_CURRENT",
  data,
});

export const lineSetCurrent = (current) => ({
  type: "LINE_SET_CURRENT",
  current,
});

export const igGetMessages = (data) => ({
  type: "IG_GET_MESSAGES",
  data,
});

export const igSetMessages = (messages) => ({
  type: "IG_SET_MESSAGES",
  messages,
});

export const igGetCurrent = (data) => ({
  type: "IG_GET_CURRENT",
  data,
});

export const igSetCurrent = (current) => ({
  type: "IG_SET_CURRENT",
  current,
});
