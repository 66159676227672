import helper from "../../utils/helper";
let accessToken = "";
let user = null;
let version = null;
let loginStatus = null;
let plugin = null;
if (localStorage.getItem(window.TENANT_ID)) {
 const dirtyStorage = JSON.parse(
  helper.decrypt(localStorage.getItem(window.TENANT_ID))
 );
 accessToken = dirtyStorage.accessToken;
 user = dirtyStorage.user;
 loginStatus = dirtyStorage.loginStatus;
 version = dirtyStorage.version;
 plugin = dirtyStorage.plugin;
}
const initialState = {
 accessToken,
 user,
 version,
 loginStatus,
 plugin,
};
export const generalLoadingReducer = (state = false, action) => {
 switch (action.type) {
  case "LOADING_GET":
   return state;
  case "LOADING_SET":
   return {
    ...state,
    loading: action.loading,
   };
  default:
   return state;
 }
};
export const generalAuthRequestReducer = (state = {}, action) => {
 switch (action.type) {
  case "AUTH_REQUEST_GET":
   return state;
  case "AUTH_REQUEST_SET":
   return {
    ...state,
    authRequest: action.authRequest,
   };
  default:
   return state;
 }
};
export const generalAuthReducer = (
 state = {
  auth: initialState,
 },
 action
) => {
 switch (action.type) {
  case "AUTH_GET":
   return state;
  case "AUTH_SET":
   return {
    ...state,
    auth: action.auth,
   };
  default:
   return state;
 }
};

export const generalChannelReducer = (state = [], action) => {
 switch (action.type) {
  case "CHANNEL_GET":
   return state;
  case "CHANNEL_SET":
   return {
    ...state,
    channel: action.channel,
   };
  default:
   return state;
 }
};

export const generalChannelAgentReducer = (state = [], action) => {
 switch (action.type) {
  case "CHANNEL_AGENT_GET":
   return state;
  case "CHANNEL_AGENT_SET":
   return {
    ...state,
    channel: action.channel,
   };
  default:
   return state;
 }
};

export const generalNotificationReducer = (state = [], action) => {
 switch (action.type) {
  case "NOTIFICATION_GET":
   return state;
  case "NOTIFICATION_SET":
   return {
    ...state,
    notification: action.notification,
   };
  default:
   return state;
 }
};

export const generalRouteReducer = (state = [], action) => {
 switch (action.type) {
  case "ROUTE_GET":
   return state;
  case "ROUTE_SET":
   return {
    ...state,
    route: action.route,
   };
  default:
   return state;
 }
};

export const generalJourneyReducer = (state = [], action) => {
 switch (action.type) {
  case "JOURNEY_GET":
   return state;
  case "JOURNEY_SET":
   return {
    ...state,
    journey: action.journey,
   };
  default:
   return state;
 }
};

export const generalProfileReducer = (state = [], action) => {
 switch (action.type) {
  case "PROFILE_GET":
   return state;
  case "PROFILE_SET":
   return {
    ...state,
    profile: action.profile,
   };
  default:
   return state;
 }
};

export const generalInteractionReducer = (state = [], action) => {
 switch (action.type) {
  case "INTERACTION_GET":
   return state;
  case "INTERACTION_SET":
   return {
    ...state,
    interaction: action.interaction,
   };
  default:
   return state;
 }
};

export const generalTabInteractionReducer = (state = [], action) => {
 switch (action.type) {
  case "TAB_INTERACTION_GET":
   return state;
  case "TAB_INTERACTION_SET":
   return {
    ...state,
    value: action.value,
   };
  default:
   return state;
 }
};

export const generalTabInfoReducer = (state = [], action) => {
 switch (action.type) {
  case "TAB_INFO_GET":
   return state;
  case "TAB_INFO_SET":
   return {
    ...state,
    value: action.value,
   };
  default:
   return state;
 }
};

export const generalIncomingReducer = (state = [], action) => {
 switch (action.type) {
  case "INCOMING_GET":
   return state;
  case "INCOMING_SET":
   return {
    ...state,
    incoming: action.incoming,
   };
  default:
   return state;
 }
};

export const generalTextReducer = (state = [], action) => {
 switch (action.type) {
  case "TEXT_GET":
   return state;
  case "TEXT_SET":
   return {
    ...state,
    text: action.text,
   };
  default:
   return state;
 }
};

export const generalTextCwcReducer = (state = [], action) => {
 switch (action.type) {
  case "TEXT_CWC_GET":
   return state;
  case "TEXT_CWC_SET":
   return {
    ...state,
    text: action.text,
   };
  default:
   return state;
 }
};

export const generalCategoryReducer = (state = [], action) => {
 switch (action.type) {
  case "CATEGORY_GET":
   return state;
  case "CATEGORY_SET":
   return {
    ...state,
    category: action.category,
   };
  default:
   return state;
 }
};

export const generalCwcIdReducer = (state = null, action) => {
 switch (action.type) {
  case "CWC_ID_GET":
   return state;
  case "CWC_ID_SET":
   return {
    ...state,
    id: action.id,
   };
  default:
   return state;
 }
};

export const generalEndDateReducer = (state = null, action) => {
 switch (action.type) {
  case "END_DATE_GET":
   return state;
  case "END_DATE_SET":
   return {
    ...state,
    date: action.date,
   };
  default:
   return state;
 }
};

export const generalDisabledReducer = (state = false, action) => {
 switch (action.type) {
  case "DISABLED_GET":
   return state;
  case "DISABLED_SET":
   return {
    ...state,
    disabled: action.disabled,
   };
  default:
   return state;
 }
};

export const generalHideLoadMoreTicketReducer = (state = false, action) => {
 switch (action.type) {
  case "HIDE_LOAD_MORE_TICKET_GET":
   return state;
  case "HIDE_LOAD_MORE_TICKET_SET":
   return {
    ...state,
    hide: action.hide,
   };
  default:
   return state;
 }
};

export const generalHideLoadMoreTicketEmailSpv = (state = false, action) => {
 switch (action.type) {
  case "HIDE_LOAD_MORE_EMAIL_SPV_GET":
   return state;
  case "HIDE_LOAD_MORE_EMAIL_SPV_SET":
   return {
    ...state,
    hide: action.hide,
   };
  default:
   return state;
 }
};

export const generalSumCwcReducer = (state = [], action) => {
 switch (action.type) {
  case "SUM_CWC_GET":
   return state;
  case "SUM_CWC_SET":
   return {
    ...state,
    cwc: action.cwc,
   };
  default:
   return state;
 }
};

export const generalStatusAllEmailReducer = (state = false, action) => {
 switch (action.type) {
  case "STATUS_ALL_EMAIL_GET":
   return state;
  case "STATUS_ALL_EMAIL_SET":
   return {
    ...state,
    status: action.status,
   };
  default:
   return state;
 }
};

export const generalTemplateReducer = (state = [], action) => {
 switch (action.type) {
  case "TEMPLATE_GET":
   return state;
  case "TEMPLATE_SET":
   return {
    ...state,
    data: action.data,
   };
  default:
   return state;
 }
};

export const generalValidVersionReducer = (state = true, action) => {
 switch (action.type) {
  case "VALID_VERSION_GET":
   return state;
  case "VALID_VERSION_SET":
   return action.valid;
  default:
   return state;
 }
};

export const generalValidLogoutReducer = (state = false, action) => {
 switch (action.type) {
  case "VALID_LOGOUT_GET":
   return state;
  case "VALID_LOGOUT_SET":
   return action.valid;
  default:
   return state;
 }
};

export const generalLogCwcReducer = (state = [], action) => {
 switch (action.type) {
  case "LOG_CWC_GET":
   return state;
  case "LOG_CWC_SET":
   return {
    ...state,
    logCwc: action.logCwc,
   };
  default:
   return state;
 }
};

export const generalLogCwcCurrentReducer = (state = false, action) => {
 switch (action.type) {
  case "LOG_CWC_CURRENT_GET":
   return state;
  case "LOG_CWC_CURRENT_SET":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const generalHideLoadMoreLogCwc = (state = false, action) => {
 switch (action.type) {
  case "HIDE_LOAD_MORE_LOG_CWC_GET":
   return state;
  case "HIDE_LOAD_MORE_LOG_CWC_SET":
   return {
    ...state,
    hide: action.hide,
   };
  default:
   return state;
 }
};

export const generalLogInteractionReducer = (state = [], action) => {
 switch (action.type) {
  case "LOG_INTERACTION_GET":
   return state;
  case "LOG_INTERACTION_SET":
   return {
    ...state,
    interaction: action.interaction,
   };
  default:
   return state;
 }
};

export const generalCallReducer = (state = [], action) => {
 switch (action.type) {
  case "CALL_GET":
   return state;
  case "CALL_SET":
   return {
    ...state,
    call: action.call,
   };
  default:
   return state;
 }
};

export const generalStatusCallReducer = (state = "", action) => {
 switch (action.type) {
  case "CALL_STATUS_GET":
   return state;
  case "CALL_STATUS_SET":
   return {
    ...state,
    status: action.status,
   };
  default:
   return state;
 }
};

export const generalStatusCallRegisterReducer = (state = "", action) => {
 switch (action.type) {
  case "CALL_STATUS_REGISTER_GET":
   return state;
  case "CALL_STATUS_REGISTER_SET":
   return {
    ...state,
    status: action.status,
   };
  default:
   return state;
 }
};

export const generalDurationCallReducer = (state = "", action) => {
 switch (action.type) {
  case "DURATION_CALL_GET":
   return state;
  case "DURATION_CALL_SET":
   return {
    ...state,
    duration: action.duration,
   };
  default:
   return state;
 }
};

export const generalIsCallReducer = (state = false, action) => {
 switch (action.type) {
  case "IS_CALL_GET":
   return state;
  case "IS_CALL_SET":
   return {
    ...state,
    call: action.call,
   };
  default:
   return state;
 }
};

export const generalAddFormReducer = (state = [], action) => {
 switch (action.type) {
  case "ADD_FORM_GET":
   return state;
  case "ADD_FORM_SET":
   return {
    ...state,
    form: action.form,
   };
  default:
   return state;
 }
};

export const generalAddFormOptReducer = (state = [], action) => {
 switch (action.type) {
  case "ADD_FORM_OPT_GET":
   return state;
  case "ADD_FORM_OPT_SET":
   return {
    ...state,
    form: action.form,
   };
  default:
   return state;
 }
};

export const generalArchiveIdReducer = (state = null, action) => {
 switch (action.type) {
  case "ARCHIVE_ID_GET":
   return state;
  case "ARCHIVE_ID_SET":
   return {
    ...state,
    id: action.id,
   };
  default:
   return state;
 }
};

export const generalHideTabInteractionReducer = (state = false, action) => {
 switch (action.type) {
  case "HIDE_TAB_INTERACTION_GET":
   return state;
  case "HIDE_TAB_INTERACTION_SET":
   return {
    ...state,
    hide: action.hide,
   };
  default:
   return state;
 }
};

export const generalActiveManualKipReducer = (state = null, action) => {
 switch (action.type) {
  case "ACTIVE_MANUAL_KIP_GET":
   return state;
  case "ACTIVE_MANUAL_KIP_SET":
   return {
    ...state,
    active: action.active,
   };
  default:
   return state;
 }
};

export const generalOutCallReducer = (state = false, action) => {
 switch (action.type) {
  case "OUT_CALL_GET":
   return state;
  case "OUT_CALL_SET":
   return {
    ...state,
    active: action.active,
   };
  default:
   return state;
 }
};

export const generalManualKipReducer = (state = [], action) => {
 switch (action.type) {
  case "MANUAL_KIP_GET":
   return state;
  case "MANUAL_KIP_SET":
   return {
    ...state,
    manual: action.manual,
   };
  default:
   return state;
 }
};
export const generalMergeCwcReducer = (state = false, action) => {
 switch (action.type) {
  case "MERGE_CWC_GET":
   return state;
  case "MERGE_CWC_SET":
   return {
    ...state,
    mergeCwc: action.mergeCwc,
   };
  default:
   return state;
 }
};
export const setOpenIncomingReducer = (state = false, action) => {
 switch (action.type) {
  case "OPEN_INCOMING_SET":
   return action.open;
  default:
   return state;
 }
};

export const setFirstReducer = (state = null, action) => {
 switch (action.type) {
  case "FIRST_SET":
   return action.first;
  default:
   return state;
 }
};

export const setSecondReducer = (state = null, action) => {
 switch (action.type) {
  case "SECOND_SET":
   return action.second;
  default:
   return state;
 }
};

export const setEventReducer = (state = null, action) => {
 switch (action.type) {
  case "EVENT_SET":
   return action.event;
  default:
   return state;
 }
};
