export const customerReducer = (state = [], action)=>{
  switch(action.type){
    case 'CUSTOMER_GET':
        return state;
    case 'CUSTOMER_SET':
        return { ...state, customer: action.customer };
    default:
        return state;
  }
}

export const customerCurrentReducer = (state = null, action)=>{
  switch(action.type){
    case 'CUSTOMER_CURRENT_GET':
        return state;
    case 'CUSTOMER_CURRENT_SET':
        return { ...state, current: action.current };
    default:
        return state;
  }
}

export const customerLoadMoreReducer = (state = false, action)=>{
  switch(action.type){
    case 'CUSTOMER_LOAD_MORE_GET':
        return state;
    case 'CUSTOMER_LOAD_MORE_SET':
        return { ...state, hide: action.hide };
    default:
        return state;
  }
}