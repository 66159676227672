import React from "react";

export const AuxTime = React.lazy(() => import("./Table/AuxTime"));
export const Category = React.lazy(() => import("./Table/Category"));
export const CustomeFields = React.lazy(() => import("./Table/CustomeFields"));
export const CustomeReport = React.lazy(() => import("./Table/CustomeReport"));
export const FilterRule = React.lazy(() => import("./Table/FilterRule"));
export const Group = React.lazy(() => import("./Table/Group"));
export const GroupDetail = React.lazy(() => import("./Table/GroupDetail"));
export const GroupSkill = React.lazy(() => import("./Table/GroupSkill"));
export const Information = React.lazy(() => import("./Table/Information"));
export const SkillSetting = React.lazy(() => import("./Table/SkillSetting"));
export const Status = React.lazy(() => import("./Table/Status"));
export const StatusLevel = React.lazy(() => import("./Table/StatusLevel"));
export const StatusNext = React.lazy(() => import("./Table/StatusNext"));
export const SubCategory = React.lazy(() => import("./Table/SubCategory"));
export const Template = React.lazy(() => import("./Table/Template"));
export const Threshold = React.lazy(() => import("./Table/Threshold"));
export const Unit = React.lazy(() => import("./Table/Unit"));
export const WhiteList = React.lazy(() => import("./Table/WhiteList"));
export const User = React.lazy(() => import("./Table/User"));
export const Parking = React.lazy(() => import("./Table/Parking"));
