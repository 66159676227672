export const SpvEmailListReducer = (state = [], action)=>{
  switch(action.type){
    case 'SPV_EMAIL_LIST_GET':
        return state;
    case 'SPV_EMAIL_LIST_SET':
        return { ...state, list: action.list };
    default:
        return state;
  }
}

export const SpvEmailListCurrentReducer = (state = null, action)=>{
  switch(action.type){
    case 'SPV_EMAIL_LIST_CURRENT_GET':
        return state;
    case 'SPV_EMAIL_LIST_CURRENT_SET':
        return { ...state, current: action.current };
    default:
        return state;
  }
}