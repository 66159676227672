/* eslint-disable no-else-return */
/* eslint-disable require-yield */
/* eslint-disable no-lonely-if */
import { put, call, take, all, getContext } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import * as _ from "lodash";
import authServices from "../../services/authService";
import generalServices from "../../services/generalService";
import * as actions from "../actions";
import history from "../../utils/history";
import helper from "../../utils/helper";
import store from "../index";
import socket from "../../utils/socket";
import audioSuccess from "../../assets/audio/SUCCESS.mp3";
import gmeet from "../../assets/audio/GMEET.ogg";
import jarvis from "../../assets/audio/JARVIS.ogg";
import ticketService from "services/ticketService";
import { EventEmitter } from "events";
import crypto from "utils/crypto";
const storage = localStorage.getItem(window.TENANT_ID);
let userLevel = null;
let intervalMizzu = null;
let interval = null;
let intervalPopup = null;
let eventEmitter = new EventEmitter();
window.eventEmitter = eventEmitter;
// let client = new WebSocket(window.SOCKET_VAXVOIP);
if (storage) {
  const dirtyStorage = JSON.parse(helper.decrypt(storage));
  const { user } = dirtyStorage;
  userLevel = user.userlevel;
}
const audio = new Audio(jarvis);
// const audio2 = new Audio(gmeet);
export function* authWatcher(action) {
  try {
    if (action.data.type === "LOGIN") {
      const data = yield call(authServices.login, action.data);
      const storage = {
        accessToken: data.accessToken,
        user: data.data.Record[0],
        version: data.version,
        loginStatus: data.data.login_status[0],
        plugin: data.data.plugin,
      };
      localStorage.removeItem(window.TENANT_ID);
      localStorage.setItem(
        window.TENANT_ID,
        helper.encrypt(JSON.stringify(storage))
      );
      yield put(actions.generalSetAuth(storage));
      window.location.reload(true);
    } else if (action.data.type === "token") {
      const tokenValid = yield call(authServices.checkToken, action.data);
      if (tokenValid.message) {
        const data = yield call(authServices.login, {
          ip: tokenValid.message.ip,
          password: tokenValid.message.password,
          tenant_id: tokenValid.message.tenant_id,
          type: "LOGIN",
          userid: tokenValid.message.userid,
        });
        const storage = {
          accessToken: data.accessToken,
          user: data.data.Record[0],
          version: data.version,
          loginStatus: data.data.login_status[0],
          plugin: data.data.plugin,
        };
        localStorage.removeItem(window.TENANT_ID);
        localStorage.setItem(
          window.TENANT_ID,
          helper.encrypt(JSON.stringify(storage))
        );
        yield put(actions.generalSetAuth(storage));
        window.location.reload(true);
      }
      // const storage = {
      //  accessToken: data.accessToken,
      //  user: data.data.Record[0],
      //  version: data.version,
      //  loginStatus: data.data.login_status[0],
      //  plugin: data.data.plugin,
      // };
      // localStorage.removeItem(window.TENANT_ID);
      // localStorage.setItem(
      //  window.TENANT_ID,
      //  helper.encrypt(JSON.stringify(storage))
      // );
      // yield put(actions.generalSetAuth(storage));
      // window.location.reload(true);
    } else if (action.data.type === "AUX") {
      yield call(authServices.updateAux, action.data.data);
      const dirtyStorage = JSON.parse(
        helper.decrypt(localStorage.getItem(window.TENANT_ID))
      );
      const newDate = new Date();
      const YYYY = newDate.getFullYear();
      let DD = newDate.getMonth() + 1;
      let MM = newDate.getDate();
      const H = newDate.getHours();
      const M = newDate.getMinutes();
      const I = newDate.getSeconds();
      if (DD < 10) {
        DD = `0${DD}`;
      }
      if (MM < 10) {
        MM = `0${MM}`;
      }
      const lup = `${YYYY}-${DD}-${MM} ${H}:${M}:${I}`;

      dirtyStorage.user.is_login = 2;
      dirtyStorage.user.reason = action.data.data.reason;
      dirtyStorage.user.lup = lup;
      localStorage.removeItem(window.TENANT_ID);
      localStorage.setItem(
        window.TENANT_ID,
        helper.encrypt(JSON.stringify(dirtyStorage))
      );
      yield put(actions.generalSetAuth(dirtyStorage));
      history.push("/aux");
      window.location.reload(true);
    } else if (action.data.type === "RELEASE_AUX") {
      yield call(authServices.updateAuxRelease, action.data.data);
      const dirtyStorage = JSON.parse(
        helper.decrypt(localStorage.getItem(window.TENANT_ID))
      );
      const newDate = new Date();
      const YYYY = newDate.getFullYear();
      let DD = newDate.getMonth() + 1;
      let MM = newDate.getDate();
      const H = newDate.getHours();
      const M = newDate.getMinutes();
      const I = newDate.getSeconds();
      if (DD < 10) {
        DD = `0${DD}`;
      }
      if (MM < 10) {
        MM = `0${MM}`;
      }
      const lup = `${YYYY}-${DD}-${MM} ${H}:${M}:${I}`;

      dirtyStorage.user.is_login = 1;
      dirtyStorage.user.reason = "release";
      dirtyStorage.user.lup = lup;
      localStorage.removeItem(window.TENANT_ID);
      localStorage.setItem(
        window.TENANT_ID,
        helper.encrypt(JSON.stringify(dirtyStorage))
      );
      yield put(actions.generalSetAuth(dirtyStorage));
      window.location.reload(true);
    } else if (action.data.type === "LOGIN_STATUS") {
      yield call(generalServices.updateLoginStatus, action.data);
      const dirtyStorage = JSON.parse(
        helper.decrypt(localStorage.getItem(window.TENANT_ID))
      );
      dirtyStorage.loginStatus[action.data.is_ready] =
        action.data.is_ready_value;
      const storage = {
        accessToken: dirtyStorage.accessToken,
        user: dirtyStorage.user,
        version: dirtyStorage.version,
        loginStatus: dirtyStorage.loginStatus,
        plugin: dirtyStorage.plugin,
      };
      localStorage.removeItem(window.TENANT_ID);
      localStorage.setItem(
        window.TENANT_ID,
        helper.encrypt(JSON.stringify(storage))
      );
      yield put(actions.generalSetAuth(storage));
    } else {
      yield call(authServices.logout, action.data);
      localStorage.removeItem(window.TENANT_ID);
      localStorage.removeItem("tenant_id");
      localStorage.removeItem(`${window.TENANT_ID}-forcelogout`);
      yield put(actions.generalSetAuth({ accessToken: null, user: null }));
      history.push("/login");
      window.location.reload(true);
    }
  } catch (err) {
    // console.log('err : ', err);
    if (localStorage.getItem(window.TENANT_ID)) {
      localStorage.removeItem(window.TENANT_ID);
      localStorage.removeItem("tenant_id");
      localStorage.removeItem(`${window.TENANT_ID}-forcelogout`);
      history.push("/login");
      window.location.reload(true);
    }
  }
}

export function* channelWatcher(action) {
  try {
    const data = yield call(generalServices.getChannel, action.data);
    yield put(actions.generalSetChannel(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* channelAgentWatcher(action) {
  try {
    const data = yield call(generalServices.getChannelAgent, action.data);
    yield put(actions.generalSetChannelAgent(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* categoryWatcher(action) {
  try {
    const data = yield call(generalServices.getCategory, action.data);
    yield put(actions.generalSetCategory(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* journeyWatcher(action) {
  try {
    const data = yield call(generalServices.getJourney, action.data);
    yield put(actions.generalSetJourney(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* profileWatcher(action) {
  try {
    const data = yield call(generalServices.getProfile, action.data);
    yield put(actions.generalSetProfile(data.Record));
  } catch (error) {
    console.log(error);
  }
}

function* countNotificationEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.generalSetNotification(data));
    };
    socket.getCountNotificationAllChannel(socketEvent);
    return () => {};
  });
}

export function* countNotificationWatcher() {
  try {
    if (userLevel === "Agent") {
      const channel = yield call(countNotificationEventChannel);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* notificationEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const state = store.getState();
      const isNewInteractions =
        typeof state?.interaction?.interaction !== "undefined" &&
        state?.interaction?.interaction?.length !== 0 &&
        Array.isArray(state?.interaction?.interaction);
      let result = isNewInteractions
        ? state?.interaction?.interaction
        : data?.length > 0 && Array.isArray(data)
        ? data
        : [];
      emit(actions.generalSetInteraction(result));
    };
    socket.getNotificationAllChannel(socketEvent);
    return () => {};
  });
}

export function* notificationWatcher() {
  try {
    //   if (userLevel === "Agent") {
    const channel = yield call(notificationEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
    //   }
  } catch (error) {
    console.log(error);
  }
}

function* notificationByChannelEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data, value) => {
      const state = store.getState();
      let result = [];
      /*
   Cek redux interaction kalo kosong result nya langsung lemparan dari socket
   */
      if (state.interaction.length === 0 && Array.isArray(state.interaction)) {
        if (data.length > 0 && Array.isArray(data)) {
          result = data;
        }
      } else {
        /*
   Kalo udah ada data redux interaction ngambil statement ini
   */

        /*
   Kalo response socket data nya > 0 cek dulu apakah data sudah ada di redux atau belum
   */
        if (data.length > 0 && Array.isArray(data)) {
          const dirtyDataNotEqual = state.interaction.interaction.filter(
            (v) => v.channel_id !== data[0].channel_id
          );
          const dirtyDataIsEqual = state.interaction.interaction.filter(
            (v) =>
              v.channel_id === data[0].channel_id &&
              v.group_id === data[0].group_id
          );

          if (dirtyDataIsEqual.length > 0) {
            result = dirtyDataNotEqual.concat(data);
            // console.log("STATEMENT A", result);
          } else {
            result = state.interaction.interaction.concat(data);
            // console.log("STATEMENT B", result);
          }
        } else {
          const dirtyDataNotEqual = state.interaction.interaction.filter(
            (v) => v.channel_id !== value
          );
          const dirtyDataIsEqual = state.interaction.interaction.filter(
            (v) => v.channel_id === value
          );
          if (dirtyDataNotEqual.length > 0) {
            result = dirtyDataNotEqual;
          } else {
            if (dirtyDataIsEqual.length === 1) {
              result = [];
            } else {
              result = state.interaction.interaction;
            }
          }
        }
      }

      if (data.length > 0) {
        if (data[data.length - 1].agent_id !== 0) {
          audio.pause();
        }
      }
      emit(actions.generalSetInteraction(result));
    };
    const channelId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 10, 16, 16];
    channelId.forEach((value) => {
      socket.getNotificationByChannel(socketEvent, value);
    });

    return () => {};
  });
}

export function* notificationByChannelWatcher() {
  try {
    //   if (userLevel === "Agent") {
    const channel = yield call(notificationByChannelEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
    // }
  } catch (error) {
    console.log(error);
  }
}

function* listenAudioChannelEventChannel() {
  return new eventChannel(() => {
    const socketEvent = (data) => {
      if (data === 9) {
        const audio = document.createElement("audio");
        audio.setAttribute("src", jarvis);
        audio.setAttribute("controls", "controls");
        audio.setAttribute("autoPlay", "autoPlay");
        document.body.appendChild(audio);
      } else {
        const audio = document.createElement("audio");
        audio.setAttribute("src", gmeet);
        audio.setAttribute("controls", "controls");
        audio.setAttribute("autoPlay", "autoPlay");
        document.body.appendChild(audio);
      }
    };
    const channelId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 10, 16];
    channelId.forEach((value) => {
      socket.listenAudioChannel(socketEvent, value);
    });

    return () => {};
  });
}

export function* listenAudioChannelWatcher() {
  try {
    if (userLevel === "Agent") {
      const channel = yield call(listenAudioChannelEventChannel);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* generalActionEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      if (data.event === "checkversion") {
        if (data.msg !== crypto.decryptedData().VERSION) {
          emit(actions.generalSetValidVersion(false));
        } else {
          emit(actions.generalSetValidVersion(true));
        }
      }

      if (data.event === "forcelogout") {
        emit(actions.generalSetValidLogout(true));
        if (localStorage.getItem(`${window.TENANT_ID}-forcelogout`)) {
          localStorage.removeItem(`${window.TENANT_ID}-forcelogout`);
        }
        localStorage.setItem(`${window.TENANT_ID}-forcelogout`, true);
      }
    };
    socket.socketEventAction(socketEvent);
    return () => {};
  });
}

export function* generalActionWatcher() {
  try {
    const channel = yield call(generalActionEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

function* insertTransTicketEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const state = store.getState();

      if (state.ticket.length === 0 && Array.isArray(state.ticket)) {
        emit(actions.ticketSet([data]));
      } else {
        const dataComparison = state.ticket.ticket.filter(
          (v) => v.ticket_id === data.ticket_id
        );
        const dataComparisonNotEqual = state.ticket.ticket.filter(
          (v) => v.ticket_id !== data.ticket_id
        );
        if (dataComparison.length === 0) {
          emit(actions.ticketSet([...state.ticket.ticket, data]));
        } else {
          emit(actions.ticketSet([...dataComparisonNotEqual, data]));
        }
      }
    };
    socket.storeInsertTransTicket(socketEvent);
    return () => {};
  });
}

export function* insertTransTicketWatcher() {
  try {
    const channel = yield call(insertTransTicketEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateTransTicketEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const state = store.getState();
      console.log("update state trans ticket", state);
      console.log("update data trans ticket", data);

      console.log("data length", data.length);

      if (data.length > 0) {
        console.log("hit 1");
        if (state.ticket.length === 0 && Array.isArray(state.ticket)) {
          console.log("hit 2");
          emit(actions.ticketSet([data]));
        } else {
          const userid = state.auth.auth.user.userid;
          const findIndex = state.ticket.ticket.findIndex(
            (v) => v.ticket_id === data[0].ticket_id
          );
          console.log("hit 3");
          console.log("findIndex ticket", findIndex);
          const newArray = state.ticket.ticket;
          newArray[findIndex] = data[0];
          emit(actions.ticketSet(newArray));

          //   check if same user remove current ticket
          if (userid === data[0].upd) {
            emit(actions.ticketSetCurrent(null));
          }
        }
      }
    };
    socket.storeUpdateTransTicket(socketEvent);
    return () => {};
  });
}

export function* updateTransTicketWatcher() {
  try {
    const channel = yield call(updateTransTicketEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

function* incomingEventChannel() {
  return new eventChannel((emit) => {
    const socketEventTelegram = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nTelegram`,
              "telegram"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.teleMessages.length === 0 || !state.teleMessages) {
              emit(
                actions.teleSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.teleMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual = state.teleMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
              if (dataComparison.length === 0) {
                emit(
                  actions.teleSetMessages([
                    ...state.teleMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.teleSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.teleMessages.length === 0 || !state.teleMessages) {
            emit(
              actions.teleSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.teleMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual = state.teleMessages.messages.filter(
              (v) => v.session_id !== data.session_id
            );
            if (dataComparison.length === 0) {
              emit(
                actions.teleSetMessages([
                  ...state.teleMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.teleSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventLine = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(data, `${data.message}\n\nLine`, "line");
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.lineMessages.length === 0 || !state.lineMessages) {
              emit(
                actions.lineSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.lineMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual = state.lineMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
              if (dataComparison.length === 0) {
                emit(
                  actions.lineSetMessages([
                    ...state.lineMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.lineSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.lineMessages.length === 0 || !state.lineMessages) {
            emit(
              actions.lineSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.lineMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual = state.lineMessages.messages.filter(
              (v) => v.session_id !== data.session_id
            );
            if (dataComparison.length === 0) {
              emit(
                actions.lineSetMessages([
                  ...state.lineMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.lineSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventWhatsapp = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nWhatsapp`,
              "whatsapp"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (
              state.whatsappMessages.length === 0 ||
              !state.whatsappMessages
            ) {
              emit(
                actions.whatsappSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.whatsappMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual =
                state.whatsappMessages.messages.filter(
                  (v) => v.session_id !== data.session_id
                );
              if (dataComparison.length === 0) {
                emit(
                  actions.whatsappSetMessages([
                    ...state.whatsappMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.whatsappSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.whatsappMessages.length === 0 || !state.whatsappMessages) {
            emit(
              actions.whatsappSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.whatsappMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual =
              state.whatsappMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
            if (dataComparison.length === 0) {
              emit(
                actions.whatsappSetMessages([
                  ...state.whatsappMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.whatsappSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventMessenger = (data) => {
      const state = store.getState();

      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nMessenger`,
              "messenger"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (
              state.messengerMessages.length === 0 ||
              !state.messengerMessages
            ) {
              emit(
                actions.messengerSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.messengerMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual =
                state.messengerMessages.messages.filter(
                  (v) => v.session_id !== data.session_id
                );
              if (dataComparison.length === 0) {
                emit(
                  actions.messengerSetMessages([
                    ...state.messengerMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.messengerSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (
            state.messengerMessages.length === 0 ||
            !state.messengerMessages
          ) {
            emit(
              actions.messengerSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.messengerMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual =
              state.messengerMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
            if (dataComparison.length === 0) {
              emit(
                actions.messengerSetMessages([
                  ...state.messengerMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.messengerSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventSms = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(data, `${data.message}\n\nSms`, "sms");
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.smsMessages.length === 0 || !state.smsMessages) {
              emit(
                actions.smsSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.smsMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual = state.smsMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
              if (dataComparison.length === 0) {
                emit(
                  actions.smsSetMessages([
                    ...state.smsMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.smsSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.smsMessages.length === 0 || !state.smsMessages) {
            emit(
              actions.smsSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.smsMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual = state.smsMessages.messages.filter(
              (v) => v.session_id !== data.session_id
            );
            if (dataComparison.length === 0) {
              emit(
                actions.smsSetMessages([
                  ...state.smsMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.smsSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventTdm = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nTwitter DM`,
              "dm"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.tdmMessages.length === 0 || !state.tdmMessages) {
              emit(
                actions.tdmSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.tdmMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual = state.tdmMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
              if (dataComparison.length === 0) {
                emit(
                  actions.tdmSetMessages([
                    ...state.tdmMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.tdmSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.tdmMessages.length === 0 || !state.tdmMessages) {
            emit(
              actions.tdmSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.tdmMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual = state.tdmMessages.messages.filter(
              (v) => v.session_id !== data.session_id
            );
            if (dataComparison.length === 0) {
              emit(
                actions.tdmSetMessages([
                  ...state.tdmMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.tdmSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };

    const socketEventIg = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state?.interaction?.length && state?.interaction) {
          const pickup = state?.interaction?.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nIG DM`,
              "instagramdm"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.igMessages.length === 0 || !state.igMessages) {
              emit(
                actions.igSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.igMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual = state.igMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
              if (dataComparison.length === 0) {
                emit(
                  actions.igSetMessages([
                    ...state.igMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.igSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.igMessages.length === 0 || !state.igMessages) {
            emit(
              actions.igSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.igMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual = state.igMessages.messages.filter(
              (v) => v.session_id !== data.session_id
            );
            if (dataComparison.length === 0) {
              emit(
                actions.igSetMessages([
                  ...state.igMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.igSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };

    const socketEventWebchat = (data) => {
      const state = store.getState();
      if (data.action_type === "IN") {
        if (!state.interaction.length && state.interaction) {
          const pickup = state.interaction.interaction.filter(
            (item) => item.agent_id === state.auth.auth.user.userid
          );
          const dirtyDataPickup = pickup.filter(
            (v) => v.session_id === data.session_id
          );
          if (dirtyDataPickup.length > 0) {
            const audio = new Audio(audioSuccess);
            audio.play();
            helper.pushNotification(
              data,
              `${data.message}\n\nWebchat`,
              "webchat"
            );
            emit(
              actions.generalSetIncoming([
                ...(state.incoming.incoming || []),
                data,
              ])
            );
            if (state.webchatMessages.length === 0 || !state.webchatMessages) {
              emit(
                actions.webchatSetMessages([
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              const dataComparison = state.webchatMessages.messages.filter(
                (v) => v.session_id === data.session_id
              );
              const dataComparisonNotEqual =
                state.webchatMessages.messages.filter(
                  (v) => v.session_id !== data.session_id
                );
              if (dataComparison.length === 0) {
                emit(
                  actions.webchatSetMessages([
                    ...state.webchatMessages.messages,
                    {
                      message: [data],
                      session_id: data.session_id,
                      type: "socket",
                    },
                  ])
                );
              } else {
                dataComparison[0].message =
                  dataComparison[0].message.concat(data);
                emit(
                  actions.webchatSetMessages([
                    ...dataComparisonNotEqual,
                    dataComparison[0],
                  ])
                );
              }
            }
          }
        }
      } else {
        if (state.auth.auth.user.userid === data.agent_id) {
          if (state.webchatMessages.length === 0 || !state.webchatMessages) {
            emit(
              actions.webchatSetMessages([
                {
                  message: [data],
                  session_id: data.session_id,
                  type: "socket",
                },
              ])
            );
          } else {
            const dataComparison = state.webchatMessages.messages.filter(
              (v) => v.session_id === data.session_id
            );
            const dataComparisonNotEqual =
              state.webchatMessages.messages.filter(
                (v) => v.session_id !== data.session_id
              );
            if (dataComparison.length === 0) {
              emit(
                actions.webchatSetMessages([
                  ...state.webchatMessages.messages,
                  {
                    message: [data],
                    session_id: data.session_id,
                    type: "socket",
                  },
                ])
              );
            } else {
              dataComparison[0].message =
                dataComparison[0].message.concat(data);
              emit(
                actions.webchatSetMessages([
                  ...dataComparisonNotEqual,
                  dataComparison[0],
                ])
              );
            }
          }
        }
      }
    };
    const socketEventMessengerUpdate = (data) => {
      const state = store.getState();
      if (state.auth.auth.user.userid === data.agent_id) {
        const dirtyData = state.messengerMessages.messages.filter(
          (v) => v.session_id === data.session_id
        );
        const indexData = dirtyData[0].message.findIndex(
          (i) => i.id === data.id
        );
        dirtyData[0].message[indexData] = data;
        emit(actions.messengerSetMessages(dirtyData));
      }
    };

    const socetEventVoiceInteraction = (data) => {
      console.log("SOCKET EVENT VOICE INTERACTION ====================== >");
      console.log(data);
    };

    const socetEventVoiceInteractionHistory = (data) => {
      console.log(
        "SOCKET EVENT VOICE INTERACTION HISTORY ====================== >"
      );
      console.log(data);
    };

    socket.storeInsertInteractionTelegram(socketEventTelegram);
    socket.storeInsertInteractionWhatsapp(socketEventWhatsapp);
    socket.storeInsertInteractionMessenger(socketEventMessenger);
    socket.storeInsertInteractionSms(socketEventSms);
    socket.storeInsertInteractionTdm(socketEventTdm);
    socket.storeInsertInteractionWebChat(socketEventWebchat);
    socket.updateInsertInteractionMessenger(socketEventMessengerUpdate);
    socket.storeInsertInteractionVoice(socetEventVoiceInteraction);
    socket.storeInsertInteractionVoiceHistory(
      socetEventVoiceInteractionHistory
    );
    socket.storeInsertInteractionLine(socketEventLine);
    socket.storeInsertInteractionIgDm(socketEventIg);
    return () => {};
  });
}

export function* incomingWatcher() {
  try {
    if (userLevel === "Agent") {
      const channel = yield call(incomingEventChannel);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* sumCwcWatcher(action) {
  try {
    const data = yield call(generalServices.getSumCwc, action.data);
    yield put(actions.generalSetSumCwc(data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* templateWatcher(action) {
  try {
    const data = yield call(generalServices.getTemplate, action.data);
    yield put(actions.generalSetTemplate(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* logCwcWatcher(action) {
  try {
    const postData = action.logCwc.value
      ? {
          tenant_id: window.TENANT_ID,
          date: action.logCwc.date,
          limit: 10,
          offset: action.logCwc.offset,
          order: "date_contact desc",
          channel_id: action.logCwc.channel_id,
          keyword: action.logCwc.value,
          filter: action.logCwc.filter,
          startDate: action.logCwc.startDate,
          endDate: action.logCwc.endDate,
          category: action.logCwc.category,
          agent_id: action.logCwc.agent_id,
        }
      : {
          tenant_id: window.TENANT_ID,
          date: action.logCwc.date,
          limit: 10,
          offset: action.logCwc.offset,
          order: "date_contact desc",
          channel_id: action.logCwc.channel_id,
          filter: action.logCwc.filter,
          startDate: action.logCwc.startDate,
          endDate: action.logCwc.endDate,
          category: action.logCwc.category,
          agent_id: action.logCwc.agent_id,
        };

    const data = yield call(generalServices.getLogCwc, postData);
    let returnable = [];
    let returnableLoadMore = false;
    if (data.TotalPages <= 1) {
      returnableLoadMore = true;
    }
    const state = store.getState();
    if (action.logCwc.type === "load_more") {
      const dataComparison = state.logCwc.logCwc;
      returnable = dataComparison.concat(data.Record);
      if (action.logCwc.offset >= data.TotalPages - 1) {
        returnableLoadMore = true;
      }
    } else {
      returnable = data.Record;
    }
    yield all([
      put(actions.generalSetLogCwc(returnable)),
      put(actions.generalSetHideLoadMoreLogCwc(returnableLoadMore)),
    ]);
  } catch (error) {
    console.log(error);
  }
}

export function* logCwcMergeWatcher(action) {
  try {
    const postData = action.mergeCwc.value
      ? {
          tenant_id: window.TENANT_ID,
          date: action.mergeCwc.date,
          limit: 10,
          offset: action.mergeCwc.offset,
          order: "date_contact desc",
          channel_id: action.mergeCwc.channel_id,
          keyword: action.mergeCwc.value,
          filter: action.mergeCwc.filter,
        }
      : {
          tenant_id: window.TENANT_ID,
          date: action.mergeCwc.date,
          limit: 10,
          offset: action.mergeCwc.offset,
          order: "date_contact desc",
          channel_id: action.mergeCwc.channel_id,
          filter: action.mergeCwc.filter,
        };

    const data = yield call(generalServices.getLogCwc, postData);
    yield put(actions.generalSetMergeCwc(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* logInteractionWatcher(action) {
  try {
    const data = yield call(
      generalServices.getLogInteraction,
      action.interaction
    );
    yield put(actions.generalSetLogInteraction([data]));
  } catch (error) {
    console.log(error);
  }
}

export function* addFormWatcher(action) {
  try {
    const data = yield call(generalServices.getAddForm, action.form);
    yield put(actions.generalSetAddForm(data.Record));
  } catch (error) {
    console.log(error);
  }
}

export function* manualKipWatcher(action) {
  try {
    const data = yield call(generalServices.getManualKip, action.manual);
    yield put(actions.generalSetManualKip(data.listQueue));
  } catch (error) {
    console.log(error);
  }
}

export function* addFormOptWatcher(action) {
  try {
    const state = store.getState();
    let returnable = [];
    const data = yield call(generalServices.getAddOpt, action.form);
    if (!Array.isArray(state.addFormOpt)) {
      const duplicate = state.addFormOpt.form.concat(data.Record);
      returnable = _.uniqBy(duplicate, "opt_id");
    } else {
      returnable = data.Record;
    }
    yield put(actions.generalSetAddFormOpt(returnable));
  } catch (error) {
    console.log(error);
  }
}

function* ticketCountEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const counter =
        Array.isArray(data) && data.length > 0 ? data[0].Total : null;
      emit(actions.ticketSetCount(counter));
    };
    socket.getCountTicket(socketEvent);
    return () => {};
  });
}

export function* ticketCountWatcher() {
  try {
    const channel = yield call(ticketCountEventChannel);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

function* vaxVoipEventChannel() {
  return new eventChannel((emit) => {
    eventEmitter.on("REGISTERED", () => {
      emit(actions.generalSetStatusCallRegister("REGISTERED"));
    });

    eventEmitter.on("CONNECTING", () => {
      emit(actions.generalSetStatusCallRegister("CONNECTING"));
    });

    eventEmitter.on("FAILED", (data) => {
      emit(actions.generalSetStatusCallRegister("FAILED: " + data));
    });

    eventEmitter.on("INCOMING", (data) => {
      voiceByAgentRinging(data, emit);
    });

    eventEmitter.on("CONNECTED", () => {
      voiceByAgentAnswer(emit);
    });

    eventEmitter.on("HANGUP", () => {
      voiceByAgentHangup(emit);
    });
    return () => {};
  });
}

function voiceByAgentRinging(data, emit) {
  const dataStore = store.getState();
  console.log(dataStore.outCall?.active);
  if (!dataStore.outCall?.active) {
    generalServices
      .voiceByAgent({
        peerdisplayname: "",
        unique_id: "",
        from: data,
        agent_id: dataStore.auth.auth.user.userid,
        tenant_id: window.TENANT_ID,
      })
      .then(async (data) => {
        if (data) {
          const e = data.data;
          emit(actions.generalSetMergeCwc([]));
          emit(actions.generalSetStatusCall("RINGING"));
          emit(actions.generalSetRoute("/voice"));
          history.push("/voice");
          emit(actions.generalSetOpenIncoming(true));
          const evtarray = [0, 0, 0, e.from];
          emit(actions.generalSetIsCall(true));
          emit(actions.generalSetCall(evtarray));
          emit(actions.generalSetHideTabInteraction(false));

          e.is_cwc = 0;
          emit(actions.voiceSetCurrent(e));
          await generalServices
            .getJourney({
              tenant_id: window.TENANT_ID,
              fields: "*",
              order: "date_contact desc",
              limit: "10",
              offset: 0,
              filter: { cust_id: e.cust_id },
            })
            .then((res) => {
              emit(actions.generalSetJourney(res?.Record));
            });

          await generalServices
            .getProfile({
              tenant_id: window.TENANT_ID,
              cust_id: e.cust_id,
            })
            .then((res) => {
              emit(actions.generalSetProfile(res?.Record));
            });
          const postDataTicket = {
            tenant_id: window.TENANT_ID,
            limit: 10,
            offset: 0,
            order: `create_date desc,ticket_priority desc`,
            filter: { cust_id: e.cust_id },
          };
          await ticketService.getTicket(postDataTicket).then((res) => {
            emit(actions.ticketSet(res.Record));
          });
          const postData = {
            tenant_id: window.TENANT_ID,
            limit: 20,
            offset: 0,
            order: "status_id desc",
          };
          await ticketService.getTicketStatus(postData).then((res) => {
            emit(actions.ticketSetStatus(res.Record));
          });
        }
      });
  }
}

function voiceByAgentAnswer(emit) {
  const dataStore = store.getState();
  emit(actions.generalSetStatusCall("CONNECTED"));
  if (!dataStore.outCall?.active) {
    emit(actions.generalSetStatusCall("CONNECTED"));
    const before = new Date();
    interval = setInterval(() => {
      const now = new Date();
      const timeDifference = now - before;

      const secondsInADay = 60 * 60 * 1000 * 24;
      const secondsInAHour = 60 * 60 * 1000;

      const hours = Math.floor(
        ((timeDifference % secondsInADay) / secondsInAHour) * 1
      );
      const mins = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
      );
      const secs = Math.floor(
        ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
          1000) *
          1
      );

      emit(
        actions.generalSetDurationCall(
          `${hours < 10 ? "0" + hours : hours}:${
            mins < 10 ? "0" + mins : mins
          }:${secs < 10 ? "0" + secs : secs}`
        )
      );
    }, 1000);
  }
}

function voiceByAgentHangup(emit) {
  const dataStore = store.getState();
  if (!dataStore.outCall?.active) {
    clearInterval(interval);
    const dataStore = store.getState();
    if (dataStore.voiceCurrent?.current) {
      const postData = {
        channel_id: 1,
        tenant_id: window.TENANT_ID,
        session_id: dataStore.voiceCurrent?.current.session_id,
        from: dataStore.voiceCurrent?.current.from,
        from_name: dataStore.voiceCurrent?.current.from_name,
        userid: dataStore.auth.auth.user.userid,
        end_session: 1,
      };
      generalServices.endInteraction(postData).then((res) => {
        if (res) {
          emit(actions.generalSetCall([]));
          emit(actions.generalSetStatusCall(false));
          emit(actions.generalSetRoute("/voice"));
          emit(actions.generalSetIsCall(false));
          emit(actions.generalSetOpenIncoming(false));
          emit(actions.generalSetDurationCall(null));
          emit(actions.generalSetEndDate(res.end_date));
          emit(actions.generalSetCwcId(res.data.insertId));
          emit(actions.generalSetDisabled(true));
        }
      });
    }
  } else {
    emit(actions.generalSetOutcall(false));
  }
}

export function* vaxVoipWatcher() {
  try {
    if (userLevel === "Agent" && parseInt(window.isMizu, 10) === 4) {
      const channel = yield call(vaxVoipEventChannel);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* amiVoiceEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      console.log(data);
      const state = store.getState();
      if (
        state.auth.auth.user &&
        state.auth.auth.user.userlevel === "Agent" &&
        state.auth.auth.user.login_pbx &&
        parseInt(window.isMizu) === 0
      ) {
        const validateChannel =
          state.channel?.channel.filter((v) => v.channel_name === "Voice")
            .length > 0
            ? state.channel?.channel.filter(
                (v) => v.channel_name === "Voice"
              )[0].is_active === "1"
              ? state.channelAgent?.channel
                ? state.channelAgent?.channel.filter((v) => v.field === "voice")
                    .length > 0
                  ? state.channelAgent?.channel.filter(
                      (v) => v.field === "voice"
                    )[0].value === "1"
                    ? ["true"]
                    : []
                  : []
                : []
              : []
            : [];
        /*
      {
    "Event": "AgentRingNoAnswer",
    "Privilege": "agent,all",
    "Channel": "SIP/513_AmarthaCSout-00002280",
    "ChannelState": "6",
    "ChannelStateDesc": "Up",
    "CallerIDNum": "+622180604252",
    "CallerIDName": "+622180604252",
    "ConnectedLineNum": "<unknown>",
    "ConnectedLineName": "<unknown>",
    "Language": "en",
    "AccountCode": "",
    "Context": "ext-queues",
    "Exten": "183",
    "Priority": "51",
    "Uniqueid": "1646659525.17645",
    "Linkedid": "1646659525.17645",
    "DestChannel": "Local/26005@from-queue-000001d6;1",
    "DestChannelState": "5",
    "DestChannelStateDesc": "Ringing",
    "DestCallerIDNum": "622130000907",
    "DestCallerIDName": "<unknown>",
    "DestConnectedLineNum": "+622180604252",
    "DestConnectedLineName": "+622180604252",
    "DestLanguage": "en",
    "DestAccountCode": "",
    "DestContext": "from-queue",
    "DestExten": "183",
    "DestPriority": "1",
    "DestUniqueid": "1646659541.17646",
    "DestLinkedid": "1646659525.17645",
    "Queue": "183",
    "Interface": "Local/26005@from-queue/n",
    "MemberName": "26005",
    "RingTime": "15000"
}
      */
        console.log(validateChannel);
        console.log(parseInt(window.isMizu));
        if (validateChannel.length > 0) {
          const parsingJSON = data ? JSON.parse(data) : null;
          console.log(parsingJSON);
          if (parsingJSON.Event === "AgentConnect") {
            voiceByAgentRingingAmi(parsingJSON, emit, store);
            clearInterval(intervalPopup);
          }

          if (parsingJSON.Event === "AgentCalled") {
            emit(actions.generalSetOpenIncoming(true));
            emit(actions.generalSetCall([0, 0, 0, parsingJSON?.CallerIDNum]));
            emit(actions.generalSetStatusCall("RINGING"));
            intervalPopup = setInterval(() => {
              emit(actions.generalSetOpenIncoming(false));
              emit(actions.generalSetStatusCall(null));
              clearInterval(intervalPopup);
            }, 20000);
          }

          if (parsingJSON.Event === "AgentComplete") {
            if (!state.voiceCurrent?.current && !state.openIncoming) {
              if (typeof state.interaction !== undefined) {
                const dataVoice = state.interaction?.interaction.filter(
                  (v) => v.from === parsingJSON?.CallerIDNum
                );
                emit(
                  actions.voiceSetCurrent(
                    dataVoice.length > 0 ? dataVoice[0] : null
                  )
                );
              }
            }
            voiceByAgentHangupAmi(emit, store);
          }

          if (parsingJSON.Event === "AgentRingNoAnswer") {
            emit(actions.generalSetOpenIncoming(false));
            emit(actions.generalSetStatusCall(null));
            clearInterval(intervalPopup);
          }
        }
      }
    };
    socket.amiSocket(socketEvent);
    return () => {};
  });
}

function voiceByAgentRingingAmi(parsingJSON, emit, state) {
  console.log("ANSWER");
  const dataStore = state.getState();
  generalServices
    .voiceByAgent({
      peerdisplayname: "",
      unique_id: "",
      ruid: parsingJSON?.Uniqueid,
      uid: parsingJSON?.Uniqueid,
      from: parsingJSON?.CallerIDNum,
      agent_id: dataStore.auth.auth.user.userid,
      tenant_id: window.TENANT_ID,
    })
    .then(async (res) => {
      if (res) {
        const e = res.data;
        const postData = {
          channel_id: 1,
          tenant_id: window.TENANT_ID,
          session_id: e.session_id,
          from: e.from,
          from_name: e.from_name,
          userid: dataStore.auth.auth.user.userid,
          end_session: 1,
          rec: e.rec,
        };
        generalServices.endInteraction(postData).then(async (res) => {
          if (res) {
            emit(actions.generalSetCwcId(res.data.insertId));
            emit(actions.generalSetMergeCwc([]));
            emit(actions.generalSetStatusCall("CONNECTED"));
            emit(actions.generalSetRoute("/voice"));
            history.push("/voice");
            const before = new Date();
            interval = setInterval(() => {
              const now = new Date();
              const timeDifference = now - before;
              const secondsInADay = 60 * 60 * 1000 * 24;
              const secondsInAHour = 60 * 60 * 1000;
              const hours = Math.floor(
                ((timeDifference % secondsInADay) / secondsInAHour) * 1
              );
              const mins = Math.floor(
                (((timeDifference % secondsInADay) % secondsInAHour) /
                  (60 * 1000)) *
                  1
              );
              const secs = Math.floor(
                ((((timeDifference % secondsInADay) % secondsInAHour) %
                  (60 * 1000)) /
                  1000) *
                  1
              );
              emit(
                actions.generalSetDurationCall(
                  `${hours < 10 ? "0" + hours : hours}:${
                    mins < 10 ? "0" + mins : mins
                  }:${secs < 10 ? "0" + secs : secs}`
                )
              );
            }, 1000);
            const evtarray = [0, 0, 0, e.from];
            emit(actions.generalSetIsCall(true));
            emit(actions.generalSetCall(evtarray));
            emit(actions.generalSetHideTabInteraction(true));
            e.is_cwc = 0;
            e.ruid = parsingJSON?.Uniqueid;
            emit(actions.voiceSetCurrent(e));
            await generalServices
              .getJourney({
                tenant_id: window.TENANT_ID,
                fields: "*",
                order: "date_contact desc",
                limit: "10",
                offset: 0,
                filter: { cust_id: e.cust_id },
              })
              .then((res) => {
                emit(actions.generalSetJourney(res?.Record));
              });
            await generalServices
              .getProfile({
                tenant_id: window.TENANT_ID,
                cust_id: e.cust_id,
              })
              .then((res) => {
                emit(actions.generalSetProfile(res?.Record));
              });
            actions.ticketGet({
              filter: {
                cust_id: e.cust_id,
              },
              offset: 0,
            });
            const postDataTicket = {
              tenant_id: window.TENANT_ID,
              limit: 10,
              offset: 0,
              order: `create_date desc,ticket_priority desc`,
              filter: { cust_id: e.cust_id },
            };
            await ticketService.getTicket(postDataTicket).then((res) => {
              emit(actions.ticketSet(res.Record));
            });
            const postData = {
              tenant_id: window.TENANT_ID,
              limit: 20,
              offset: 0,
              order: "status_id desc",
            };
            await ticketService.getTicketStatus(postData).then((res) => {
              emit(actions.ticketSetStatus(res.Record));
            });
          }
        });
      }
    });
}

function voiceByAgentHangupAmi(emit, state) {
  clearInterval(interval);
  const dataStore = state.getState();
  emit(actions.generalSetStatusCall(false));
  emit(actions.generalSetRoute("/voice"));
  emit(actions.generalSetIsCall(false));
  emit(actions.generalSetOpenIncoming(false));
  emit(actions.generalSetDurationCall(null));
  if (dataStore.voiceCurrent?.current) {
    const interaction = helper.getInteraction(
      helper.mapStateToProps(dataStore),
      1
    );
    const validateInteraction = interaction.filter(
      (v) => v.session_id === dataStore.voiceCurrent?.session_id
    );
    emit(
      actions.generalSetEndDate(
        validateInteraction.length > 0 ? validateInteraction[0].end_date : ""
      )
    );
    emit(actions.generalSetDisabled(true));
  }
}
export function* amiVoiceWatcher() {
  try {
    const amiVoice = yield call(amiVoiceEventChannel);
    while (true) {
      const action = yield take(amiVoice);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

function* mizzuEventChannel() {
  const store = yield getContext("store");
  const state = store.getState();
  return new eventChannel((emit) => {
    if (
      state.auth.auth.user &&
      state.auth.auth.user.userlevel === "Agent" &&
      state.auth.auth.user.login_pbx &&
      parseInt(window.isMizu) === 1 &&
      window.webphone_api &&
      state.auth.auth.user.is_login !== 2
    ) {
      window.webphone_api.parameters = {
        serveraddress: state.auth.auth.user.pbx_host,
        username: state.auth.auth.user.login_pbx,
        password: state.auth.auth.user.pwd_pbx,
        loglevel: 0,
        registerinterval: 30,
        callreceiver: -1,
        autoaccept: false,
        beeponincoming: 0,
        beeponconnect: 0,
        enginepriority_webrtc: 0,
        enginepriority_ns: 5,
      };

      window.webphone_api.onEvent(function (type, message) {
        if (type === "event") {
          var evtarray = message.split(",");
          const outCall = state.outCall ? state.outCall.active : null;
          console.log("OUTCALL BOSS", outCall);
          if (evtarray[0] === "STATUS" && evtarray[2] === "Accepted") {
            const before = new Date();
            intervalMizzu = setInterval(() => {
              const now = new Date();
              const timeDifference = now - before;

              const secondsInADay = 60 * 60 * 1000 * 24;
              const secondsInAHour = 60 * 60 * 1000;

              const hours = Math.floor(
                ((timeDifference % secondsInADay) / secondsInAHour) * 1
              );
              const mins = Math.floor(
                (((timeDifference % secondsInADay) % secondsInAHour) /
                  (60 * 1000)) *
                  1
              );
              const secs = Math.floor(
                ((((timeDifference % secondsInADay) % secondsInAHour) %
                  (60 * 1000)) /
                  1000) *
                  1
              );

              emit(
                actions.generalSetDurationCall(
                  `${hours < 10 ? "0" + hours : hours}:${
                    mins < 10 ? "0" + mins : mins
                  }:${secs < 10 ? "0" + secs : secs}`
                )
              );
            }, 1000);
          }

          if (evtarray[0] === "STATUS" && evtarray[2] === "Ringing") {
            console.log("======>RINGING...", message);
            if (!outCall) {
              emit(actions.generalSetOpenIncoming(true));
              emit(actions.generalSetIsCall(true));
              emit(actions.generalSetCall(evtarray));
              emit(actions.generalSetHideTabInteraction(true));
            }
            emit(actions.generalSetStatusCall("RINGING"));
          }
        }
      });

      window.webphone_api.onAppStateChange(function (
        state //watch for webphone state changes
      ) {
        console.log("===> this state: ", state);
        if (state === "loaded") {
          window.webphone_api.start(); //start if not started automatically
          window.webphone_api.onCallStateChange(function (
            event,
            direction,
            peername,
            peerdisplayname,
            line,
            callid
          ) {
            console.log("EVENT BOS : " + event);
            if (event === "callSetup") {
              if (direction === 1) {
                console.log("====> OUTGOING CALL");
              } else if (direction === 2) {
                console.log("====> INCOMING CALL");
              }
            }
            if (event === "callConnected" || event === "connected") {
              createSessionMizzu(
                {
                  peerdisplayname,
                  peername,
                },
                emit
              );
            }
            if (event === "callDisconnected" || event === "disconnected") {
              console.log("===> call disconnect :" + peername);
              hangupSessionMizzu(emit);
            }
          });
        } else if (state === "started") {
          emit(actions.generalSetStatusCallRegister("STARTED"));
          console.log("STARTED");
          console.log("ON START...");
        }
      });

      window.webphone_api.onRegStateChange(function (
        state //watch for webphone state changes
      ) {
        console.log("===>onRegStateChange:", state);
        emit(
          actions.generalSetStatusCallRegister(state.toString().toUpperCase())
        );
      });

      window.webphone_api.onCdr(function (
        caller,
        called,
        connecttime,
        duration,
        direction,
        peerdisplayname,
        reason,
        line
      ) {
        console.log(
          "===>CRD:",
          caller,
          called,
          connecttime,
          duration,
          direction,
          peerdisplayname,
          reason,
          line
        );
      });
    }
    return () => {};
  });
}

function createSessionMizzu(data, emit) {
  const dataStore = store.getState();
  const outCall = dataStore.outCall ? dataStore.outCall.active : null;
  if (!outCall) {
    generalServices
      .voiceByAgent({
        peerdisplayname: data.peerdisplayname,
        unique_id:
          data.peerdisplayname.split("-").length > 0
            ? data.peerdisplayname.split("-")[1]
            : "",
        from: data.peername,
        agent_id: dataStore.auth.auth.user.userid,
        tenant_id: window.TENANT_ID,
      })
      .then(async (data) => {
        if (data) {
          const e = data.data;
          emit(actions.generalSetMergeCwc([]));
          emit(actions.generalSetStatusCall("CONNECTED"));
          emit(actions.generalSetRoute("/voice"));
          history.push("/voice");
          e.is_cwc = 0;
          emit(actions.voiceSetCurrent(e));
          await generalServices
            .getJourney({
              tenant_id: window.TENANT_ID,
              fields: "*",
              order: "date_contact desc",
              limit: "10",
              offset: 0,
              filter: { cust_id: e.cust_id },
            })
            .then((res) => {
              emit(actions.generalSetJourney(res?.Record));
            });

          await generalServices
            .getProfile({
              tenant_id: window.TENANT_ID,
              cust_id: e.cust_id,
            })
            .then((res) => {
              emit(actions.generalSetProfile(res?.Record));
            });
          const postDataTicket = {
            tenant_id: window.TENANT_ID,
            limit: 10,
            offset: 0,
            order: `create_date desc,ticket_priority desc`,
            filter: { cust_id: e.cust_id },
          };
          await ticketService.getTicket(postDataTicket).then((res) => {
            emit(actions.ticketSet(res.Record));
          });
          const postData = {
            tenant_id: window.TENANT_ID,
            limit: 20,
            offset: 0,
            order: "status_id desc",
          };
          await ticketService.getTicketStatus(postData).then((res) => {
            emit(actions.ticketSetStatus(res.Record));
          });
        }
      });
  } else {
    emit(actions.generalSetStatusCall("CONNECTED"));
  }
}

function hangupSessionMizzu(emit) {
  const dataStore = store.getState();
  if (!dataStore.outCall?.active) {
    clearInterval(intervalMizzu);
    const dataStore = store.getState();
    if (dataStore.voiceCurrent?.current) {
      const postData = {
        channel_id: 1,
        tenant_id: window.TENANT_ID,
        session_id: dataStore.voiceCurrent?.current.session_id,
        from: dataStore.voiceCurrent?.current.from,
        from_name: dataStore.voiceCurrent?.current.from_name,
        userid: dataStore.auth.auth.user.userid,
        end_session: 1,
      };
      generalServices.endInteraction(postData).then((res) => {
        if (res) {
          emit(actions.generalSetCall([]));
          emit(actions.generalSetStatusCall(false));
          emit(actions.generalSetRoute("/voice"));
          emit(actions.generalSetIsCall(false));
          emit(actions.generalSetOpenIncoming(false));
          emit(actions.generalSetDurationCall(null));
          emit(actions.generalSetEndDate(res.end_date));
          emit(actions.generalSetCwcId(res.data.insertId));
          emit(actions.generalSetDisabled(true));
        }
      });
    }
  } else {
    emit(actions.generalSetCall([]));
    emit(actions.generalSetDurationCall("00:00:00"));
    emit(actions.generalSetStatusCall(false));
    emit(actions.generalSetOutcall(false));
  }
}

export function* mizzuWatcher() {
  try {
    if (userLevel === "Agent") {
      const channel = yield call(mizzuEventChannel);
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    }
  } catch (error) {
    console.log(error);
  }
}
