import { combineReducers } from "redux";
import * as general from "./general";
import * as channel from "./channel";
import * as ticket from "./ticket";
import * as customer from "./customer";
import * as spv from "./spv";
import * as report from "./report";
import * as voiceLog from "./voicelog";

const appReducer = combineReducers({
 loading: general.generalLoadingReducer,
 auth: general.generalAuthReducer,
 authRequest: general.generalAuthRequestReducer,
 channel: general.generalChannelReducer,
 channelAgent: general.generalChannelAgentReducer,
 notification: general.generalNotificationReducer,
 route: general.generalRouteReducer,
 journey: general.generalJourneyReducer,
 interaction: general.generalInteractionReducer,
 tabInteraction: general.generalTabInteractionReducer,
 tabInfo: general.generalTabInfoReducer,
 profile: general.generalProfileReducer,
 incoming: general.generalIncomingReducer,
 text: general.generalTextReducer,
 textCwc: general.generalTextCwcReducer,
 category: general.generalCategoryReducer,
 cwcId: general.generalCwcIdReducer,
 endDate: general.generalEndDateReducer,
 disabled: general.generalDisabledReducer,
 hideLoadMoreTicket: general.generalHideLoadMoreTicketReducer,
 hideLoadMoreEmailSpv: general.generalHideLoadMoreTicketEmailSpv,
 hideLoadMoreLogCwc: general.generalHideLoadMoreLogCwc,
 sumCwc: general.generalSumCwcReducer,
 statusAllEmail: general.generalStatusAllEmailReducer,
 template: general.generalTemplateReducer,
 validVersion: general.generalValidVersionReducer,
 validLogout: general.generalValidLogoutReducer,
 logCwc: general.generalLogCwcReducer,
 logCwcCurrent: general.generalLogCwcCurrentReducer,
 logInteraction: general.generalLogInteractionReducer,
 call: general.generalCallReducer,
 statusCall: general.generalStatusCallReducer,
 statusRegister: general.generalStatusCallRegisterReducer,
 openIncoming: general.setOpenIncomingReducer,
 first: general.setFirstReducer,
 second: general.setSecondReducer,
 event: general.setEventReducer,
 durationCall: general.generalDurationCallReducer,
 addForm: general.generalAddFormReducer,
 addFormOpt: general.generalAddFormOptReducer,
 archiveId: general.generalArchiveIdReducer,
 hideTabInteraction: general.generalHideTabInteractionReducer,
 activeManualKip: general.generalActiveManualKipReducer,
 outCall: general.generalOutCallReducer,
 manualKip: general.generalManualKipReducer,
 mergeCwc: general.generalMergeCwcReducer,
 teleCurrent: channel.teleCurrentReducer,
 teleMessages: channel.teleMessagesReducer,
 whatsappCurrent: channel.whatsappCurrentReducer,
 whatsappMessages: channel.whatsappMessagesReducer,
 messengerCurrent: channel.messengerCurrentReducer,
 messengerMessages: channel.messengerMessagesReducer,
 smsCurrent: channel.smsCurrentReducer,
 smsMessages: channel.smsMessagesReducer,
 tdmCurrent: channel.tdmCurrentReducer,
 tdmMessages: channel.tdmMessagesReducer,
 webchatCurrent: channel.webchatCurrentReducer,
 webchatMessages: channel.webchatMessagesReducer,
 emailCurrent: channel.emailCurrentReducer,
 emailMessages: channel.emailMessagesReducer,
 facebookCurrent: channel.facebookCurrentReducer,
 facebookMessages: channel.facebookMessagesReducer,
 twitterCurrent: channel.twitterCurrentReducer,
 twitterMessages: channel.twitterMessagesReducer,
 instagramCurrent: channel.instagramCurrentReducer,
 instagramMessages: channel.instagramMessagesReducer,
 vidcallCurrent: channel.vidcallCurrentReducer,
 vidcallMessages: channel.vidcallMessagesReducer,
 voiceCurrent: channel.voiceCurrentReducer,
 voiceMessages: channel.voiceMessagesReducer,
 lineCurrent: channel.lineCurrentReducer,
 lineMessages: channel.lineMessagesReducer,
 igCurrent: channel.igCurrentReducer,
 igMessages: channel.igMessagesReducer,
 ticketHistory: ticket.ticketHistoryReducer,
 ticketStatus: ticket.ticketStatusReducer,
 ticketUnit: ticket.ticketUnitReducer,
 ticket: ticket.ticketReducer,
 ticketCurrent: ticket.ticketCurrentReducer,
 ticketStatusLevel: ticket.ticketStatusLevelReducer,
 ticketStatusNext: ticket.ticketStatusNextReducer,
 ticketCaseOrigin: ticket.ticketCaseOriginReducer,
 ticketServiceArea: ticket.ticketServiceAreaReducer,
 ticketStage: ticket.ticketStageReducer,
 ticketAdditional: ticket.ticketAdditionalReducer,
 ticketAdditionalValue: ticket.ticketAdditionalValueReducer,
 ticketCount: ticket.ticketCountReducer,
 customer: customer.customerReducer,
 customerCurrent: customer.customerCurrentReducer,
 customerLoadMore: customer.customerLoadMoreReducer,
 spvEmailList: spv.SpvEmailListReducer,
 spvEmailListCurrent: spv.SpvEmailListCurrentReducer,
 customeReport: report.customeReportReducer,
 voiceLog: voiceLog.voiceLogReducer,
 voiceLogCurrent: voiceLog.voiceLogCurrentReducer,
 voiceLogLoadMore: voiceLog.voiceLogLoadMoreReducer,
});

export default appReducer;
