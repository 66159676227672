/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import {
 GET_CHANNEL_,
 REGISTER_QUEUE_,
 GET_JOURNEY_,
 GET_PROFILE_,
 UPDATE_PROFILE_,
 GET_CATEGORY_,
 CWC_UPDATE_,
 END_INTERACTION_,
 MERGE_CUSTOMER_,
 GET_CUSTOMER_,
 SUMMARY_CHANNEL_,
 GET_LOG_CWC,
 SUM_CWC_,
 GET_AUX_,
 GET_SIGNATURE_,
 ERROR_REPORT_,
 LOAD_IMAGE,
 ESKALASI_GROUP,
 TEMPLATE_,
 CREATE_CUSTOMER_,
 SEARCH_CUSTOMER_,
 GET_ACCOUNT_,
 BLOCKED_MSG,
 IN_QUEUE,
 LOGIN_STATUS,
 LOG_INTERACTION,
 VALIDATE_TENANT,
 VOICE_AGENT,
 GET_CHANNEL_AGENT,
 GET_LOG_CWC_ALL,
 ADD_FIELD,
 ADD_OPT,
 ADD_MANUAL_KIP,
 GET_LOGIN_STATUS,
 GET_MANUAL_KIP,
 GET_USER_DETAIL_,
 DASHBOARD_MONITORING,
 DASHBOARD_MONITORING_HISTORY,
 DASHBOARD_MONITORING_HISTORY_TODAY,
 RADION,
 DOWNLOAD_RECORDING,
} from "../utils/api";
import http from "../utils/http";
import crypto from "utils/crypto";

export default {
 getChannel(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_CHANNEL_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getJourney(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_JOURNEY_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInQueue(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: IN_QUEUE,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTemplate(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: TEMPLATE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getManualKip(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_MANUAL_KIP,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getBlockedMsg(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: BLOCKED_MSG,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAccount(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_ACCOUNT_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAux() {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data: { tenant_id: window.TENANT_ID },
    url: GET_AUX_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getProfile(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_PROFILE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getCategory(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_CATEGORY_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getCustomer(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_CUSTOMER_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getLogCwc(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_LOG_CWC,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getLogCwcAll(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_LOG_CWC_ALL,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAddForm(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: ADD_FIELD,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAddOpt(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: ADD_OPT,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getSumCwc(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: SUM_CWC_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getSummaryAgent(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: SUMMARY_CHANNEL_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getSignature(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_SIGNATURE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getChannelAgent(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_CHANNEL_AGENT,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 registerQueue(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: REGISTER_QUEUE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 errorReport(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: ERROR_REPORT_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateProfile(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: UPDATE_PROFILE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 uploadFile(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${crypto.decryptedData().MIDDLEWARE_URL}/minio/upload`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 endInteraction(data) {
  return new Promise((resolve, reject) => {
   http({
    url: END_INTERACTION_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateCwc(data) {
  return new Promise((resolve, reject) => {
   http({
    url: CWC_UPDATE_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 downloadImage(data) {
  return new Promise((resolve, reject) => {
   http({
    url: LOAD_IMAGE,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 downloadRecording(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: DOWNLOAD_RECORDING,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 mergeCustomer(data) {
  return new Promise((resolve, reject) => {
   http({
    url: MERGE_CUSTOMER_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 createCustomer(data) {
  return new Promise((resolve, reject) => {
   http({
    url: CREATE_CUSTOMER_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 searchCustomer(data) {
  return new Promise((resolve, reject) => {
   http({
    url: SEARCH_CUSTOMER_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 escalationGroup(data) {
  return new Promise((resolve, reject) => {
   http({
    url: ESKALASI_GROUP,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateLoginStatus(data) {
  return new Promise((resolve, reject) => {
   http({
    url: LOGIN_STATUS,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getLoginStatus(data) {
  return new Promise((resolve, reject) => {
   http({
    url: GET_LOGIN_STATUS,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getUserDetail(data) {
  return new Promise((resolve, reject) => {
   http({
    url: GET_USER_DETAIL_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getLogInteraction(data) {
  return new Promise((resolve, reject) => {
   http({
    url: LOG_INTERACTION,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTenantValid(data) {
  return new Promise((resolve, reject) => {
   http({
    url: VALIDATE_TENANT,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 voiceByAgent(data) {
  return new Promise((resolve, reject) => {
   http({
    url: VOICE_AGENT,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 addManualCwc(data) {
  return new Promise((resolve, reject) => {
   http({
    url: ADD_MANUAL_KIP,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getFile(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: LOAD_IMAGE,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getDashboardMonitoring(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${DASHBOARD_MONITORING}`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getDashboardHistory(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${DASHBOARD_MONITORING_HISTORY}`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getDashboardHistoryToday(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${DASHBOARD_MONITORING_HISTORY_TODAY}`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getCsat(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${RADION}/getCsat`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getVoiceLog(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${RADION}/cdrAmtFu`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
};
