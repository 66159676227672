const initialState = [];

export const customeReportReducer = (state = initialState, action) => {
 switch (action.type) {
  case "CUSTOME_REPORT_GET":
   return state;
  case "CUSTOME_REPORT_SET":
   return {
    ...state,
    ...action.data,
   };
  default:
   return state;
 }
};
