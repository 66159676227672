import React from "react";
// Table
import {
 AuxTime,
 Category,
 CustomeFields,
 CustomeReport,
 FilterRule,
 Group,
 GroupDetail,
 GroupSkill,
 Information,
 SkillSetting,
 Status,
 StatusLevel,
 StatusNext,
 SubCategory,
 Template,
 Threshold,
 Unit,
 WhiteList,
 User,
 Parking,
} from "components/SpvTable";

// Reporting
import {
 AgentLog,
 Custome,
 CustomerRetention,
 DetailKip,
 Kip,
 Rating,
 SoundRecord,
 Takeout,
 Ticketing,
 Traffic,
 VideoRecord,
 WordCloud,
 //  ReportEmail,
 //  EmailHandling,
 EmailHistory,
 //  EmailQueue,
 Csat,
 DetailReport,
} from "../components/SpvReport";

import {
 AgentStatus,
 AgentActivity,
 AgentMap,
} from "../components/SpvMonitoring";

// Main
const DashboardAdminLayout = React.lazy(() =>
 import("layouts/DashboardAdminLayout")
);

//const WallboardAdmin = React.lazy(() => import("layouts/WallboardAdmin"));

// Registered Route
const mainRoute = "/spv-";
const main = [
 {
  url: "/supervisor",
  components: () => <DashboardAdminLayout />,
  name: "Supervisor",
 },
 //  {
 //   url: "/wallboard",
 //   components: () => <WallboardAdmin />,
 //   name: "Wallboard",
 //  },
];

const table = [
 {
  url: `${mainRoute}aux`,
  components: () => <AuxTime />,
  name: "Aux",
 },
 {
  url: `${mainRoute}category`,
  components: () => <Category />,
  name: "Category",
 },
 {
  url: `${mainRoute}custome-fields`,
  components: () => <CustomeFields />,
  name: "Custome Fields",
 },
 {
  url: `${mainRoute}custome-report`,
  components: () => <CustomeReport />,
  name: "Custome Report",
 },

 {
  url: `${mainRoute}filter-rule`,
  components: () => <FilterRule />,
  name: "Filter Rule",
 },
 {
  url: `${mainRoute}group`,
  components: () => <Group />,
  name: "Group",
 },
 {
  url: `${mainRoute}detail-group`,
  components: () => <GroupDetail />,
  name: "Group Detaul",
 },
 {
  url: `${mainRoute}skill-group`,
  components: () => <GroupSkill />,
  name: "Group Skill",
 },

 {
  url: `${mainRoute}information`,
  components: () => <Information />,
  name: "Information",
 },
 {
  url: `${mainRoute}skill-setting`,
  components: () => <SkillSetting />,
  name: "Skill Setting",
 },
 {
  url: `${mainRoute}status`,
  components: () => <Status />,
  name: "Status",
 },
 {
  url: `${mainRoute}level-status`,
  components: () => <StatusLevel />,
  name: "Status Level",
 },
 {
  url: `${mainRoute}next-status`,
  components: () => <StatusNext />,
  name: "Status Next",
 },
 {
  url: `${mainRoute}sub-category`,
  components: () => <SubCategory />,
  name: "Sub Category",
 },
 {
  url: `${mainRoute}template`,
  components: () => <Template />,
  name: "Template",
 },
 {
  url: `${mainRoute}threshold`,
  components: () => <Threshold />,
  name: "Threshold",
 },
 {
  url: `${mainRoute}unit`,
  components: () => <Unit />,
  name: "Unit",
 },
 {
  url: `${mainRoute}white-list`,
  components: () => <WhiteList />,
  name: "White List",
 },
 {
  url: `${mainRoute}user`,
  components: () => <User />,
  name: "user",
 },
 {
  url: `${mainRoute}parking`,
  components: () => <Parking />,
  name: "parking",
 },
];

const report = [
 {
  url: `${mainRoute}reporting-custome`,
  components: () => <Custome />,
  name: "Custome reporting",
 },
 {
  url: `${mainRoute}reporting-email`,
  components: () => <EmailHistory />,
  name: "Report Email",
 },
 {
  url: `${mainRoute}reporting-agent-log`,
  components: () => <AgentLog />,
  name: "Agent log reporting",
 },
 {
  url: `${mainRoute}reporting-detail-kip`,
  components: () => <DetailKip />,
  name: "Detail kip reporting",
 },
 {
  url: `${mainRoute}reporting-rating`,
  components: () => <Rating />,
  name: "Rating reporting",
 },
 {
  url: `${mainRoute}reporting-kip`,
  components: () => <Kip />,
  name: "Kip reporting",
 },
 {
  url: `${mainRoute}reporting-traffic`,
  components: () => <Traffic />,
  name: "Traffic reporting",
 },
 {
  url: `${mainRoute}reporting-ticketing`,
  components: () => <Ticketing />,
  name: "Ticketing reporting",
 },
 {
  url: `${mainRoute}reporting-customer-retention`,
  components: () => <CustomerRetention />,
  name: "Customer retention reporting",
 },
 {
  url: `${mainRoute}reporting-word-cloud`,
  components: () => <WordCloud />,
  name: "Word cloud reporting",
 },
 {
  url: `${mainRoute}reporting-video-record`,
  components: () => <VideoRecord />,
  name: "Video recording reporting",
 },
 {
  url: `${mainRoute}reporting-sound-record`,
  components: () => <SoundRecord />,
  name: "Sound recording reporting",
 },
 {
  url: `${mainRoute}reporting-takeout`,
  components: () => <Takeout />,
  name: "Takeout reporting",
 },
 {
  url: `${mainRoute}reporting-csat`,
  components: () => <Csat />,
  name: "Takeout reporting",
 },
 {
  url: `${mainRoute}reporting-detail-2`,
  components: () => <DetailReport />,
  name: "Detail reporting",
 },
 //  {
 //   url: `${mainRoute}reporting-queue-email`,
 //   components: () => <EmailQueue />,
 //   name: "Queue Email",
 //  },
 //  {
 //   url: `${mainRoute}reporting-handling-email`,
 //   components: () => <EmailHandling />,
 //   name: "Handling Email",
 //  },
 //  {
 //   url: `${mainRoute}reporting-history-email`,
 //   components: () => <EmailHistory />,
 //   name: "History Email",
 //  },
 //  {
 //   url: `${mainRoute}reporting-email`,
 //   components: () => <EmailHistory />,
 //   name: "Report Email",
 //  },
];

const monitoring = [
 {
  url: `${mainRoute}monitoring-agent-status`,
  components: () => <AgentStatus />,
  name: "Monitoring Agent Status",
 },
 {
  url: `${mainRoute}monitoring-agent-activity`,
  components: () => <AgentActivity />,
  name: "Monitoring Agent Status",
 },
 {
  url: `${mainRoute}monitoring-agent-map`,
  components: () => <AgentMap />,
  name: "Monitoring Agent Map",
 },
];

// Export all routes
const routes = [...main, ...table, ...report, ...monitoring];
export default routes;
