import {
  put, call, all,
} from 'redux-saga/effects';
import * as actions from '../actions';
import store from '../index';
import generalService from '../../services/generalService';

export function* customerWatcher(action) {
  try {
    const postData = {
      tenant_id: window.TENANT_ID,
      limit: 10,
      offset: action.data.offset,
      order: 'cust_id',
      filter: action.data.filter,
      fields: '*',
    };
    const data = yield call(generalService.getCustomer, postData);
    let returnable = [];
    let returnableLoadMore = false;
    if (data.TotalPages <= 1) {
      returnableLoadMore = true;
    }
    const state = store.getState();
    if (action.data.type === 'load_more') {
      const dataComparison = state.customer.customer;
      returnable = dataComparison.concat(data.Record);
      if (action.data.offset >= data.TotalPages - 1) {
        returnableLoadMore = true;
      }
    } else {
      returnable = data.Record;
    }
    yield all([
      put(actions.customerSet(returnable)),
      put(actions.customerLoadMoreSet(returnableLoadMore)),
    ]);
  } catch (error) {
    console.log(error);
  }
}
