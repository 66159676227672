import { put, call, all } from "redux-saga/effects";
import * as actions from "../actions";
import store from "../index";
import channelService from "../../services/channelService";

export function* spvEmailListWatcher(action) {
 try {
  //  if action type all dont pass param action type
  const filterCount = Object.keys(action.data.filter);
  const filter =
   action.data.filter && action.data.filter.action_type === "'ALL'"
    ? filterCount.length > 1
      ? delete action.data.filter.action_type && action.data.filter
      : ""
    : action.data.filter;
  // send order by param
  const order = action.data.order || "id desc";

  const postData = {
   tenant_id: window.TENANT_ID,
   limit: action.data.limit || 10,
   offset: action.data.offset,
   order,
   filter,
   channel_id: 2,
   operator: action.data.operator,
  };
  const data = yield call(channelService.getInteractionAll, postData);
  let returnable = [];
  let returnableLoadMore = false;
  if (data.TotalPages <= 1) {
   returnableLoadMore = true;
  }
  const state = store.getState();
  if (action.data.type === "load_more") {
   const dataComparison = state.spvEmailList.list;
   returnable = dataComparison.concat(data.Record);
   if (action.data.offset >= data.TotalPages - 1) {
    returnableLoadMore = true;
   }
  } else {
   returnable = data.Record;
  }
  yield all([
   put(actions.spvSetEmailList(returnable)),
   put(actions.generalSetHideLoadMoreEmailSpv(returnableLoadMore)),
  ]);
 } catch (error) {
  console.log(error);
 }
}
