import styled from "styled-components";
import {
 TextField,
 IconButton,
 InputLabel,
 Select,
 FormHelperText,
 Button,
 Avatar,
 Grid,
 Tabs,
 withStyles,
 InputBase,
 Snackbar,
 Input,
 Table,
 TableCell,
 Modal,
 Radio,
 Menu,
 ExpansionPanel,
 ExpansionPanelSummary,
 ExpansionPanelDetails,
 Chip,
 Switch,
 Backdrop,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ChatItem, MessageBox, Popup } from "react-chat-elements";
import randomMC from "random-material-color";
import DatePicker from "react-datepicker";
import { FiMeh, FiSmile, FiFrown } from "react-icons/fi";

const randomColor = randomMC.getColor();
export const MarginBottom = styled.div`
 margin-bottom: ${(props) => (props.val ? `${props.val}px` : "unset")};
`;
export const MarginTop = styled.div`
 margin-top: ${(props) => (props.val ? `${props.val}px` : "unset")};
`;
export const MarginRight = styled.div`
 margin-right: ${(props) => (props.val ? `${props.val}px` : "unset")};
`;
export const MarginLeft = styled.div`
 margin-left: ${(props) => (props.val ? `${props.val}` : "unset")};
 height: ${(props) => (props.height ? `${props.height}` : "unset")};
`;
export const Justify = styled.div`
 justify-content: ${(props) => (props.val ? `${props.val}` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")};
`;
export const Absolute = styled.div`
 position: absolute;
 top: ${(props) => (props.top ? `${props.top}` : "unset")};
 bottom: ${(props) => (props.bottom ? `${props.bottom}` : "unset")};
 left: ${(props) => (props.left ? `${props.left}` : "unset")};
 right: ${(props) => (props.right ? `${props.right}` : "unset")};
 width: ${(props) => (props.width ? `${props.width}` : "unset")};
 height: ${(props) => (props.height ? `${props.height}` : "unset")};
 z-index: ${(props) => (props.zindex ? `${props.zindex}` : "unset")};
 background: ${(props) => (props.bgcolor ? `${props.bgcolor} ` : "unset")};
`;
export const Fixed = styled.div`
 position: fixed;
 top: ${(props) => (props.top ? `${props.top}` : "unset")};
 bottom: ${(props) => (props.bottom ? `${props.bottom}` : "unset")};
 left: ${(props) => (props.left ? `${props.left}` : "unset")};
 right: ${(props) => (props.right ? `${props.right}` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")};
 padding: ${(props) => (props.padding ? `${props.padding}` : "unset")};
 width: ${(props) => (props.width ? `${props.width}` : "unset")};
 height: ${(props) => (props.height ? `${props.height}` : "unset")};
 display: ${(props) => (props.display ? `${props.display}` : "unset")};
`;
export const Relative = styled.div`
 position: relative;
 width: ${(props) => (props.width ? `${props.width}` : "unset")};
 height: ${(props) => (props.height ? `${props.height}` : "unset")};
`;
export const InputText = styled(TextField)`
 margin: ${(props) => (props.m ? `${props.m} !important` : "unset")};
 background: ${(props) =>
  props.bgcolor ? `${props.bgcolor} !important` : "unset"};
 width: ${(props) => (props.width ? `${props.width}` : "unset")};
 & .MuiInputBase-root {
  padding: ${(props) => (props.padding ? `${props.padding} !important` : "")};
 }
 & .MuiInputBase-input {
  font-size: 12px !important;
 }
 & .MuiFormLabel-root {
  font-size: 12px !important;
 }
 & .MuiFormLabel-root.Mui-focused {
  color: ${window.palette.primary.main} !important;
 }
 & .MuiOutlinedInput-root.Mui-focused {
  & .MuiOutlinedInput-notchedOutline {
   border-color: ${window.palette.primary.main} !important;
  }
 }
`;
export const InputDate = styled(DatePicker)`
 width: 100%;
 padding: 10px;
 outline: none;
 border: 1px solid #dedede;
 border-radius: 5px;
 font-size: 12px;
 background: #fff;
 & .react-datepicker__day--selected {
  background: ${window.palette.primary.main} !important;
  &:hover {
   background: ${window.palette.primary.main} !important;
  }
 }
 &:focus {
  border: 1px solid ${window.palette.primary.main} !important;
 }
`;
export const SelectText = styled(Select)`
 min-width: 150px;
 font-size: 10px !important;
 &.MuiInput-underline:before {
  border-bottom: unset !important;
 }
 &.MuiInput-underline:after {
  border-bottom: unset !important;
 }
`;
export const Label = styled(InputLabel)`
 font-size: 12px !important;
`;
export const ButtonPrimary = styled(Button)`
 background: ${(props) =>
  props.bgcolor
   ? `${props.bgcolor} !important`
   : `${window.palette.primary.button} !important`};
 text-transform: unset !important;
 color: ${(props) =>
  props.color ? `${props.color} !important` : "#fff !important"};
 font-size: ${(props) => (props.fs ? `${props.fs} !important` : "unset")};
 z-index: ${(props) => (props.zindex ? `${props.zindex} !important` : "unset")};
 font-weight: ${(props) => (props.bold ? `${props.bold} !important` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin} !important` : "unset")};
 padding: ${(props) =>
  props.padding ? `${props.padding} !important` : "unset"};
 border-radius: ${(props) =>
  props.radius ? `${props.radius} !important` : "unset"};
 border: ${(props) => (props.border ? `${props.border} !important` : "unset")};
 min-width: ${(props) =>
  props.minwidth ? `${props.minwidth} !important` : "64px"};
 box-shadow: ${(props) => (props.noshadow ? " none !important" : "unset")};
 box-shadow: ${(props) =>
  props.shadow ? " rgba(0, 0, 0, 0.15) 0px 0px 32px -4px !important" : "unset"};
`;
export const ButtonOutlinePrimary = styled(Button)`
 border: 1px solid ${window.palette.primary.main} !important;
 text-transform: unset !important;
 color: ${window.palette.primary.main} !important;
 background: transparent !important;
 font-weight: bold;
 &:hover {
  background: ${window.palette.primary.main} !important;
  color: #fff !important;
 }
`;
export const CardBox = styled.div`
 box-shadow: ${(props) =>
  props.noshadow
   ? "unset !important"
   : "0 0 32px -4px rgba(0,0,0,.15) !important"};
 margin: ${(props) =>
  props.margin ? `${props.margin} !important` : "unset !important"};
 background: ${(props) =>
  props.bgcolor ? `${props.bgcolor} !important` : "unset !important"};
 border-radius: 5px !important;
 border: ${(props) =>
  props.noshadow ? "1px solid #e8e8e8 !important" : "unset !important"};
 padding: ${(props) =>
  props.padding ? `${props.padding} !important` : "unset !important"};
 width: ${(props) => (props.width ? `${props.width} !important` : "auto")};
 height: ${(props) => (props.h ? `${props.h} !important` : "auto")};
`;

export const Text = styled.div`
 font-size: ${(props) => (props.fs ? `${props.fs}px` : "12px")};
 font-weight: ${(props) => (props.bold ? "bold" : "unset")};
 color: ${(props) => (props.color ? `${props.color}` : "unset")};
 text-align: ${(props) => (props.textalign ? `${props.textalign}` : "unset")};
 padding: ${(props) =>
  props.padding ? `${props.padding} !important` : "unset !important"};
 margin: ${(props) =>
  props.margin ? `${props.margin} !important` : "unset !important"};
`;
export const AnchorText = styled(Link)`
 color: ${(props) =>
  props.color ? `${props.color}` : `${window.palette.primary.main}`};
 cursor: pointer;
 font-size: ${(props) => (props.fs ? `${props.fs}` : "13px")};
 font-weight: ${(props) => (props.bold ? `${props.bold}` : "unset")};
 text-align: ${(props) => (props.textalign ? `${props.textalign}` : "unset")};
 text-decoration: ${(props) =>
  props.decoration ? `${props.decoration}` : "unset"};
 &.active {
  opacity: ${(props) => (props.opacity ? `${props.opacity}` : "unset")};
  background: ${(props) =>
   props.navbar ? "unset" : `${window.palette.primary.light} !important`};
  margin-bottom: ${(props) => (props.navbar ? "unset" : "10px !important")};
  & .icon {
   opacity: ${(props) => (props.opacity ? `${props.opacity}` : "unset")};
   margin-bottom: 0 !important;
   padding: 10px !important;
  }
 }
 & .icon {
  opacity: ${(props) => (props.opacity ? `${props.opacity}` : "unset")};
 }
 & .icon-navbar {
  opacity: ${(props) => (props.opacity ? `${props.opacity}` : "unset")};
 }
 &:hover {
  opacity: ${(props) => (props.hover ? `${props.hover}` : "unset")};
 }
`;
export const Image = styled.div`
 background: ${(props) => (props.img ? `url(${props.img})` : "#EEE")};
 background-color: ${(props) => (props.bgcolor ? `${props.bgcolor}` : "unset")};
 background-size: ${(props) => (props.size ? `${props.size}` : "cover")};
 background-position: ${(props) =>
  props.position ? `${props.position}` : "center"};
 background-repeat: no-repeat;
 height: ${(props) => (props.height ? `${props.height}` : "unset")};
 min-height: ${(props) => (props.minheight ? `${props.minheight}` : "unset")};
 width: ${(props) => (props.width ? `${props.width}` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")};
 padding: ${(props) => (props.padding ? `${props.padding}` : "unset")};
`;

export const ButtonWithIcon = styled(IconButton)`
 background: ${(props) =>
  props.bgcolor ? `${props.bgcolor}` : "unset"} !important;
 padding: ${(props) => (props.p ? `${props.p}px` : "0px")} !important;
 margin-left: ${(props) => (props.ml ? `${props.ml}px` : "unset")} !important;
 margin-right: ${(props) => (props.mr ? `${props.mr}px` : "unset")} !important;
 border-radius: ${(props) =>
  props.radius ? `${props.radius}` : "unset"} !important;
 display: ${(props) => (props.grid ? "grid" : "unset")} !important;
 width: ${(props) => (props.width ? `${props.width}` : "unset")} !important;
 box-shadow: ${(props) =>
  props.noshadow
   ? `${props.noshadow}`
   : "rgba(0, 0, 0, 0.15) 0px 0px 32px -4px"} !important;
 && span {
  font-size: 20px !important;
  color: ${(props) =>
   props.coloricon ? `${props.coloricon}` : "unset"} !important;
 }
 z-index: ${(props) => (props.index ? `${props.index}` : "unset")} !important;
`;
export const HelperText = styled(FormHelperText)`
 color: red !important;
`;
export const Flex = styled.div`
 box-shadow: ${(props) =>
  props.shadow ? "rgba(0, 0, 0, 0.15) 0px 0px 32px -4px" : "unset"};
 cursor: ${(props) => (props.cursor ? `${props.cursor}` : "unset")} !important;
 pointer-events: ${(props) =>
  props.disabled ? `${props.disabled}` : "unset"} !important;
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")} !important;
 padding: ${(props) =>
  props.padding ? `${props.padding}` : "unset"} !important;
 display: flex;
 align-items: ${(props) =>
  props.align ? `${props.align}` : "center"} !important;
 flex-wrap: ${(props) => (props.nowrap ? "unset" : "wrap")} !important;
 justify-content: ${(props) =>
  props.justify ? `${props.justify}` : "unset"} !important;
 text-align: ${(props) =>
  props.textalign ? `${props.textalign}` : "unset"} !important;
 background-color: ${(props) =>
  props.bgcolor ? `${props.bgcolor}` : "unset"} !important;
 height: ${(props) => (props.height ? `${props.height}` : "unset")} !important;
 width: ${(props) => (props.width ? `${props.width}` : "unset")} !important;
 flex-direction: ${(props) =>
  props.direction ? `${props.direction}` : "unset"} !important;
 border: ${(props) => (props.border ? `${props.border}` : "unset")} !important;
 border-bottom: ${(props) =>
  props.bottom ? "1px solid #e8e8e8" : "unset"} !important;
 border-left: ${(props) =>
  props.left ? "1px solid #e8e8e8" : "unset"} !important;
 border-right: ${(props) =>
  props.right ? "1px solid #e8e8e8" : "unset"} !important;
 border-top: ${(props) =>
  props.top ? "1px solid #e8e8e8" : "unset"} !important;
 border-radius: ${(props) =>
  props.radius ? `${props.radius}` : "unset"} !important;
 overflow-y: ${(props) =>
  props.scroll ? `${props.scroll}` : "unset"} !important;
 overflow-x: ${(props) =>
  props.scrollx ? `${props.scrollx}` : "unset"} !important;
`;
export const Block = styled.div`
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")} !important;
 padding: ${(props) =>
  props.padding ? `${props.padding}` : "unset"} !important;
 display: block;
 text-align: ${(props) =>
  props.textalign ? `${props.textalign}` : "unset"} !important;
 background-color: ${(props) =>
  props.bgcolor ? `${props.bgcolor}` : "unset"} !important;
 height: ${(props) => (props.height ? `${props.height}` : "unset")} !important;
 width: ${(props) => (props.width ? `${props.width}` : "unset")} !important;
 max-width: ${(props) =>
  props.maxwidth ? `${props.maxwidth}` : "unset"} !important;
 box-sizing: ${(props) =>
  props.boxisizing ? `${props.boxisizing}` : "unset"} !important;
`;
export const AvatarBorder = styled(Avatar)`
 width: ${(props) =>
  props.width ? `${props.width} !important` : "25px !important"};
 height: ${(props) =>
  props.height ? `${props.height} !important` : "25px !important"};
 margin: ${(props) => (props.margin ? `${props.margin}` : "10px")};
 border: 2px solid ${(props) => (props.border ? props.border : "unset")};
 font-size: ${(props) => (props.fs ? `${props.fs}` : "unset")};
 background-color: ${(props) =>
  props.bgcolor ? `${props.bgcolor} !important` : `${randomColor} !important`};
 cursor: pointer;
`;
export const ScrollBar = styled(PerfectScrollbar)`
 width: ${(props) => (props.width ? `${props.width} !important` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin} !important` : "unset")};
 padding: ${(props) =>
  props.padding ? `${props.padding} !important` : "unset"};
 & .ps__rail-y {
  width: 7px;
  z-index: 999;
  &:hover {
   & .ps__thumb-y {
    width: 7px !important;
   }
  }

  &:focus {
   & .ps__thumb-y {
    width: 7px !important;
   }
  }

  & .ps--clicking {
   & .ps__thumb-y {
    width: 7px !important;
   }
  }
 }

 & .ps__rail-x {
  display: ${(props) => (props.hidex ? `${props.hidex} !important` : "unset")};
  &:hover {
   & .ps__thumb-x {
    display: ${(props) =>
     props.hidex ? `${props.hidex} !important` : "unset"};
   }
  }
 }
 @media only screen and (min-width: 600px) {
  height: ${(props) =>
   props.height ? `${props.height} !important` : "100% !important"};
 }
 @media only screen and (max-width: 600px) {
  height: unset !important;
 }
`;
export const Badge = styled.div`
 background-color: ${red["500"]};
 position: absolute;
 top: -5px;
 right: 10px;
 font-size: 9px;
 font-weight: bold;
 color: #fff;
 padding: 2px;
 border-radius: 5px;
 height: 10px;
 width: 10px;
 display: flex;
 justify-content: center;
 align-items: center;
 box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
 opacity: 1 !important;
 z-index: ${(props) => (props.idx ? `${props.idx} !important` : "unset")};
`;
export const GridContent = styled(Grid)`
 height: ${(props) => (props.height ? `${props.height}% ` : "unset")};
 position: relative;
 border-left: ${(props) =>
  props.borderleft ? `${props.borderleft}px solid #e8e8e8` : "unset"};
 border-right: ${(props) =>
  props.borderright ? `${props.borderright}px solid #e8e8e8` : "unset"};
 align-self: ${(props) => (props.centered ? "center" : "unset")};
 padding: ${(props) => (props.padding ? `${props.padding}` : "unset")};
 margin: ${(props) => (props.margin ? `${props.margin}` : "unset")};
 @media (min-width: 1280px) {
  &.MuiGrid-grid-lg-3 {
   max-width: 20% !important;
   flex-basis: 20% !important;
  }

  &.MuiGrid-grid-lg-6 {
   max-width: 55% !important;
   flex-basis: 55% !important;
  }

  &.MuiGrid-grid-lg-9 {
   max-width: 80% !important;
   flex-basis: 80% !important;
  }
 }
`;
export const Timer = styled.div`
 border: 1px solid #7b7474;
 color: #294354;
 border-radius: 3px;
 padding: 2px;
 font-size: 10px;
 margin-left: 10px;
 margin-right: 10px;
 margin-bottom: 5px;
 font-weight: bold;
`;
export const ListItem = styled(ChatItem)`
 width: 100%;
 min-width: unset !important;
 & .rce-citem-avatar {
  display: ${(props) => (props.hideAvatar ? "none" : "flex")};
 }
 & .rce-avatar-container.large {
  width: 30px;
  height: 30px;
 }
 & .rce-citem-body--top-title {
  font-size: 12px;
 }
 & .rce-citem {
  min-width: unset !important;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "50px")};
  align-items: center;
  background: ${(props) => (props.isActive ? "#ececec" : "white")};
  padding-left: ${(props) => (props.hideAvatar ? "10px" : "unset")};
  padding-bottom: ${(props) => (props.pb ? "10px" : "unset")};
  padding-top: ${(props) => (props.pt ? "10px" : "unset")};
  border-bottom: 1px solid #e2e2e2;
  &:hover {
   background: #ececec;
  }
 }

 & .rce-citem-body--bottom {
  margin-top: 0 !important;
 }

 & .rce-citem-body--bottom-title {
  font-size: 10px;
 }
 & .rce-citem-body--top-time {
  font-size: 10px;
 }
 & .rce-citem-body--bottom-status span {
  width: 15px;
  height: 15px;
  font-size: 10px;
 }
 & .rce-citem-body {
  border-bottom: 1px solid transparent;
 }

 & .rce-citem-avatar {
  height: 30px;
  & .rce-avatar-container {
   font-size: 15px;
   font-weight: normal;
   color: white;
   background: ${randomColor};
   &::after {
    content: ${(props) => (props.bg ? `'${props.bg}'` : "A")};
   }
   & .rce-avatar {
    display: none;
   }
  }
 }
`;
export const TabsPrimary = withStyles({
 indicator: {
  height: "2px",
  backgroundColor: window.palette.primary.dark,
 },
})(Tabs);

export const GreenRadio = withStyles({
 root: {
  color: `${window.palette.primary.main}`,
  "&$checked": {
   color: `${window.palette.primary.main}`,
  },
  "& span > div > svg ": {
   fontSize: "17px !important",
   marginLeft: "10px !important",
  },
  "&:hover": {
   backgroundColor: "transparent !important",
  },
 },
 checked: {},
})(Radio);

export const FloatingButton = styled.div`
 position: fixed;
 bottom: 30px;
 right: 30px;
 background-color: ${window.palette.primary.main};
 color: #fff;
 border-radius: 50px;
 text-align: center;
 font-size: 12px;
 box-shadow: 2px 2px 3px #999;
 z-index: 100;
 padding: 10px;
 display: inline-flex;
 justify-content: center;
 align-items: center;
`;
export const InputBaseMessage = styled(InputBase)`
 font-size: 12px !important;
 width: 100%;
 padding: 10px !important;
 && textarea {
  background: #f5f6f8;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
 }
 &&::placeholder {
  font-size: 12px;
 }
`;
export const MessageBoxLeft = styled(MessageBox)`
 & .rce-mbox {
  background: #ececec;
  margin-left: 30px;
  margin-bottom: 10px;
  margin-right: 30px;
 }

 & .rce-mbox.rce-mbox-left {
  width: ${(props) => (props.width ? `${props.width}` : "unset")};
 }

 & .rce-mbox-text {
  font-size: 11px;
  padding-bottom: 10px;
  color: #686767;
 }

 & .rce-mbox-time {
  font-size: 10px;
 }

 & .rce-mbox-left-notch {
  fill: #ececec;
 }

 & .rce-mbox-photo--img__block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: unset;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
 }
`;

export const MessageBoxRight = styled(MessageBox)`
 & .rce-mbox {
  background: ${window.palette.primary.dark};
  margin-right: 30px;
  margin-bottom: 10px;
 }

 & .rce-mbox-right-notch {
  fill: ${window.palette.primary.dark};
 }

 & .rce-mbox-text {
  font-size: 11px;
  padding-bottom: 10px;
  color: #fff;
 }

 & .rce-mbox-time {
  font-size: 10px;
  color: #e8e8e8;
 }

 & .rce-mbox.rce-mbox-right {
  margin-left: 30px;
  width: ${(props) => (props.width ? `${props.width}` : "unset")};
 }

 & .rce-mbox-photo--img__block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: unset;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
 }
`;

export const PopupMessage = styled(Popup)`
 & .rce-popup {
  width: 70%;
  height: 90%;
  & .rce-popup-content {
   height: 100%;
  }
 }
`;
export const MessageSpace = styled.div`
 flex: 1 1 auto;
 min-height: 12px;
`;
export const MessageWrapper = styled.div`
 box-sizing: border-box;
 display: flex;
 flex-direction: column;
 height: calc(100% - 150px);
 width: 100%;
 z-index: -1;
 transition: background 0.3s ease-out 0.1s;
 overflow-y: auto;
`;
export const MessageWrapperHistory = styled.div`
 box-sizing: border-box;
 display: flex;
 flex-direction: column;
 height: calc(100% - 88px);
 width: 100%;
 z-index: -1;
 transition: background 0.3s ease-out 0.1s;
 overflow-y: auto;
 overflow-x: hidden;
`;
export const Td = styled(TableCell)`
 font-size: 11px !important;
 color: #686868 !important;
 border-top: 1px solid #e5e5e5;
 border-bottom: 1px solid #e5e5e5 !important;
 padding: 10px !important;
 &.MuiTableCell-head {
  background: ${(props) =>
   props.bgcolor
    ? `${props.bgcolor}`
    : `${window.palette.primary.main}`} !important;
  color: ${(props) => (props.color ? `${props.color}` : "#fff")} !important;
  font-weight: ${(props) => (props.bold ? "bold !important" : "normal")};
  text-align: center;
  padding: 5px !important;
  font-size: 12px !important;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
 }
`;

export const Tbl = styled(Table)`
 border-spacing: unset !important;
 margin-top: 10px;
 margin-bottom: 10px;
`;

export const SnackbarMain = styled(Snackbar)`
 & .MuiSnackbarContent-root {
  background-color: ${(props) => (props.bg ? `${props.bg}` : "unset")};
  font-size: 12px !important;
 }
`;
export const ModalTitle = styled.div`
 background: #ebebeb;
 border-top-left-radius: 5px;
 border-top-right-radius: 5px;
 padding: 10px;
 color: #294354;
 font-weight: bold;
 font-size: 12px;
`;
export const ModalBody = styled.div`
 padding: 10px;
 height: ${(props) => (props.height ? `${props.height}` : "auto")} !important;
`;
export const ModalButtonClose = styled.div`
 border-radius: 100px;
 background: #d4d4d4;
 padding: 5px;
 cursor: pointer;
 width: 10px;
 height: 10px;
 font-size: 9px;
 text-align: center;
`;
export const ModalElement = styled(Modal)`
 display: flex;
 align-items: center;
 justify-content: center;
`;

export const SearchField = styled(Input)`
 padding: 4px;
 font-size: 12px !important;
 border-radius: 5px;
 border: 1px solid #e8e8e8;
 &&::before {
  border-bottom: 1px solid transparent !important;
 }

 &&::after {
  border-bottom: 1px solid transparent !important;
 }
`;
export const MenuPopover = styled(Menu)`
 & .MuiPopover-paper {
  top: 170px !important;
  width: ${(props) => (props.width ? `${props.width}` : "300px")} !important;
  padding: 10px;
 }

 & .MuiMenuItem-root {
  font-size: 12px !important;
  color: #294354;
  font-weight: 500;
  font-family: "Quicksand";
 }

 & .MuiListItemIcon-root {
  min-width: 20px !important;
  color: #294354;
 }
`;
export const MenuPopover2 = styled(Menu)`
 & .MuiMenuItem-root {
  font-size: 12px !important;
  color: #294354;
  font-weight: 500;
  font-family: "Quicksand";
 }

 & .MuiListItemIcon-root {
  min-width: 20px !important;
  color: #294354;
 }
`;
export const ListItemTicket = styled(ChatItem)`
 & .rce-avatar-container.large {
  width: 30px;
  height: 30px;
 }
 & .rce-citem-body--top {
  position: absolute;
  top: 15px;
  width: 80%;
 }
 & .rce-citem-body--top-title {
  font-size: 12px;
  color: #294354;
  font-weight: bold;
  width: 100%;
 }
 & .rce-citem {
  min-height: 50px;
  background: ${(props) => (props.isActive ? "#ececec" : "white")};
  border-bottom: 1px solid #e2e2e2;
  &:hover {
   background: #ececec;
  }
  padding-top: 10px;
  padding-bottom: 10px;
 }
 & .rce-citem-body--bottom {
  margin-top: 4px;
  position: absolute;
  display: flex;
  left: 15px;
  top: 40px;
 }
 & .rce-citem-body--bottom-title {
  font-size: 10px;
 }
 & .rce-citem-body--top-time {
  font-size: 10px;
 }
 & .rce-citem-body--bottom-status span {
  width: 15px;
  height: 15px;
  font-size: 10px;
 }
 & .rce-citem-body {
  border-bottom: 1px solid transparent;
 }
 & .rce-citem-avatar {
  height: 30px;
  & .rce-avatar-container {
   font-size: 15px;
   font-weight: normal;
   color: white;
   background: ${randomColor};
   &::after {
    content: ${(props) => (props.bg ? `'${props.bg}'` : "A")};
   }
   & .rce-avatar {
    display: none;
   }
  }
 }
`;

export const BoxStatus = styled.div`
 background: ${(props) => (props.bg ? `${props.bg}` : "unset")};
 padding: 3px;
 font-size: 9px;
 color: #fff;
 font-weight: normal;
 margin-left: auto;
`;
export const AccordionCollapse = styled(ExpansionPanel)`
 pointer-events: ${(props) => (props.disabled ? `${props.disabled}` : "unset")};
 font-size: 12px;
 width: ${(props) => (props.width ? `${props.width} !important` : "unset")};
 height: ${(props) => (props.height ? `${props.height} !important` : "unset")};
 &.MuiPaper-elevation1 {
  box-shadow: ${(props) => (props.noshadow ? "none !important" : "unset")};
 }
`;
export const AccordionCollapseSum = styled(ExpansionPanelSummary)`
 font-size: 12px;
`;
export const AccordionCollapseDetail = styled(ExpansionPanelDetails)`
 font-size: 12px;
 height: ${(props) => (props.height ? `${props.height} !important` : "unset")};
`;
export const FiMehs = styled(FiMeh)`
 fill: #ffc71d !important;
`;
export const FiSmiles = styled(FiSmile)`
 fill: #6fcd2f !important;
`;
export const FiFrowns = styled(FiFrown)`
 fill: #de2727 !important;
`;
export const ChipElement = styled(Chip)`
 margin-top: ${(props) =>
  props.mt ? `${props.mt} !important` : "10px !important"};
 margin-bottom: ${(props) =>
  props.mb ? `${props.mb} !important` : "10px !important"};
 width: fit-content !important;
 margin-right: 5px !important;
 font-size: 10px !important;
 height: 20px !important;
 & .MuiChip-deleteIcon {
  width: 15px !important;
 }
`;
export const GreenSwitch = withStyles({
 switchBase: {
  color: `${window.palette.primary.main}`,
  "&$checked": {
   color: `${window.palette.primary.main}`,
  },
  "&$checked + $track": {
   backgroundColor: `${window.palette.primary.main}`,
  },
 },
 checked: {},
 track: {},
})(Switch);
export const PurpleSwitch = withStyles({
 switchBase: {
  color: window.palette.primary.button,
  "&$checked": {
   color: window.palette.primary.button,
  },
  "&$checked + $track": {
   backgroundColor: window.palette.primary.button,
  },
 },
 checked: {},
 track: {},
})(Switch);
export const Loading = withStyles({
 root: {
  zIndex: 999999999,
  color: "#fff",
 },
})(Backdrop);
