export const ticketGetStatus = (data) => ({ type: "TICKET_STATUS_GET", data });

export const ticketSetStatus = (status) => ({
 type: "TICKET_STATUS_SET",
 status,
});

export const ticketGetHistory = (data) => ({
 type: "TICKET_HISTORY_GET",
 data,
});

export const ticketSetHistory = (history) => ({
 type: "TICKET_HISTORY_SET",
 history,
});

export const ticketGet = (data) => ({ type: "TICKET_GET", data });

export const ticketSet = (ticket) => ({
 type: "TICKET_SET",
 ticket,
});

export const ticketGetUnit = (data) => ({ type: "TICKET_UNIT_GET", data });

export const ticketSetUnit = (unit) => ({
 type: "TICKET_UNIT_SET",
 unit,
});

export const ticketGetCurrent = (data) => ({
 type: "TICKET_CURRENT_GET",
 data,
});

export const ticketSetCurrent = (current) => ({
 type: "TICKET_CURRENT_SET",
 current,
});

export const ticketGetStatusLevel = (data) => ({
 type: "TICKET_STATUS_LEVEL_GET",
 data,
});

export const ticketSetStatusLevel = (status) => ({
 type: "TICKET_STATUS_LEVEL_SET",
 status,
});

export const ticketGetStatusNext = (data) => ({
 type: "TICKET_STATUS_NEXT_GET",
 data,
});

export const ticketSetStatusNext = (status) => ({
 type: "TICKET_STATUS_NEXT_SET",
 status,
});

export const ticketGetCaseOrigin = (data) => ({
 type: "TICKET_CASE_ORIGIN_GET",
 data,
});

export const ticketSetCaseOrigin = (status) => ({
 type: "TICKET_CASE_ORIGIN_SET",
 status,
});

export const ticketGetServiceArea = (data) => ({
 type: "TICKET_SERVICE_AREA_GET",
 data,
});

export const ticketSetServiceArea = (status) => ({
 type: "TICKET_SERVICE_AREA_SET",
 status,
});

export const ticketGetStageTicket = (data) => ({
 type: "TICKET_STAGE_GET",
 data,
});

export const ticketSetStageTicket = (stage) => ({
 type: "TICKET_STAGE_SET",
 stage,
});
export const ticketGetAdditionalTicket = (data) => ({
 type: "TICKET_ADDITIONAL_GET",
 data,
});

export const ticketSetAdditionalTicket = (additional) => ({
 type: "TICKET_ADDITIONAL_SET",
 additional,
});
export const ticketGetAdditionalValue = (data) => ({
 type: "TICKET_ADDITIONAL_VALUE_GET",
 data,
});

export const ticketSetAdditionalValue = (additional) => ({
 type: "TICKET_ADDITIONAL_VALUE_SET",
 additional,
});

export const ticketGetCount = (count) => ({
 type: "TICKET_GET_COUNT",
 count,
});

export const ticketSetCount = (count) => ({
 type: "TICKET_SET_COUNT",
 count,
});
