export const teleCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "TELE_GET_CURRENT":
   return state;
  case "TELE_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const teleMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "TELE_GET_MESSAGES":
   return state;
  case "TELE_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const whatsappCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "WHATSAPP_GET_CURRENT":
   return state;
  case "WHATSAPP_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const whatsappMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "WHATSAPP_GET_MESSAGES":
   return state;
  case "WHATSAPP_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const messengerCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "MESSENGER_GET_CURRENT":
   return state;
  case "MESSENGER_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const messengerMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "MESSENGER_GET_MESSAGES":
   return state;
  case "MESSENGER_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const smsCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "SMS_GET_CURRENT":
   return state;
  case "SMS_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const smsMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "SMS_GET_MESSAGES":
   return state;
  case "SMS_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const tdmCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "TDM_GET_CURRENT":
   return state;
  case "TDM_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const tdmMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "TDM_GET_MESSAGES":
   return state;
  case "TDM_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const webchatCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "WEBCHAT_GET_CURRENT":
   return state;
  case "WEBCHAT_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const webchatMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "WEBCHAT_GET_MESSAGES":
   return state;
  case "WEBCHAT_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const emailCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "EMAIL_GET_CURRENT":
   return state;
  case "EMAIL_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const emailMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "EMAIL_GET_MESSAGES":
   return state;
  case "EMAIL_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const facebookCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "FACEBOOK_GET_CURRENT":
   return state;
  case "FACEBOOK_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const facebookMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "FACEBOOK_GET_MESSAGES":
   return state;
  case "FACEBOOK_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const twitterCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "TWITTER_GET_CURRENT":
   return state;
  case "TWITTER_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const twitterMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "TWITTER_GET_MESSAGES":
   return state;
  case "TWITTER_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const instagramCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "INSTAGRAM_GET_CURRENT":
   return state;
  case "INSTAGRAM_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const instagramMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "INSTAGRAM_GET_MESSAGES":
   return state;
  case "INSTAGRAM_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const vidcallCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "VIDCALL_GET_CURRENT":
   return state;
  case "VIDCALL_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const vidcallMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "VIDCALL_GET_MESSAGES":
   return state;
  case "VIDCALL_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const voiceCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "VOICE_GET_CURRENT":
   return state;
  case "VOICE_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const voiceMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "VOICE_GET_MESSAGES":
   return state;
  case "VOICE_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const lineCurrentReducer = (state = null, action) => {
 switch (action.type) {
  case "LINE_GET_CURRENT":
   return state;
  case "LINE_SET_CURRENT":
   return {
    ...state,
    current: action.current,
   };
  default:
   return state;
 }
};

export const lineMessagesReducer = (state = [], action) => {
 switch (action.type) {
  case "LINE_GET_MESSAGES":
   return state;
  case "LINE_SET_MESSAGES":
   return {
    ...state,
    messages: action.messages,
   };
  default:
   return state;
 }
};

export const igCurrentReducer = (state = null, action) => {
  switch (action.type) {
   case "IG_GET_CURRENT":
    return state;
   case "IG_SET_CURRENT":
    return {
     ...state,
     current: action.current,
    };
   default:
    return state;
  }
 };
 
 export const igMessagesReducer = (state = [], action) => {
  switch (action.type) {
   case "IG_GET_MESSAGES":
    return state;
   case "IG_SET_MESSAGES":
    return {
     ...state,
     messages: action.messages,
    };
   default:
    return state;
  }
 };
 