/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import {
 GET_TICKET_HISTORY_,
 GET_TICKET_STATUS_,
 GET_TICKET_,
 GET_TICKET_UNIT_,
 GET_TICKETING_STATUS_LEVEL_,
 GET_CASE_ORIGIN_,
 GET_SERVICE_AREA_,
 GET_TICKETING_STATUS_NEXT_,
 UPDATE_TICKET_,
 GET_STAGE_,
 GET_ADDITIONAL_,
 GET_ADDITIONAL_VALUE_,
} from "../utils/api";
import http from "../utils/http";

export default {
 getTicket(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKET_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getStageTicket(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_STAGE_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketStatusLevel(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKETING_STATUS_LEVEL_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getCaseOrigin(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_CASE_ORIGIN_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getServiceArea(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_SERVICE_AREA_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getStatusNext(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKETING_STATUS_NEXT_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketHistory(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKET_HISTORY_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketStatus(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKET_STATUS_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getTicketUnit(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_TICKET_UNIT_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateTicket(data) {
  return new Promise((resolve, reject) => {
   http({
    url: UPDATE_TICKET_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAdditionalField(data) {
  return new Promise((resolve, reject) => {
   http({
    url: GET_ADDITIONAL_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getAdditionalValue(data) {
  return new Promise((resolve, reject) => {
   http({
    url: GET_ADDITIONAL_VALUE_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
};
