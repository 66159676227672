import React from "react";
import { Formik } from "formik";
import { MdSave } from "react-icons/md";
import * as moment from "moment";
import { connect } from "react-redux";
import { InputText, ButtonPrimary, MarginBottom } from "../Layout";
import helper from "../../utils/helper";
import generalService from "../../services/generalService";

class ErrorLogs extends React.Component {
 render() {
  const { auth, data } = this.props;
  const initialValues = {
   tenant_id: window.TENANT_ID,
   userid: auth && auth.user ? auth.user.userid : "Unauthenticated",
   error_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
   error_name: data.error_name,
   error_line: data.error_line,
   error_description: data.error_description,
   error_report: "",
   error_browser: navigator.userAgent,
  };

  return (
   <Formik
    initialValues={initialValues}
    onSubmit={(e) => {
     e.error_ip = "127.0.0.1";
     generalService.errorReport(e).then((r) => {
      if (r) {
       this.props.handleClose();
      }
     });
    }}
   >
    {({
     values,
     errors,
     touched,
     handleBlur,
     handleSubmit,
     isValid,
     handleChange,
    }) => (
     <>
      <InputText
       id="error_report"
       name="error_report"
       type="text"
       label="Please report your problem here"
       variant="outlined"
       size="small"
       fullWidth
       value={values.error_report}
       onChange={handleChange}
       onBlur={handleBlur}
       multiline
       rows={3}
      />
      <MarginBottom val={10} />
      <ButtonPrimary
       variant="contained"
       color="primary"
       size="small"
       onClick={handleSubmit}
       disabled={!isValid}
       fullWidth
       startIcon={<MdSave />}
       fs="10px"
      >
       Save
      </ButtonPrimary>
     </>
    )}
   </Formik>
  );
 }
}
export default connect(
 helper.mapStateToProps,
 helper.mapDispatchToProps
)(ErrorLogs);
