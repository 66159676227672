/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import {
 HISTORY_INTERACTION_,
 POST_OUTGOING_EMAIL,
 GET_ALL_EMAIL_,
} from "../utils/api";
import http from "../utils/http";

export default {
 getInteraction(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: HISTORY_INTERACTION_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractionAll(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: GET_ALL_EMAIL_,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 getInteractioHistory(data) {
  return new Promise((resolve, reject) => {
   http({
    url: HISTORY_INTERACTION_,
    method: "POST",
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 sendMessageRtc(item, msg, user, url, type, media) {
  return new Promise((resolve, reject) => {
   const postData = {
    message: msg,
    session_id: item.session_id,
    stream_to_id: item.stream_to_id,
    stream_id: item.stream_id,
    conv_id: item.conv_id,
    from: item.from,
    from_name: item.from_name,
    message_type: type,
    action_type: "OUT",
    media: media ? `${JSON.stringify(media)}` : null,
    tenant_id: window.TENANT_ID,
    agent_id: user.userid,
    parent_id: item.parent_id,
    account_id: item.account_id,
   };

   http({
    url,
    method: "POST",
    data: postData,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 sendMessageEmail(item) {
  return new Promise((resolve, reject) => {
   const postData = {
    tenant_id: window.TENANT_ID,
    agent_id: item.agent_id,
    account_email: item.account_email,
    subject: item.subject,
    to: item.to_email,
    cc: item.cc_email,
    message: item.message,
    media: item.media ? `${JSON.stringify(item.media)}` : null,
    session_id: item.session_id,
    msgId: item.msgId,
    action_type: item.action_type,
    cwc_type: item.cwc_type || "",
   };

   http({
    url: POST_OUTGOING_EMAIL,
    method: "POST",
    data: postData,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
};
