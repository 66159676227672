import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import * as Sentry from '@sentry/browser';
import store from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Sentry.init({ dsn: 'https://ead00d0ff5a44f3099470693caf7c80e@o408178.ingest.sentry.io/5278664' });

const Root = () => (
 <Provider store={store}>
  <App />
 </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
