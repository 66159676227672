import { put, call, all } from "redux-saga/effects";
import * as actions from "../actions";
import store from "../index";
import generalService from "../../services/generalService";

export function* voiceLogWatcher(action) {
 try {
  const postData = {};
  const data = yield call(generalService.getVoiceLog, postData);
  let returnable = [];
  let returnableLoadMore = false;

  const state = store.getState();
  if (action.data.type === "load_more") {
   const dataComparison = state.customer.customer;
   returnable = dataComparison.concat(data.data);
  } else {
   returnable = data.data;
  }
  yield all([
   put(actions.voiceLogSet(returnable)),
   put(actions.voiceLogLoadMoreSet(returnableLoadMore)),
  ]);
 } catch (error) {
  console.log(error);
 }
}
