import React from "react";
import { Paper, Backdrop, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import {
 Flex,
 MarginLeft,
 ModalTitle,
 ModalBody,
 ModalButtonClose,
 ModalElement,
} from "../Layout";

class ModalAddOrEdit extends React.Component {
 render() {
  const { open, handleClose, children, title, width, istemplate } = this.props;
  return (
   <ModalElement
    disableBackdropClick
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}
    disablePortal
    disableEnforceFocus
    disableAutoFocus
   >
    <Fade in={open}>
     <Paper
      style={{
       height: "fit-content",
       maxHeight: "90%",
       width: "fit-content",
       maxWidth: istemplate ? "50%" : "90%",
       minWidth: width || "40%",
      }}
     >
      <ModalTitle>
       <Flex width="100%" align="center" nowrap="nowrap">
        {title}
        <MarginLeft val="auto">
         <ModalButtonClose onClick={() => handleClose()}>X</ModalButtonClose>
        </MarginLeft>
       </Flex>
      </ModalTitle>
      <ModalBody>{children}</ModalBody>
     </Paper>
    </Fade>
   </ModalElement>
  );
 }
}
ModalAddOrEdit.propTypes = {
 open: PropTypes.any,
 handleClose: PropTypes.any,
 children: PropTypes.any,
 title: PropTypes.any,
};
export default ModalAddOrEdit;
