import {
  all,
  takeLatest,
  takeEvery,
  fork,
  setContext,
} from "redux-saga/effects";
import * as general from "./general";
import * as channel from "./channel";
import * as ticket from "./ticket";
import * as customer from "./customer";
import * as spv from "./spv";
import * as voiceLog from "./voiceLog";

export function* watchUsers(context) {
  yield setContext(context);

  yield all([
    fork(general.notificationWatcher),
    fork(general.incomingWatcher),
    fork(general.insertTransTicketWatcher),
    fork(general.updateTransTicketWatcher),
    fork(general.generalActionWatcher),
    fork(general.notificationByChannelWatcher),
    fork(general.countNotificationWatcher),
    fork(general.listenAudioChannelWatcher),
    fork(general.ticketCountWatcher),
    fork(general.vaxVoipWatcher),
    //fork(general.mizzuWatcher),
    fork(general.amiVoiceWatcher),
    takeLatest("AUTH_GET", general.authWatcher),
    takeEvery("CHANNEL_GET", general.channelWatcher),
    takeEvery("CHANNEL_AGENT_GET", general.channelAgentWatcher),
    takeEvery("JOURNEY_GET", general.journeyWatcher),
    takeEvery("PROFILE_GET", general.profileWatcher),
    takeEvery("CATEGORY_GET", general.categoryWatcher),
    takeEvery("SUM_CWC_GET", general.sumCwcWatcher),
    takeEvery("TEMPLATE_GET", general.templateWatcher),
    takeEvery("LOG_CWC_GET", general.logCwcWatcher),
    takeEvery("LOG_INTERACTION_GET", general.logInteractionWatcher),
    takeEvery("ADD_FORM_GET", general.addFormWatcher),
    takeEvery("ADD_FORM_OPT_GET", general.addFormOptWatcher),
    takeEvery("MERGE_CWC_GET", general.logCwcMergeWatcher),
    takeEvery("TELE_GET_MESSAGES", channel.telegramMessagesWatcher),
    takeEvery("WHATSAPP_GET_MESSAGES", channel.whatsappMessagesWatcher),
    takeEvery("SMS_GET_MESSAGES", channel.smsMessagesWatcher),
    takeEvery("TDM_GET_MESSAGES", channel.tdmMessagesWatcher),
    takeEvery("WEBCHAT_GET_MESSAGES", channel.webchatMessagesWatcher),
    takeEvery("MESSENGER_GET_MESSAGES", channel.messengerMessagesWatcher),
    takeEvery("EMAIL_GET_MESSAGES", channel.emailMessagesWatcher),
    takeEvery("FACEBOOK_GET_MESSAGES", channel.facebookMessagesWatcher),
    takeEvery("TWITTER_GET_MESSAGES", channel.twitterMessagesWatcher),
    takeEvery("INSTAGRAM_GET_MESSAGES", channel.instagramMessagesWatcher),
    takeEvery("IG_GET_MESSAGES", channel.igMessagesWatcher),
    takeEvery("VIDCALL_GET_MESSAGES", channel.vidcallMessagesWatcher),
    takeEvery("VOICE_GET_MESSAGES", channel.voiceMessagesWatcher),
    takeEvery("LINE_GET_MESSAGES", channel.lineMessagesWatcher),
    takeEvery("TICKET_STATUS_GET", ticket.ticketStatusWatcher),
    takeEvery("TICKET_UNIT_GET", ticket.ticketUnitWatcher),
    takeEvery("TICKET_GET", ticket.ticketWatcher),
    takeEvery("TICKET_HISTORY_GET", ticket.ticketHistoryWatcher),
    takeEvery("TICKET_STATUS_LEVEL_GET", ticket.statusLevelWatcher),
    takeEvery("TICKET_STATUS_NEXT_GET", ticket.statusNextWatcher),
    takeEvery("TICKET_CASE_ORIGIN_GET", ticket.caseOriginWatcher),
    takeEvery("TICKET_SERVICE_AREA_GET", ticket.serviceAreaWatcher),
    takeEvery("TICKET_STAGE_GET", ticket.stageTicketWatcher),
    takeEvery("TICKET_ADDITIONAL_GET", ticket.additionalTicketWatcher),
    takeEvery("TICKET_ADDITIONAL_VALUE_GET", ticket.additionalValueWatcher),
    takeEvery("CUSTOMER_GET", customer.customerWatcher),
    takeEvery("VOICE_LOG_GET", voiceLog.voiceLogWatcher),
    takeEvery("SPV_EMAIL_LIST_GET", spv.spvEmailListWatcher),
    takeEvery("MANUAL_KIP_GET", general.manualKipWatcher),
  ]);
}
