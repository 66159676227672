/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import {
 LOGIN_,
 LOGOUT_,
 UPDATE_PASSWORD_,
 AUX_,
 RELEASE_AUX_,
 RESET_PASSWORD_,
 SPV_,
 PAUSE_AUTO_IN_,
 FORGOT_PASSWORD,
 TOKEN_VALID,
} from "../utils/api";
import http from "../utils/http";

export default {
 login(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    data,
    url: LOGIN_,
   })
    .then((response) => {
     if (response) {
      http.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 checkToken(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "GET",
    url: `${TOKEN_VALID}?token=${data.token}`,
   })
    .then((response) => {
     if (response) {
      http.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updatePassword(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: UPDATE_PASSWORD_,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 resetPassword(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: RESET_PASSWORD_,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 releaseUser(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: `${SPV_}releaseLogin`,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateAux(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: AUX_,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 updateAuxRelease(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: RELEASE_AUX_,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 logout(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: LOGOUT_,
    data,
   })
    .then((response) => {
     if (response) {
      if (parseInt(window.isMizu) === 1) {
       if (window.webphone_api) {
        window.webphone_api.unregister();
        window.webphone_api.stop();
       }
      }
      delete http.defaults.headers.common.Authorization;
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 pauseAutoIn(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: PAUSE_AUTO_IN_,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
 forgotPassword(data) {
  return new Promise((resolve, reject) => {
   http({
    method: "POST",
    url: FORGOT_PASSWORD,
    data,
   })
    .then((response) => {
     if (response) {
      resolve(response.data);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 },
};
